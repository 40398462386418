import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { NavigationContext } from "../lib/NavigationProvider";

export interface ButtonProps {
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const IconButton = ({ item }: { item: ButtonProps }) => {
  let isActiveButton = false;
  return (
    <NavLink
      key={item.name}
      to={item.href}
      className={({ isActive }) => {
        isActiveButton = isActive;
        return classNames(
          isActive
            ? "bg-darkBackground text-darkPrimary shadow-card dark:shadow-darkCard fill-darkPrimary"
            : "text-primary hover:bg-darkPopupSecondary dark:hover:bg-darkBackground dark:text-darkPrimary fill-primary dark:fill-darkPrimary",
          "group flex items-center h-16 px-2 py-2 text-base rounded-md w-full"
        );
      }}
    >
      <item.icon
        className={classNames(
          isActiveButton
            ? "text-darkPrimary fill-darkPrimary"
            : "text-primary dark:text-darkPrimary fill-primary dark:fill-darkPrimary"
        )}
        aria-hidden="true"
      />
      <span className={"mx-3"}> {item.name}</span>
    </NavLink>
  );
};

export default IconButton;
