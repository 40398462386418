import { useContext, useEffect, useState } from "react";
import LendingHistory from "../components/LendingHistory";
import Panel from "../components/Panel";
import Tabs from "../components/Tabs";
import { NavigationContext } from "../lib/NavigationProvider";
import { LendingHistoryItem } from "../lib/NFTContext";
import PendingLends from "./PendingLends";

const ProfileLending = ({
  enrichedHistory,
  isLoggedInUser = false,
}: {
  enrichedHistory: LendingHistoryItem[];
  isLoggedInUser: boolean;
}) => {
  const [activeTab, setTab] = useState(0);
  const { search } = useContext(NavigationContext);
  const [borrowedData, setBorrowedData] = useState<LendingHistoryItem[]>();
  const [lendedNFTs, setLendedNFTs] = useState<LendingHistoryItem[]>([]);
  const [pendingNFTs, setPendingNFTs] = useState<LendingHistoryItem[]>([]);

  useEffect(() => {
    const borrowed = enrichedHistory?.filter(
      (nft) => nft.type === "Borrow" && nft.lendStatus === "accepted"
    );
    const lent = enrichedHistory?.filter(
      (nft) => nft.type === "Lend" && nft.lendStatus === "accepted"
    );
    const pending = enrichedHistory?.filter(
      (nft) => nft.lendStatus === "pending"
    );
    if (borrowed) setBorrowedData(borrowed);
    if (lent) setLendedNFTs(lent);
    if (pending) setPendingNFTs(pending);
  }, [enrichedHistory]);

  const borrowedLength = borrowedData ? borrowedData.length : 0;
  const lendedLength = lendedNFTs?.length || 0;
  const sumLength = borrowedLength + lendedLength;

  const filteredLentNFTs =
    search && search !== ""
      ? lendedNFTs &&
        lendedNFTs.filter(
          (nft) =>
            nft.name?.toLowerCase().includes(search.toLowerCase()) === true
        )
      : lendedNFTs;

  const filteredBorrowedNFTs =
    search && search !== ""
      ? borrowedData &&
        borrowedData.filter(
          (nft) =>
            nft.name?.toLowerCase().includes(search.toLowerCase()) === true
        )
      : borrowedData;

  let allNFTs: LendingHistoryItem[] = [];
  if (filteredBorrowedNFTs) allNFTs = allNFTs.concat(filteredBorrowedNFTs);
  if (filteredLentNFTs) allNFTs = allNFTs.concat(filteredLentNFTs);

  return (
    <div>
      <Panel
        injectedClassesInner="text-center mb-4 mt-2"
        injectedClassesOuter="p-4"
      >
        <h1 className="text-2xl pb-6">
          Lending is currently gasless and not on-chain
        </h1>
        <p>
          The NFTs do not leave your wallet, and you will not be asked to pay a
          fee.
        </p>
        <p>
          We manage an in-house chain that’s centralized to the Hub, allowing
          NFT owners to cryptographically prove permission by providing a
          signature.
        </p>
      </Panel>
      {isLoggedInUser && pendingNFTs.length > 0 && (
        <Panel
          injectedClassesInner="text-center overflow-visible mb-4 mt-2"
          injectedClassesOuter="p-4 mb-6 mt-6"
        >
          <h1 className="text-2xl pb-6">Pending Lends</h1>
          <PendingLends nfts={pendingNFTs} />
        </Panel>
      )}
      <Panel
        injectedClassesInner="mt-8 mb-4 overflow-visible pb-20"
        injectedClassesOuter="p-4 mt-8"
      >
        <Tabs
          tabs={[
            {
              name: "Lending",
              count: filteredLentNFTs ? filteredLentNFTs.length : 0,
              index: 0,
            },
            {
              name: "Borrowed",
              count: filteredBorrowedNFTs ? filteredBorrowedNFTs.length : 0,
              index: 1,
            },
            {
              name: "All History",
              count: allNFTs ? allNFTs.length : 0,
              index: 2,
            },
          ]}
          activeTab={activeTab}
          setTab={setTab}
        />
        <div className="my-4" style={{ minHeight: "59vh" }}>
          {activeTab === 0 && lendedLength === 0 && (
            <h1 className="m text-xl">No Lent NFTs</h1>
          )}
          {activeTab === 1 && borrowedLength === 0 && (
            <h1 className="m text-xl">No Borrowed NFTs</h1>
          )}
          {activeTab === 2 && sumLength === 0 && (
            <h1 className="m text-xl">No Lending History</h1>
          )}
          {activeTab === 0 && lendedLength >= 1 && filteredLentNFTs && (
            <LendingHistory nfts={filteredLentNFTs} />
          )}
          {activeTab === 1 && borrowedLength >= 1 && filteredBorrowedNFTs && (
            <LendingHistory nfts={filteredBorrowedNFTs} />
          )}
          {activeTab === 2 && sumLength >= 1 && (
            <LendingHistory nfts={allNFTs} />
          )}
        </div>
      </Panel>
    </div>
  );
};

export default ProfileLending;
