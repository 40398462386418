import { Dispatch, SetStateAction, useState } from "react";
import WalletButton from "../components/WalletButton";
import { Chain, Chains } from "../lib/constants";

interface ConnectWalletProps {
  connectingChain?: Chain | undefined;
  setConnectingChain: Dispatch<SetStateAction<Chain | undefined>>;
}

export default function ConnectWallet({
  connectingChain,
  setConnectingChain,
}: ConnectWalletProps) {
  return (
    <div className="mt-6 h-48 flex flex-col bg-transparent justify-center">
      {connectingChain ? (
        <p>Connecting to {connectingChain.name}</p>
      ) : (
        Chains.map((chain: Chain) => (
          <div className="w-full my-1" key={chain.name}>
            <WalletButton
              wallet={chain}
              setConnectingChain={setConnectingChain}
            />
          </div>
        ))
      )}
    </div>
  );
}
