import type {
	DocumentData,
	FirestoreDataConverter,
	PartialWithFieldValue,
	QueryDocumentSnapshot,
	SnapshotOptions,
	Timestamp,
	WithFieldValue
} from "firebase/firestore";
import { ChainId } from "./APIResponseTypes";

export enum ApiID {
    Ethereum = 1,
    Autograph = 2,
    Solana = 3,
    Nifty = 4
}

export enum ChainID {
    Ethereum = 1,
    Polygon = 2,
    Solana = 3
}

export default interface WalletEntry {
	address: string;
	associationDate: Date;
	label: string;
	ownerID: string;
	apiID?: ApiID[];
	chainID?: ChainID[];
	nameService?: string;
}

interface WalletEntryRaw {
    associatedAt: Timestamp;
    label: string;
    ownerId: string;
    apiId: number[];
    chainId: number[];
    nameService: string | undefined;
}

export const walletEntryFirestoreDataConverter: FirestoreDataConverter<WalletEntry> = {
	fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): WalletEntry {
		const data: WalletEntryRaw = snapshot.data(options);
		
		return {
			address: snapshot.id,
			associationDate: data["associatedAt"] && new Date(data["associatedAt"].seconds * 1000),
			label: data["label"],
			ownerID: data["ownerId"],
			apiID: data["apiId"],
			chainID: data["chainId"],
			nameService: data["nameService"]
		};
	},
	toFirestore(modelObject: WithFieldValue<WalletEntry> | PartialWithFieldValue<WalletEntry>): DocumentData {
		return {
			associatedAt: modelObject.associationDate,
			label: modelObject.label,
			ownerId: modelObject.ownerID,
			chainId: modelObject.chainID,
			nameService: modelObject.nameService
		};
	}
}


export const getWalletTypeName = (ApiID: number[] | undefined, chainID: number[]|undefined) => {
    if (ApiID && ApiID.includes(4)) {
        return "Nifty Gateway";
    } else if (chainID && chainID.includes(3)) {
        return "Solana";
    } else if (chainID && chainID.includes(1)) {
        return "Ethereum";
    } else {
        return "Ethereum";
    }
}