import { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSigninCheck } from "reactfire";
import { DeviceProvider } from "../lib/DeviceContext";
import { NavigationProvider } from "../lib/NavigationProvider";
import { NewUserProvider } from "../lib/NewUserContext";
import { NFTProvider } from "../lib/NFTContext";
import { UserProvider } from "../lib/UserContext";
import { ErrorBoundary } from "react-error-boundary";

interface Props {
  children: JSX.Element;
}

function ErrorBoundaryFallbackComponent({ error, resetErrorBoundary }) {
  const clickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    resetErrorBoundary();
    location.reload();
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre></pre>
      <button onClick={clickHandler}>Try again</button>
    </div>
  );
}

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.error("ERROR HANDLER", error, info);
};

// https://v5.reactrouter.com/web/example/auth-workflow
const OpenRoute: FunctionComponent<Props> = ({ children }) => {
  const { status, data: signInCheckResult } = useSigninCheck();
  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full h-full animate-pulse">
        Loading
      </div>
    );
  } else if (signInCheckResult.signedIn === true) {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallbackComponent}
        onError={myErrorHandler}
      >
        <UserProvider>
          <NavigationProvider>
            <NFTProvider>
              <DeviceProvider>{children}</DeviceProvider>
            </NFTProvider>
          </NavigationProvider>
        </UserProvider>
      </ErrorBoundary>
    );
  } else {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallbackComponent}
        onError={myErrorHandler}
      >
        <NewUserProvider>{children}</NewUserProvider>
      </ErrorBoundary>
    );
  }
};

export default OpenRoute;
