import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import IconButton from "./IconButton";
import type { ButtonProps } from "./IconButton";
import Divider from "./Divider";
import { DeviceContext } from "../lib/DeviceContext";
import DeviceEntry from "./DeviceEntry";
import LogoSquare from "../resources/reactIcons/LogoSquare";
import AFModal from "./AFModal";
import DeviceSetup from "./DeviceSetup";

export default function SidebarMobile({
  sidebarOpen,
  setSidebarOpen,
  navigation,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  navigation: ButtonProps[];
}) {
  const [showAddDeviceModal, toggleAddDeviceModal] = useState(false);
  const { data } = useContext(DeviceContext);
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full focus:outline-none bg-surface dark:bg-darkSurface shadow-card dark:shadow-darkCard">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <LogoSquare passedClasses="h-14 w-auto color-primary fill-primary dark:color-popupPrimary dark:fill-popupPrimary" />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-2 mb-2 space-y-1">
                      {navigation.map((item) => (
                        // eslint-disable-next-line react/jsx-key, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                        <IconButton item={item} key={item.name} />
                      ))}
                    </div>
                  </nav>
                  <Divider withClass="overflow-y-auto">
                    <div className="w-full">
                      {data &&
                        data.map((device, index) => {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          return (
                            <DeviceEntry
                              device={device}
                              key={device.id || index}
                            />
                          );
                        })}
                    </div>
                  </Divider>
                </div>
                <Divider withClass="flex-shrink-0">
                  <div className="flex items-center flex-shrink-0 h-11 p-3 w-full group block bg-purple hover:bg-purpleLighter text-darkPrimary font-bold rounded">
                    <div>
                      <PlusIcon
                        className="h-6 w-6 font-bold"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <button
                        className="mx-3 w-full font-bold"
                        onClick={() => toggleAddDeviceModal(true)}
                      >
                        Add Device
                      </button>
                    </div>
                  </div>
                </Divider>
              </Dialog.Panel>
            </Transition.Child>
            <AFModal
              toggleModal={toggleAddDeviceModal}
              showModal={showAddDeviceModal}
            >
              <DeviceSetup toggleModal={toggleAddDeviceModal} />
            </AFModal>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
