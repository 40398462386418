export default function ActionIconSleep() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 45 45"
      fill="current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 23H27V21L30.39 17H27V15H33V17L29.62 21H33V23ZM25 27H19V25L22.39 21H19V19H25V21L21.62 25H25V27ZM17 31H11V29L14.39 25H11V23H17V25L13.62 29H17V31Z"
      />
    </svg>
  );
}
