import { navigation } from "../lib/constants";
import { ExternalLink } from "./ExternalLink";
// import { getAuth, signOut } from "firebase/auth";
import { useAuth, useAnalytics, useSigninCheck } from "reactfire";
import { clearFirestoreCache } from "../lib/utils";
import { logEvent } from "firebase/analytics";
import { useNavigate, useLocation } from "react-router-dom";
// import {disconnect} from "wagmi";
import ThemeSwitcher from "./ThemeSwitcher";
import { Link } from "react-router-dom";
import { ChevronDoubleLeftIcon } from "@heroicons/react/solid";
import { classNames } from "../lib/uiUtils";
import { useAccount, useDisconnect } from "wagmi";

export const Header = () => {
  const { isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const { data: signInCheckResult } = useSigninCheck();
  const analytics = useAnalytics();
  const auth = useAuth();
  const { pathname } = useLocation();
  const executeLogout = () => {
    logEvent(analytics, "logout");
    // Handle Wagmi disconnect
    !isDisconnected && disconnect();
    // Firebase disconnect
    auth
      .signOut()
      .then(() => {
        // navigate(".");
        // window.location.reload();
        // reactFirePreloadedObservables();
        clearFirestoreCache();
        navigate("/");
      })
      .catch((e) => alert(`Error logging out: ${e}`));
  };

  const additionalClasses = pathname.includes("/provenance")
    ? "bg-surface dark:bg-popupBackgroundSecondary"
    : "";

  return (
    <header
      className={classNames(
        "sticky top-0 z-10 py-2 px-4 sm:px-8 w-full h-10 sm:h-16",
        additionalClasses
      )}
    >
      {pathname.includes("/provenance/") && (
        <div className="absolute left-0 sm:top-0 top-8 w-10 h-10 hover:text-purpleLighter">
          <Link className="inline-flex items-center" to="/">
            <ChevronDoubleLeftIcon className="flex-1 w-8 h-8" />{" "}
            <span className="flex-1">Home</span>
          </Link>
        </div>
      )}
      <div>
        <ul className="flex items-center justify-center sm:justify-end">
          <li className="flex">
            <ThemeSwitcher />
            <span className="px-2">|</span>
          </li>
          {navigation.map(({ name, href }) => (
            <li key={name}>
              <ExternalLink href={href} name={name} />
              <span className="px-2">|</span>
            </li>
          ))}
          <li>
            {signInCheckResult.signedIn === true && (
              <button onClick={() => executeLogout()}>Logout</button>
            )}
            {signInCheckResult.signedIn === false && (
              <Link to="/login">Login</Link>
            )}
          </li>
        </ul>
      </div>
    </header>
  );
};
