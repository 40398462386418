type Props = {
  href: string;
  name: string;
};

export const ExternalLink = ({ href, name }: Props) => (
  <a
    className="hover:underline"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {name}
  </a>
);
