import Ethereum from "../ethereum.svg";
import Nifty from "../nifty.png";
import Solana from "../solana.svg";
import AF from "../logo-square.svg";
import AutographBlack from "../autograph-black.png";
import AutographWhite from "../autograph-white.png";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../lib/ThemeContext";

interface ChainIconProps {
  chainID: number | undefined;
  apiID: number | undefined;
}

const ChainIcon = ({ chainID, apiID }: ChainIconProps) => {
  const [theme] = useContext(ThemeContext);
  const [icon, setIcon] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (chainID === 1 || chainID === 2) {
      if (apiID === 1) {
        setIcon(Ethereum);
        setName("Ethereum");
      } else if (apiID == 2) {
        setIcon(theme === "dark" ? AutographWhite : AutographBlack);
        setName("Autograph");
      } else if (apiID === 4) {
        setIcon(Nifty);
        setName("Nifty Gateway");
      } else {
        setIcon(Ethereum);
        setName("Ethereum");
      }
    } else if (chainID === 3) {
      setIcon(Solana);
      setName("Solana");
    } else if (chainID === 0) {
      setIcon(AF);
      setName("OffChain");
    } else {
      setIcon(Ethereum);
      setName("Ethereum");
    }
  }, []);

  if (icon.length > 0) {
    return <img src={icon} alt={name} className="h-5 w-auto" />;
  } else {
    return <></>;
  }
};

export default ChainIcon;
