import { XIcon } from "@heroicons/react/outline";
import { FirebaseError } from "firebase/app";
import {
  HttpsCallable,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import { useContext, useState } from "react";
import { useAnalytics, useFunctions } from "reactfire";
import { logEvent } from "firebase/analytics";
import { DeviceContext } from "../lib/DeviceContext";
import IconSetupPower from "../resources/reactIcons/IconSetupPower";
import AFButton from "./AFButton";
import AFSpinner from "./AFSpinner";
import Input from "./Input";
import NumericPINInput from "./NumericPINInput";
import { UserContext } from "../lib/UserContext";

interface Props {
  toggleModal: (val: boolean) => void;
}

enum SetupSteps {
  PowerOn = 0,
  ConnectNetwork,
  InputCode,
  AssignName,
}

export enum InputCodeError {
  OK,
  NotFound,
  ServerError,
}

const setupCopy = [
  {
    title: "Power on device",
    subtitle: "Connect and power on your Wave or Photon",
    icon: IconSetupPower,
  },
  {
    title: "Connect to network",
    subtitle:
      "Follow the prompts on the screen to connect your display to the internet",
    icon: IconSetupPower,
  },
  {
    title: "Input pairing code",
    subtitle: "Input the pairing code displayed on the display",
    icon: IconSetupPower,
  },
  {
    title: "Name your display",
    subtitle: "Give your display a name",
    icon: IconSetupPower,
  },
];

const DeviceSetup = ({ toggleModal }: Props) => {
  const functions = useFunctions();
  const associateDevice: HttpsCallable<{ code: string }, { deviceId: string }> =
    httpsCallable(functions, "associateDevice");

  const { renameDevice } = useContext(DeviceContext);
  const { userId } = useContext(UserContext);
  const [step, setStep] = useState<number>(0);
  const [pairingCode, setPairingCode] = useState("");
  const [inputCodeError, setInputCodeError] = useState(InputCodeError.OK);
  const [isLoading, setIsLoading] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");

  const analytics = useAnalytics();

  // PairingCode OK
  // DeviceID
  // DeviceName

  //   let inputCodeError;

  const onClickNext = async () => {
    if (step === SetupSteps.InputCode) {
      setInputCodeError(InputCodeError.OK);
      setIsLoading(true);
      await submitPairingCode(pairingCode)
        .then(() => {
          setStep(step + 1);
        })
        .catch((e) => {
          alert(`Error ${e}`);
          return;
        });
    } else if (step === SetupSteps.AssignName) {
      const cleanDeviceName = deviceName.trim();
      if (cleanDeviceName.length > 0) {
        setIsLoading(true);
        if (renameDevice)
          await renameDevice({ deviceId, newLabel: cleanDeviceName });
        setIsLoading(false);
      }
      toggleModal(false);
    } else {
      setStep(step + 1);
    }
  };

  const submitPairingCode = async (pairingCode: string) => {
    try {
      const { data } = await associateDevice({ code: pairingCode });
      const newDeviceId = data && data.deviceId;
      logEvent(analytics, "pair_device", {
        device_id: deviceId,
        user_id: userId,
      });
      setDeviceId(newDeviceId);
    } catch (error: unknown) {
      if (
        error instanceof FirebaseError &&
        error.code === "functions/not-found"
      ) {
        setInputCodeError(InputCodeError.NotFound);
        alert("error");
      } else {
        setInputCodeError(InputCodeError.ServerError);
        console.warn("Failed to associate device:", error);
        alert("error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-modal w-modal flex flex-col">
      <div className="flex align-center justify-end">
        <button onClick={() => toggleModal(false)}>
          <XIcon className="w-6 h-6 mr-3" />
        </button>
      </div>
      <div className="flex-1">
        <div className="w-full h-full flex-shrink-0 flex flex-col align-center justify-center px-6 box-border">
          <h2 className="m-0 text-center text-2xl font-bold h-11">
            {setupCopy[step].title}
          </h2>
          <p className="m-0 text-center">{setupCopy[step].subtitle}</p>
          <div className="w-full flex flex-col items-center justify-center flex-1">
            {step === 0 && (
              <svg
                width="253"
                height="149"
                viewBox="0 0 253 149"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-popupBackground dark:fill-popupPrimary"
                  d="M133.5 64.7402L132.5 66.4702C134.597 67.681 136.236 69.5498 137.163 71.787C138.089 74.0241 138.252 76.5046 137.625 78.8435C136.998 81.1825 135.617 83.2493 133.696 84.7234C131.775 86.1975 129.421 86.9965 127 86.9965C124.579 86.9965 122.225 86.1975 120.304 84.7234C118.383 83.2493 117.002 81.1825 116.375 78.8435C115.748 76.5046 115.911 74.0241 116.837 71.787C117.764 69.5498 119.403 67.681 121.5 66.4702L120.5 64.7402C118.022 66.1711 116.085 68.3798 114.99 71.0237C113.894 73.6676 113.702 76.599 114.443 79.3632C115.184 82.1274 116.816 84.57 119.086 86.3122C121.356 88.0543 124.138 88.9986 127 88.9986C129.862 88.9986 132.644 88.0543 134.914 86.3122C137.184 84.57 138.816 82.1274 139.557 79.3632C140.298 76.599 140.106 73.6676 139.01 71.0237C137.915 68.3798 135.978 66.1711 133.5 64.7402Z"
                />
                <path
                  className="fill-popupBackground dark:fill-popupPrimary"
                  d="M126 61H128V75H126V61Z"
                />
                <rect
                  className="stroke-popupBackground dark:stroke-popupPrimary"
                  x="247.5"
                  y="5.5"
                  width="138"
                  height="242"
                  transform="rotate(90 247.5 5.5)"
                  strokeWidth="11"
                />
              </svg>
            )}
            {step === 1 && (
              <svg
                width="253"
                height="149"
                viewBox="0 0 253 149"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="stroke-popupBackground dark:stroke-popupPrimary"
                  x="88"
                  y="36"
                  width="78"
                  height="78"
                  rx="3"
                  strokeWidth="2"
                />
                <rect
                  className="fill-popupBackground dark:fill-popupPrimary"
                  x="100"
                  y="59"
                  width="54"
                  height="4"
                  rx="2"
                />
                <rect
                  className="fill-popupBackground dark:fill-popupPrimary"
                  x="112"
                  y="51"
                  width="30"
                  height="4"
                  rx="2"
                />
                <rect
                  className="fill-popupBackground dark:fill-popupPrimary"
                  x="100"
                  y="67"
                  width="54"
                  height="4"
                  rx="2"
                />
                <rect
                  className="fill-popupBackground dark:fill-popupPrimary"
                  x="110"
                  y="90"
                  width="32"
                  height="4"
                  rx="2"
                />

                <rect
                  className="stroke-popupBackground dark:stroke-popupPrimary"
                  x="247.5"
                  y="5.5"
                  width="138"
                  height="242"
                  transform="rotate(90 247.5 5.5)"
                  strokeWidth="11"
                />
              </svg>
            )}
            {step === 2 && !isLoading && (
              <NumericPINInput
                disabled={false}
                setPairingCode={setPairingCode}
                errorStatus={inputCodeError}
              />
            )}
            {step === 2 && isLoading && <AFSpinner />}
            {step === 3 && isLoading && <AFSpinner />}
            {step === 3 && !isLoading && (
              <form
                className="w-full flex md:ml-0"
                onSubmit={(e) => {
                  e.preventDefault();
                  onClickNext().catch((e) => console.error(e));
                }}
              >
                <label htmlFor="mobile-search-field" className="sr-only">
                  Search
                </label>
                <label htmlFor="desktop-search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600 rounded">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"></div>
                  <input
                    name="mobile-search-field"
                    id="mobile-search-field"
                    className="h-full w-full rounded-xl border py-2 pl-8 pr-3 bg-popupPrimary text-base text-gray-900 dark:text-primary placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden"
                    placeholder="Enter Device Name"
                    onChange={(e) => setDeviceName(e.target.value)}
                    type="search"
                  />
                  <input
                    name="desktop-search-field"
                    id="desktop-search-field"
                    className="hidden h-full w-full rounded-xl border py-2 pl-8 pr-3 bg-popupPrimary text-sm dark:text-primary text-primary placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple focus:border-transparent focus:placeholder-gray-400 sm:block"
                    placeholder="Enter Device Name"
                    onChange={(e) => setDeviceName(e.target.value)}
                    type="search"
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <div className="mx-6 mb-3">
        <div className="flex flex-row mb-6">
          <form
            className="w-full flex"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={async (e) => {
              e.preventDefault();
              await onClickNext();
            }}
            onReset={(e) => {
              e.preventDefault();
              setStep(step - 1);
            }}
          >
            <AFButton
              type="reset"
              variant="outline"
              disabled={step === 0 ? true : false}
              width={undefined}
              fullWidth={undefined}
              flexFill={true}
            >
              <h1>Back</h1>
            </AFButton>
            <div className="w-3"></div>
            <AFButton
              type="submit"
              variant="fill"
              disabled={false}
              width={undefined}
              fullWidth={undefined}
              flexFill={true}
            >
              <h1>{step === 3 ? "Done" : "Next"}</h1>
            </AFButton>
          </form>
        </div>
        <ul className="list-none m-0 p-0 h-2 flex flex-row align-center justify-center gap-2">
          {setupCopy.map((stepCopy, index) => (
            <li key={index}>
              <svg
                className={`w-2 h-2 transition-all${
                  step >= index
                    ? " fill-primary dark:fill-darkPrimary"
                    : " fill-symbolFaint dark:fill-popupBackgroundSecondary"
                }`}
                width="8"
                height="8"
                viewBox="0 0 1 1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="0.5" cy="0.5" r="0.5" />
              </svg>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DeviceSetup;
