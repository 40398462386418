import { Observable } from 'rxjs';
import { ensureInitialized, getValue as baseGetValue, getString as baseGetString, getNumber as baseGetNumber, getBoolean as baseGetBoolean, getAll as baseGetAll, } from 'firebase/remote-config';
function parameter$(_a) {
    var remoteConfig = _a.remoteConfig, key = _a.key, getter = _a.getter;
    return new Observable(function (subscriber) {
        ensureInitialized(remoteConfig).then(function () {
            // 'this' for the getter loses context in the next()
            // call, so it needs to be bound.
            var boundGetter = getter.bind(remoteConfig);
            subscriber.next(boundGetter(remoteConfig, key));
        });
    });
}
export function getValue(remoteConfig, key) {
    var getter = baseGetValue;
    return parameter$({ remoteConfig: remoteConfig, key: key, getter: getter });
}
export function getString(remoteConfig, key) {
    var getter = baseGetString;
    return parameter$({ remoteConfig: remoteConfig, key: key, getter: getter });
}
export function getNumber(remoteConfig, key) {
    var getter = baseGetNumber;
    return parameter$({ remoteConfig: remoteConfig, key: key, getter: getter });
}
export function getBoolean(remoteConfig, key) {
    var getter = baseGetBoolean;
    return parameter$({ remoteConfig: remoteConfig, key: key, getter: getter });
}
export function getAll(remoteConfig) {
    var getter = baseGetAll;
    // No key is needed for getAll()
    return parameter$({ remoteConfig: remoteConfig, key: '', getter: getter });
}
