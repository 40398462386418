import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { signInWithCustomToken } from "firebase/auth";
import React from "react";
import { useFirestore, useAuth, useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import { ethers } from "ethers";
import { ExternalProvider } from "@ethersproject/providers";
import { base58, randomBytes } from "ethers/lib/utils";
import { PhantomProvider } from "../..";

interface NewUserContextProps {
  userId?: string;
  username?: string | undefined;
  addressExists?: (address: string) => Promise<boolean>;
  associateWallet?: ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps) => Promise<void>;
  connectToEthereum?: () => Promise<string | void>;
  connectToSolana?: () => Promise<{
    stringAddress: string;
    arrayAddress: Uint8Array;
  } | void>;
  connectToNifty?: () => Promise<void>;
  getChallengeLogin?: ({
    address,
    apiId,
    chainId,
  }: ChallengeProps) => Promise<string | undefined>;
  signChallengeLoginSolana?: ({ message }: { message: string }) => Promise<{
    publicKey: PhantomProvider["publicKey"];
    signature: Uint8Array;
  } | void>;
  signUp?: ({
    address,
    signature,
    username,
    apiId,
    chainId,
    message,
    userslug,
  }: SignUpProps) => Promise<string | void>;
  updateWallet?: ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps) => Promise<string | void>;
  signIn?: ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps) => Promise<string | void>;
  usernameExists?: (username: string) => Promise<boolean>;
  slugExists?: (slug: string) => Promise<boolean>;
  getNiftyAccount?: (code: string) => Promise<NiftyAccount>;
  getUserByHandle?: (handle: string) => Promise<UserEntry | undefined>;
}

export const NewUserContext = React.createContext<NewUserContextProps>({});

interface Props {
  children: React.ReactNode;
}

export interface UserEntry {
  createdAt: Date;
  username: string;
  displayName: string;
  slug: string;
  photoURL?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  isPublic?: boolean;
  website?: string;
  id?: string;
}

export interface ChallengeProps {
  address: string;
  apiId: number[];
  chainId: number[];
}

interface AssociateWalletProps {
  address: string | Uint8Array;
  signature: string | Uint8Array;
  apiId: number[];
  chainId: number[];
  message?: Uint8Array;
}

interface SignUpProps {
  address: string | Uint8Array;
  signature: string | Uint8Array;
  username: string;
  apiId: number[];
  chainId: number[];
  message?: Uint8Array;
  userslug: string;
}

export interface NiftyAccount {
  username: string;
  name: string;
  profilePicUrl: string;
  userId?: string;
  token?: string;
  email: string;
  nameService?: string;
}

export const connectToEthereum = async (): Promise<string | void> => {
  let provider: ethers.providers.Web3Provider;
  const externalProvider = window?.ethereum as ExternalProvider;
  if (externalProvider && externalProvider.request) {
    try {
      provider = new ethers.providers.Web3Provider(externalProvider);
      await externalProvider.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      const accounts = await provider.listAccounts();
      const sanitizedAccount = accounts[0].toLowerCase();
      return sanitizedAccount;
    } catch (error) {
      console.error("Metamask connection error", { error, type: typeof error });
    }
  }
};

export const connectToSolana = async (): Promise<{
  stringAddress: string;
  arrayAddress: Uint8Array;
} | void> => {
  const externalProvider = window.solana;
  if (externalProvider) {
    try {
      const { publicKey } = await externalProvider.connect();
      const stringAddress = publicKey.toString();
      const arrayAddress = publicKey.toBuffer();
      return { stringAddress, arrayAddress };
    } catch (error) {
      console.error("Metamask connection error", { error, type: typeof error });
    }
  }
};

export const connectToNifty = async (): Promise<void> => {
  const random = randomBytes(16);
  const randomString = base58.encode(random);
  window.localStorage.setItem("niftyState", randomString);
  const urlOrigin = window.location.origin;
  const redirectUri =
    "https://niftygateway.com/authorize?scope=profile:read+email:read&state=" +
    randomString +
    "&client_id=4zBx0BF4Wiyd6WqohisfHXAOflsy4n2wwiK3kNVu&redirect_uri=" +
    urlOrigin +
    "/nifty-callback&response_type=token";
  window.location.replace(redirectUri);
};

export const signChallengeLoginSolana = async ({
  message,
}: {
  message: Uint8Array;
}): Promise<{
  publicKey: PhantomProvider["publicKey"];
  signature: Uint8Array;
} | void> => {
  const externalProvider = window.solana as PhantomProvider;
  if (externalProvider) {
    try {
      const signedMessage = (await externalProvider.signMessage(
        message,
        "utf8"
      )) as {
        publicKey: PhantomProvider["publicKey"];
        signature: Uint8Array;
      };
      return signedMessage;
    } catch (error) {
      console.error("Sign Challenge Login Solana error", {
        error,
        type: typeof error,
      });
    }
  }
};

export const NewUserProvider: React.FC<Props> = ({ children }) => {
  const firestore = useFirestore();
  const functions = useFunctions();
  const auth = useAuth();
  const userCollection = collection(firestore, "users");

  const associateWallet = async ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps): Promise<void> => {
    try {
      await httpsCallable<AssociateWalletProps, void>(
        functions,
        "associateWallet"
      )({ address, signature, apiId, chainId, message });
    } catch (e) {
      console.error("ASSOCIATION ERROR", e);
    }
  };

  const updateWallet = async ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps): Promise<void> => {
    try {
      await httpsCallable<AssociateWalletProps, void>(
        functions,
        "updateWallet"
      )({ address, signature, apiId, chainId, message });
    } catch (e) {
      console.error("Update error", e);
    }
  };

  const getChallengeLogin = async ({
    address,
    apiId,
    chainId,
  }: ChallengeProps): Promise<string | undefined> => {
    try {
      const { data } = await httpsCallable<ChallengeProps, { message: string }>(
        functions,
        "getChallenge"
      )({ address, apiId, chainId });
      return data.message;
    } catch (error) {
      console.error("GET CHALLENGE ERROR", error);
      throw error;
    }
  };

  const signIn = async ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps): Promise<string | void> => {
    try {
      const signInFunction = httpsCallable<
        AssociateWalletProps,
        { token: string }
      >(functions, "auth");
      const { data } = await signInFunction({
        address,
        signature,
        apiId,
        chainId,
        message,
      });
      return data.token;
    } catch (error) {
      console.error("Sign Up error", {
        error,
        type: typeof error,
      });
      throw error;
    }
  };

  const signUp = async ({
    address,
    signature,
    username,
    apiId,
    chainId,
    message,
    userslug,
  }: SignUpProps): Promise<string | void> => {
    try {
      const signUpFunction = httpsCallable<SignUpProps, { token: string }>(
        functions,
        "signUp"
      );
      const { data } = await signUpFunction({
        address,
        signature,
        username,
        apiId,
        chainId,
        message,
        userslug,
      });
      await signInWithCustomToken(auth, data.token);
    } catch (error) {
      console.error("Sign Up error", {
        error,
        type: typeof error,
      });
      throw error;
    }
  };

  const getNiftyAccount = async (code: string): Promise<NiftyAccount> => {
    const niftyAccountFunction = httpsCallable<{ code: string }, NiftyAccount>(
      functions,
      "niftyAuth"
    );
    const { data } = await niftyAccountFunction({ code });
    return data;
  };

  const getUserByHandle = async (
    handle: string
  ): Promise<UserEntry | undefined> => {
    const userQuery = query(userCollection, where("slug", "==", handle));
    const querySnapshot = await getDocs(userQuery);
    let returnVal: UserEntry | undefined;
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (docData) {
        docData.createdAt = new Date(docData.createdAt * 1000);
        docData.id = doc.id;
        returnVal = docData as UserEntry;
      } else {
        console.log("NO DATA FOUND");
        return undefined;
      }
    });
    return returnVal;
  };

  const addressExists = async (address: string): Promise<boolean> => {
    const snapshot = await getDoc(doc(firestore, "wallets", address));
    if (snapshot.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const usernameExists = async (username: string): Promise<boolean> => {
    const usernameQuery = query(
      userCollection,
      where("displayName", "==", username)
    );
    const querySnapshot = await getDocs(usernameQuery);

    if (querySnapshot.size > 0) {
      return true;
    } else {
      return false;
    }
  };

  const slugExists = async (slug: string): Promise<boolean> => {
    const slugQuery = query(userCollection, where("slug", "==", slug));
    const querySnapshot = await getDocs(slugQuery);

    if (querySnapshot.size > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <NewUserContext.Provider
      value={{
        addressExists,
        associateWallet,
        connectToEthereum,
        connectToSolana,
        connectToNifty,
        getChallengeLogin,
        getNiftyAccount,
        getUserByHandle,
        signIn,
        signUp,
        updateWallet,
        usernameExists,
        slugExists,
      }}
    >
      {children}
    </NewUserContext.Provider>
  );
};
