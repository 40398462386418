import React, { createContext } from "react";

const getInitialTheme = () => {
  // Check for local storage Prefs
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("color-theme");
    if (typeof storedPrefs === "string") {
      if (storedPrefs === "dark")
        window.document.documentElement.classList.add("dark");
      if (
        storedPrefs === "light" &&
        window.document.documentElement.classList.contains("dark")
      )
        window.document.documentElement.classList.remove("dark");
      return storedPrefs;
    }

    // Check for User system prefrences
    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      window.document.documentElement.classList.add("dark");
      return "dark";
    }
  }
  if (window.document.documentElement.classList.contains("dark")) {
    window.document.documentElement.classList.remove("dark");
  }
  // Light as the default
  return "light";
};

export const ThemeContext: React.Context<[string, () => void]> = createContext([
  getInitialTheme(),
  () => {},
]);

interface Props {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = React.useState(getInitialTheme);

  // Handle toggling the theme
  const toggleTheme = (): void => {
    // First update the html
    if (typeof window !== "undefined" && window.document) {
      theme === "light"
        ? window.document.documentElement.classList.add("dark")
        : window.document.documentElement.classList.remove("dark");
    }

    if (theme === "light") {
      window.localStorage.setItem("color-theme", "dark");
      setTheme("dark");
    } else {
      window.localStorage.setItem("color-theme", "light");
      setTheme("light");
    }
  };

  return (
    <ThemeContext.Provider value={[theme, toggleTheme]}>
      {children}
    </ThemeContext.Provider>
  );
};
