import { collection, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useSigninCheck,
} from "reactfire";
import ProfileLoggedIn from "../components/ProfileLoggedIn";
import ProfileLoggedOut from "../components/ProfileLoggedOut";
import { UserContext, UserEntry } from "../lib/UserContext";

const Profile = () => {
  const firestore = useFirestore();
  const { status, data: signInCheckResult } = useSigninCheck();
  const [profileUser, setProfileUser] = useState<UserEntry | undefined>();
  const { userData, userSlug } = useContext(UserContext);
  const [isPublic, setIsPublic] = useState<boolean | undefined>();
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const { username } = useParams();

  const userCollection = collection(firestore, "users");
  const userQuery = query(userCollection, where("slug", "==", username));
  const { status: userDataStatus, data: queryUserData } =
    useFirestoreCollectionData(userQuery, { idField: "id" });

  useEffect(() => {
    if (userDataStatus === "loading") {
      console.log("Loading User Data");
    } else if (userDataStatus === "error") {
      console.log("Error Loading User Data");
    } else if (userDataStatus === "success") {
      console.log("Success Loading User Data");
      if (queryUserData && queryUserData[0]) {
        const user = queryUserData[0];
        user.createdAt = new Date(user.createdAt * 1000);

        // Logic for logged in user's own profile
        if (userSlug === username && userData) {
          setProfileUser(userData);
          setIsLoggedInUser(true);
          setIsPublic(true);
          // Another user's profile
        } else if (username !== userSlug) {
          if (user.isPublic) {
            setIsPublic(true);
            setProfileUser(user as UserEntry);
          } else {
            setIsPublic(false);
          }
        }
      } else {
        setIsPublic(false);
      }
    }
  }, [
    profileUser,
    queryUserData,
    userData,
    userDataStatus,
    userSlug,
    username,
  ]);

  if (status === "loading" || isPublic === undefined) {
    return (
      <div className="h-full w-full min-h-full flex justify-center items-center">
        {/* Loading */}
      </div>
    );
  } else if (signInCheckResult.signedIn === true) {
    return (
      <ProfileLoggedIn
        profileUser={profileUser}
        isLoggedInUser={isLoggedInUser}
      />
    );
  } else {
    return <ProfileLoggedOut profileUser={profileUser} />;
  }
};

export default Profile;
