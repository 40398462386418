import { useContext } from "react";
import { SingleValue } from "react-select";
import { DeviceEntry } from "../lib/DeviceConstants";
import { DeviceContext } from "../lib/DeviceContext";
import AFButton from "./AFButton";
import AFSelect from "./AFSelect";
import "flatpickr/dist/flatpickr.css";

export default function DeviceControlSlideshow(device: DeviceEntry) {
  const { updateDeviceSettings } = useContext(DeviceContext);
  const timeValues = [
    { value: 5, label: "5 sec" },
    { value: 10, label: "10 sec" },
  ];

  const handleSelectChange = (
    newValue: SingleValue<{ value: string | number; label: string }>
  ) => {
    const newDuration = newValue?.value;
    if (newDuration && typeof newDuration === "number") {
      updateDeviceSettings &&
        updateDeviceSettings({
          deviceID: device.id,
          options: { slideshowDuration: newDuration },
        }).catch((e) => console.error("UPDATING TIMEZONE ERROR", e));
    }
  };

  const handleToggleEnabled = () => {
    const newValue = !device.state.sleepSchedule.enabled;
    device.state.sleepSchedule.enabled = newValue;
    updateDeviceSettings &&
      updateDeviceSettings({
        deviceID: device.id,
        options: { slideshow: newValue },
      }).catch((e) => console.error("BIG ERROR", e));
  };

  return (
    <div className="flex flex-col w-auto h-auto justify-center text-center">
      <div className="mt-4 w-48 mx-auto">
        {timeValues && (
          <AFSelect
            options={timeValues}
            defaultValue={timeValues[0]}
            handleChange={handleSelectChange}
          />
        )}
        <div></div>
        <div
          className="mt-4"
          aria-hidden="true"
          onClick={() => handleToggleEnabled()}
        >
          <AFButton
            disabled={false}
            width={undefined}
            fullWidth={true}
            flexFill={undefined}
            variant={"fill"}
            type={"button"}
          >
            <p>{device.state.slideshow ? "Turn Off" : "Turn On"}</p>
          </AFButton>
        </div>
      </div>
    </div>
  );
}
