export default function ActionIconBluetooth() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 45 45"
      fill="current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2883 10.377L28.3359 17.4492L22.3428 22.1338L28.2761 27.3515L19.2883 34.377V24.5214L14.6869 28.1181L13.3826 26.4913L19.0768 22.0403L13.3359 16.992L14.7336 15.4425L19.2883 19.4478V10.377ZM21.3897 24.0742L25.0102 27.258L21.3897 30.088L21.3897 24.0742ZM21.3897 20.2325V14.6659L24.9504 17.4492L21.3897 20.2325Z"
      />
      <path d="M27.879 24.213L25.3359 22.1515L27.9559 20.1109C28.3359 21.5082 28.2761 23.1619 27.879 24.213Z" />
      <path d="M29.2023 19.1965L30.8238 17.9232C32.1461 20.9286 31.7654 24.2545 30.6654 26.6217L29.1079 25.23C29.8161 23.0464 29.8882 21.3363 29.2023 19.1965Z" />
    </svg>
  );
}
