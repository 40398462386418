import { SearchIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { LendingHistoryItem } from "../lib/NFTContext";
import { UserEntry } from "../lib/UserContext";
import Grid from "./Grid";
import StackedColumnTable from "./StackedColumnTable";
import Tabs from "./Tabs";
import { walletEntryFirestoreDataConverter } from "../lib/WalletConstants";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Query,
  where,
} from "firebase/firestore";
import {
  displayNFTFirestoreDataConverter,
  LentNFT,
  lentNFTFirestoreDataConverter,
} from "../lib/NFTContstants";
import ProfileLending from "./ProfileLendingComponent";
import AFButton from "./AFButton";
import NewModal from "./NewModal";
import MediaUpload from "./MediaUpload";

export default function ProfileBody({
  user,
  isLoggedInUser = false,
}: {
  user: UserEntry;
  isLoggedInUser: boolean;
}) {
  const [activeTab, setActiveTab] = useState(0);
  const firestore = useFirestore();
  const [modalOpen, toggleModal] = useState(false);
  const [lendingHistoryData, setLendingHistoryData] = useState<LentNFT[]>([]);
  const [enrichedHistory, setEnrichedHistory] = useState<LendingHistoryItem[]>(
    []
  );
  const [walletArrayState, setWalletArrayState] = useState<string[]>([]);

  // const { enrichedHistory } = useContext(NFTContext);
  const [nftSearch, setNFTSearch] = useState("");

  const walletCollection = collection(firestore, "wallets").withConverter(
    walletEntryFirestoreDataConverter
  );
  const walletQuery = query(walletCollection, where("ownerId", "==", user.id));
  const { data: wallets } = useFirestoreCollectionData(walletQuery);

  const nftCollection = collection(firestore, "nfts").withConverter(
    displayNFTFirestoreDataConverter
  );
  const nftQuery = query(nftCollection, where("ownerId", "==", user.id));
  const { data: nfts } = useFirestoreCollectionData(nftQuery);

  useEffect(() => {
    const lendingCollection = collection(firestore, "lending").withConverter(
      lentNFTFirestoreDataConverter
    );
    const walletArray: string[] = wallets.map((wallet) => wallet.address);
    setWalletArrayState(walletArray);
    const getLendingHistory = async (
      borrowerQuery: Query,
      lenderQuery: Query
    ) => {
      const lendingData: LentNFT[] = [];
      const borrowerQuerySnapshot = await getDocs(borrowerQuery);
      borrowerQuerySnapshot.forEach((doc) => {
        lendingData.push(doc.data() as LentNFT);
      });
      const lenderQuerySnapshot = await getDocs(lenderQuery);
      lenderQuerySnapshot.forEach((doc) => {
        lendingData.push(doc.data() as LentNFT);
      });
      return lendingData;
    };

    if (walletArray.length > 0) {
      const lenderQuery = query(
        lendingCollection,
        where("lender", "in", walletArray)
      );
      const borrowerQuery = query(
        lendingCollection,
        where("borrower", "in", walletArray)
      );

      getLendingHistory(lenderQuery, borrowerQuery)
        .then((data) => {
          setLendingHistoryData(data);
        })
        .catch((e) => {
          console.error("Error getting lending history");
        });
    }
  }, [firestore, wallets]);

  useEffect(() => {
    const generateEnrichedData = async () => {
      return await Promise.all(
        lendingHistoryData.map(async (lend) => {
          const nftRef = doc(firestore, "nfts", lend.nftId).withConverter(
            displayNFTFirestoreDataConverter
          );
          const docSnap = await getDoc(nftRef);

          let status: LendingHistoryItem["status"];

          const current = new Date();
          if (current < lend.startDate) {
            status = "Future";
          } else if (current > lend.endDate) {
            status = "Past";
          } else {
            status = "Active";
          }

          if (docSnap.exists()) {
            const nft = docSnap.data();

            let type: LendingHistoryItem["type"];
            // Check if the wallet address belongs to the current user
            if (walletArrayState.includes(nft.walletAddress)) {
              type = "Lend";
            } else {
              type = "Borrow";
            }

            return {
              lendId: lend.id,
              lender: lend.lender,
              borrower: lend.borrower,
              startDate: lend.startDate,
              endDate: lend.endDate,
              nftId: lend.nftId,
              status,
              type,
              lendStatus: lend.status,
              signature: lend.signature,
              artworkURL: nft.artworkURL,
              thumbnailURL: nft.thumbnailURL,
              name: nft.name,
              apiID: nft.apiID,
              chainID: nft.chainID,
              contractAddress: nft.contractAddress,
              tokenID: nft.tokenID,
            };
          } else {
            return {
              lendId: lend.id,
              lender: lend.lender,
              borrower: lend.borrower,
              startDate: lend.startDate,
              endDate: lend.endDate,
              nftId: lend.nftId,
              signature: lend.signature,
              status,
              lendStatus: lend.status,
            };
          }
        })
      );
    };

    if (
      lendingHistoryData.length > 0 &&
      enrichedHistory.length === 0 &&
      walletArrayState.length > 0
    ) {
      generateEnrichedData()
        .then((data) => {
          if (data !== undefined) {
            setEnrichedHistory(
              // @ts-ignore-next-line
              data
                .filter(
                  (d) => d.type !== undefined && d.lendStatus === "accepted"
                )
                .sort((a, b) =>
                  a.endDate.getTime() > b.endDate.getTime() ? -1 : 1
                )
            );
          }
        })
        .catch((e) => console.error("Error enriching history", e));
    }
  }, [enrichedHistory.length, firestore, lendingHistoryData, walletArrayState]);

  const filteredNFTs =
    nftSearch && nftSearch !== ""
      ? nfts?.filter(
          (nft) =>
            nft.name?.toLowerCase().includes(nftSearch.toLowerCase()) === true
        )
      : nfts;

  return (
    <>
      <div className="mx-2 border-b">
        <div className="flex-grow mt-6 mx-6 md:flex-row flex-row flex">
          <div className="font-semibold text-xl flex-1">{user.displayName}</div>
        </div>
        {user.website && (
          <div className="mx-6 mb-2 flex-1">
            <a
              className="text-primary dark:text-darkPrimary hover:text-purpleLighter hover:dark:text-purpleLighter underline"
              href={user.website}
              target="_blank"
            >
              {user.website}
            </a>
          </div>
        )}
        <div className="mt-2 mb-4 mx-6 flex-0">
          <p className="whitespace-pre-wrap">{user?.bio}</p>
        </div>
        {/* <p className="mx-6 my-2 italic text-gray-500">
          Joined: {createdAt.getMonth()}/{createdAt.getFullYear()}
        </p> */}
      </div>
      <div className="my-3 mx-8">
        <Tabs
          tabs={[
            {
              name: "Wallets",
              count: wallets?.length,
              index: 0,
            },
            {
              name: "NFTs",
              count: nfts?.length,
              index: 1,
            },
            {
              name: "Lending History",
              count: enrichedHistory?.length,
              index: 2,
            },
          ]}
          activeTab={activeTab}
          setTab={setActiveTab}
        />
      </div>
      <div className="mx-3">
        {activeTab === 0 && (
          <StackedColumnTable
            wallets={wallets}
            isLoggedInUser={isLoggedInUser}
          />
        )}
        {activeTab === 1 && (
          <div>
            {isLoggedInUser && (
              <div className="flex justify-end w-1/2 ml-auto">
                <AFButton
                  disabled={false}
                  width={undefined}
                  fullWidth={undefined}
                  flexFill={true}
                  variant={"fill"}
                  type={undefined}
                  clickHandler={() => toggleModal(true)}
                >
                  <p>Add OffChain Media</p>
                </AFButton>
              </div>
            )}
            <div className="sm:flex-auto">
              <h1 className="text-xl mx-6 font-semibold text-primary dark:text-darkPrimary">
                NFTs
              </h1>
              <p className="mt-2 mx-6 text-sm text-primary dark:text-darkPrimary">
                The NFTs that belong to this Account
              </p>
            </div>
            <form className="w-full flex md:ml-0 my-4 border rounded-lg">
              <label htmlFor="mobile-search-field" className="sr-only">
                Search
              </label>
              <label htmlFor="desktop-search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-primary dark:text-darkPrimary rounded">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                  <SearchIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
                <input
                  name="mobile-search-field"
                  id="mobile-search-field"
                  className="h-full w-full rounded-xl border-transparent py-2 pl-8 pr-3 bg-transparent text-base text-primary dark:text-darkPrimary focus:ring-0 focus:outline-purple focus:border-transparent sm:hidden bg-surface dark:bg-darkSurface"
                  placeholder="Search"
                  type="search"
                  value={nftSearch}
                  onInput={(e) => {
                    setNFTSearch(e.target.value);
                  }}
                />
                <input
                  name="desktop-search-field"
                  id="desktop-search-field"
                  className="hidden h-full w-full rounded-xl border-transparent py-2 pl-8 pr-3 bg-transparent text-sm dark:text-darkPrimary text-primary focus:outline-purple focus:ring-2 focus:ring-purple focus:border-transparent sm:block bg-surface dark:bg-darkSurface"
                  placeholder="Search"
                  type="search"
                  value={nftSearch}
                  onInput={(e: { target: { value: string } }) => {
                    setNFTSearch(e.target.value);
                  }}
                />
              </div>
            </form>
            <Grid nfts={filteredNFTs} />
          </div>
        )}
        {activeTab === 2 && (
          <ProfileLending
            enrichedHistory={enrichedHistory}
            isLoggedInUser={isLoggedInUser}
          />
        )}
      </div>
      <NewModal
        title="Add Offchain Media"
        toggleModal={toggleModal}
        showModal={modalOpen}
      >
        <MediaUpload user={user} toggleModal={toggleModal} />
      </NewModal>
    </>
  );
}
