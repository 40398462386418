import { DisplayNFT } from "../lib/NFTContstants";
import ArtCard from "./ArtCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function Grid({ nfts }: { nfts: DisplayNFT[] | null }) {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 500: 1, 900: 2, 1200: 3 }}>
      <Masonry gutter="16px">
        {nfts &&
          nfts.map((nft: DisplayNFT) => {
            return <ArtCard nft={nft} key={nft.id} />;
          })}
      </Masonry>
    </ResponsiveMasonry>
  );
}
