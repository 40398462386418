import { useContext } from "react";
import { useParams } from "react-router-dom";
import { NFTContext } from "../lib/NFTContext";
import Grid from "../components/Grid";
import EmptyDeviceState from "../components/EmptyDeviceState";
import { NavigationContext } from "../lib/NavigationProvider";

const Device = () => {
  const { search } = useContext(NavigationContext);
  const { id } = useParams();
  const { combinedData } = useContext(NFTContext);

  const deviceNFTs =
    combinedData &&
    combinedData.filter((nft) => {
      if (nft.associations) {
        return nft.associations.some((association) => {
          return association.deviceID === id ? true : false;
        });
      } else return false;
    });

  const filteredDeviceNFTs =
    search && search !== ""
      ? deviceNFTs &&
        deviceNFTs.filter(
          (nft) =>
            nft.name?.toLowerCase().includes(search.toLowerCase()) === true
        )
      : deviceNFTs;

  if (deviceNFTs && deviceNFTs.length > 0) {
    return <Grid nfts={filteredDeviceNFTs} />;
  } else {
    return <EmptyDeviceState />;
  }
};

export default Device;
