interface Props {
  name: string;
  clickHandler: () => void;
}

const Button = ({ name, clickHandler }: Props) => {
  return (
    <button
      className="z-40 h-10 font-light rounded text-primary hover:bg-darkPopupSecondary dark:hover:bg-darkBackground dark:text-darkPrimary w-full"
      onClick={() => clickHandler()}
    >
      {name}
    </button>
  );
};

export default Button;
