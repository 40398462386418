import { classNames } from "../lib/uiUtils";

interface TabProps {
  tabs: Tab[];
  activeTab: number;
  setTab: (arg0: number) => void;
}
interface Tab {
  name: string;
  count?: number;
  index: number;
}

export default function Tabs({ tabs, activeTab, setTab }: TabProps) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md bg-surface dark:bg-darkSurface"
          defaultValue={tabs[activeTab].name}
          onChange={(e) => {
            const newValue = e.target.value;
            const newTab = tabs.filter((tab) => tab.name === newValue);
            setTab(newTab[0].index);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setTab(tab.index)}
                className={classNames(
                  tab.index === activeTab
                    ? "border-purpleLighter text-purpleLighter"
                    : "border-transparent hover:text-purpleLighter hover:border-purpleLighter",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm hover:text-purpleLighter"
                )}
                aria-current={tab.index === activeTab ? "page" : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.index === activeTab
                        ? "bg-purpleLighter text-darkPrimary dark:text-darkPrimary"
                        : "bg-primary text-darkPrimary dark:bg-darkPrimary dark:text-primary",
                      "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
