import { DisplayNFT } from "../lib/NFTContstants";
import Panel from "./Panel";

interface Props {
  nft: DisplayNFT;
  classNames?: string;
}

const ArtCardMini = ({ nft, classNames = "" }: Props) => {
  const passedClasses = `w-auto h-auto p-0 transition-all ${classNames}`;
  return (
    <Panel
      injectedClassesOuter={passedClasses}
      injectedClassesInner={"w-auto h-auto"}
    >
      <article className={`w-auto h-auto ${classNames}`}>
        <img
          className={`w-full h-auto block object-fill transition-all ${classNames}`}
          src={nft.thumbnailURL}
          alt={nft.name}
        />
      </article>
      <span
        title={nft.name}
        className="text-ellipsis inline-block overflow-hidden whitespace-nowrap p-1 w-full text-center font-medium"
      >
        {nft.name}
      </span>
    </Panel>
  );
};

export default ArtCardMini;
