import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import firebaseConfig from "./../../firebase-config.json";

import {
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  AuthProvider,
  useFirebaseApp,
  AnalyticsProvider,
} from "reactfire";

import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { FunctionComponent } from "react";
import { getAnalytics } from "firebase/analytics";
// import { connectDatabaseEmulator } from "firebase/database";

interface Props {
  children: React.ReactNode;
}

const InnerFirebaseWrapper: FunctionComponent<Props> = ({ children }) => {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  const functionsInstance = getFunctions(app);

  // connectAuthEmulator(auth, "http://localhost:9099");
  // connectFirestoreEmulator(firestoreInstance, "localhost", 8580);
  // connectFunctionsEmulator(functionsInstance, "localhost", 5501);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <FunctionsProvider sdk={functionsInstance}>
          <AnalyticsProvider sdk={getAnalytics(app)}>
            {children}
          </AnalyticsProvider>
        </FunctionsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
};

const FirebaseProvider: FunctionComponent<Props> = ({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <InnerFirebaseWrapper>{children}</InnerFirebaseWrapper>
    </FirebaseAppProvider>
  );
};

export default FirebaseProvider;
