import OverflowMenu from "./OverflowMenu";
import Button from "./Button";
import { useContext, useState } from "react";
import Overflow from "../resources/reactIcons/overflow";
import { UserContext } from "../lib/UserContext";
import WalletEntry, { getWalletTypeName } from "../lib/WalletConstants";
import NewModal from "./NewModal";
import ConnectWalletWrapper from "./ConnectWalletWrapper";
import { NavigationContext } from "../lib/NavigationProvider";

export default function StackedColumnTable({
  wallets,
  isLoggedInUser = false,
}: {
  wallets: WalletEntry[];
  isLoggedInUser: boolean;
}) {
  const { removeWallet, syncWallet } = useContext(UserContext);
  const { search } = useContext(NavigationContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [openFloatingMenu, setOpenFloatingMenu] = useState(false);
  const [clientCoords, setClientCoords] = useState([0, 0]);
  const [overflowWallet, setOverflowWallet] = useState<WalletEntry | void>();

  const walletSyncHandler = async (wallet: WalletEntry) => {
    if (syncWallet && wallet) {
      try {
        await syncWallet(wallet.address);
      } catch (e) {
        console.error("ERROR", e);
      }
    }
  };

  const filteredWallets =
    search && search !== ""
      ? wallets &&
        wallets.filter(
          (wallet) =>
            wallet.address.includes(search.toLowerCase()) === true &&
            !wallet.apiID?.includes(0)
        )
      : wallets.filter((wallet) => !wallet.apiID?.includes(0));

  return (
    <div className="mt-4 px-3">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex items-center">
              {/* {WalletIcon()} */}
              <h1 className="text-xl ml-3 font-semibold">Wallets</h1>
            </div>
            {isLoggedInUser && (
              <div className="">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-purple px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-lighter focus:outline-none focus:ring-2 focus:purple focus:ring-offset-2 sm:w-auto"
                  onClick={() => setModalOpen(true)}
                >
                  Add wallet
                </button>
              </div>
            )}
          </div>
          {isLoggedInUser && (
            <>
              <p className="mt-2 px-4 text-sm">
                A list of all the Wallets in your account including their name,
                chain, and status.
              </p>
              <p className="mt-2 px-4 text-sm">
                Add wallets, sync them or remove them from your account here
              </p>
            </>
          )}
        </div>
      </div>
      <div className="mt-8 -mx-2 lg:mx-2 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y dark:divide-surface divide-popupBackground">
          <thead className="bg-surface dark:bg-popupBackground">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
              >
                Address
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell"
              >
                Name Service
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold sm:table-cell"
              >
                Chain
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold"
              >
                Date Added
              </th>
              {isLoggedInUser && (
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="text-end">Edit</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y dark:divide-popupSecondary divide-symbolFaint bg-surface dark:bg-popupBackgroundSecondary">
            {filteredWallets &&
              filteredWallets.map((wallet) => (
                <tr key={wallet.address}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-left text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                    {wallet.address}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Name Service</dt>
                      <dd className="mt-1 truncate">{wallet.nameService}</dd>
                      <dt className="sr-only sm:hidden">Chain</dt>
                      <dd className="mt-1 truncate sm:hidden">
                        {getWalletTypeName(wallet.apiID, wallet.chainID)}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm lg:table-cell">
                    {wallet.nameService}
                  </td>
                  <td className="hidden px-3 py-4 text-sm sm:table-cell">
                    {getWalletTypeName(wallet.apiID, wallet.chainID)}
                  </td>
                  <td className="px-3 py-4 text-sm text-left">
                    {wallet.associationDate.getMonth()}/
                    {wallet.associationDate.getFullYear()}
                  </td>
                  {isLoggedInUser && (
                    <td className="py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenFloatingMenu(true);
                          setClientCoords([e.clientX - 162, e.clientY + 7]);
                          setOverflowWallet(wallet);
                        }}
                      >
                        <Overflow
                          passedClasses="
                        hover:bg-symbolFaint
                        hover:dark:bg-darkSurface
                        fill-primaryw
                        dark:fill-darkPrimary
                        text-end
                        h-10
                        w-10
                        p-2
                        rounded"
                        />
                      </button>
                    </td>
                  )}
                  {isLoggedInUser && openFloatingMenu && (
                    <OverflowMenu
                      coords={clientCoords}
                      setOpen={() => setOpenFloatingMenu(false)}
                    >
                      <div className="flex flex-col w-full w-40 p-2">
                        <div className="w-full w-30 flex justify-center font-light relative z-40">
                          <Button
                            name={"Sync Contents"}
                            clickHandler={() => {
                              overflowWallet &&
                                walletSyncHandler &&
                                walletSyncHandler(overflowWallet);
                            }}
                          ></Button>
                        </div>
                        <div className="w-full w-30 flex justify-center font-light relative z-40">
                          <Button
                            name={"Remove from AF"}
                            clickHandler={() => {
                              overflowWallet &&
                                removeWallet &&
                                wallets.length > 1 &&
                                removeWallet(overflowWallet.address);
                            }}
                          ></Button>
                        </div>
                      </div>
                    </OverflowMenu>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        {modalOpen && (
          <NewModal title="" showModal={modalOpen} toggleModal={setModalOpen}>
            <ConnectWalletWrapper setModalOpen={setModalOpen} />
          </NewModal>
        )}
      </div>
    </div>
  );
}
