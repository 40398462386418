import { FunctionComponent } from "react";
import { classNames } from "../lib/uiUtils";

interface Props {
  children: React.ReactNode;
  injectedClassesOuter?: string;
  injectedClassesInner?: string;
}

const Panel: FunctionComponent<Props> = ({
  children,
  injectedClassesOuter = "",
  injectedClassesInner = "",
}) => (
  <div
    className={classNames(
      "overflow-hidden bg-surface dark:bg-darkSurface shadow-card dark:shadow-darkCard rounded-lg ",
      injectedClassesOuter
    )}
  >
    <div className={classNames("sm:rounded-lg w-full", injectedClassesInner)}>
      {children}
    </div>
  </div>
);

export default Panel;
