import { classNames } from "../lib/uiUtils";

interface Props {
  disabled: boolean;
  width: number | undefined;
  fullWidth: boolean | undefined;
  flexFill: boolean | undefined;
  variant: "fill" | "accent" | "outline";
  type: "submit" | "reset" | "button" | "file" | undefined;
  children: JSX.Element;
  clickHandler?: (value: any) => void;
}

const fillClasses =
  "border-none bg-primary text-darkPrimary dark:bg-darkPrimary dark:text-primary hover:opacity-80";
const accentClasses =
  "border-none bg-purple text-darkPrimary hover:bg-purpleLighter";
const outlineClasses =
  "text-primary bg-transparent border-2 border-primary box-border dark:text-darkPrimary hover:dark:text-primary dark:border-darkPrimary hover:secondary hover:bg-primary hover:text-darkPrimary hover:dark:bg-darkPopupPrimary";
const disabledClasses = "opacity-50";
const fullWidthClasses = "w-full";
const flexFillClasses = "flex-1";

const AFButton = ({
  disabled = false,
  width = undefined,
  fullWidth = undefined,
  flexFill = undefined,
  variant = "fill",
  type = undefined,
  children,
  clickHandler = undefined,
}: Props) => {
  let additionalClasses = "";
  if (variant === "fill") additionalClasses += `${fillClasses}`;
  if (variant === "accent") additionalClasses += ` ${accentClasses}`;
  if (variant === "outline") additionalClasses += ` ${outlineClasses}`;
  if (disabled) additionalClasses += ` ${disabledClasses}`;
  if (fullWidth) additionalClasses += ` ${fullWidthClasses}`;
  if (flexFill) additionalClasses += ` ${flexFillClasses}`;
  return (
    <button
      className={classNames(
        "h-10 font-bold rounded transition-all flex-shrink-0 flex items-center justify-center",
        additionalClasses
      )}
      onClick={clickHandler}
      onKeyDown={clickHandler}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default AFButton;
