export default function AFSpinner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      className="animate-[spin_4s_linear_infinite] fill-primary dark:fill-darkPrimary"
      fill="current"
    >
      <path d="M48.9977 19.0174H40.9474V10.969H48.9977V19.0174Z" />
      <path d="M51.96 29.9816L44.9882 25.9573L49.0134 18.9871L55.9852 23.0114L51.96 29.9816Z" />
      <path d="M49.0282 40.9575L45.003 33.9873L51.9748 29.9631L56 36.9333L49.0282 40.9575Z" />
      <path d="M40.9851 48.9935V40.945H49.0354V48.9935H40.9851Z" />
      <path d="M30.0186 51.955L34.0437 44.9848L41.0155 49.009L36.9904 55.9792L30.0186 51.955Z" />
      <path d="M19.0401 49.0239L26.0119 44.9997L30.0371 51.9699L23.0653 55.9941L19.0401 49.0239Z" />
      <path d="M11.0023 40.9827H19.0527V49.0312H11.0023V40.9827Z" />
      <path d="M8.04018 30.0187L15.012 34.0429L10.9868 41.0131L4.01502 36.9889L8.04018 30.0187Z" />
      <path d="M10.9718 19.0427L14.997 26.0129L8.02516 30.0372L4 23.067L10.9718 19.0427Z" />
      <path d="M19.0149 11.0067V19.0552H10.9646V11.0067H19.0149Z" />
      <path d="M29.9816 8.04503L25.9564 15.0152L18.9846 10.991L23.0098 4.02078L29.9816 8.04503Z" />
      <path d="M23.0873 56L19.1217 49.1331L25.9902 45.1684L29.9558 52.0354L23.0873 56Z" />
      <path d="M36.9133 4L40.8789 10.8669L34.0104 14.8316L30.0448 7.96463L36.9133 4Z" />
    </svg>
  );
}
