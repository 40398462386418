import { useContext } from "react";
import { Header } from "./Header";
import LayoutProfile from "./LayoutProfile";
import SidebarDesktop from "./SidebarDesktop";
import { NavigationContext } from "../lib/NavigationProvider";
import { UserEntry } from "../lib/UserContext";
import NotFound from "../pages/NotFound";

const ProfileLoggedIn = ({
  profileUser,
  isLoggedInUser,
}: {
  profileUser: UserEntry | undefined;
  isLoggedInUser: boolean;
}) => {
  const { navigation } = useContext(NavigationContext);

  return (
    <div className="h-full">
      <Header />
      <div className="h-full">
        <SidebarDesktop navigation={navigation} />
        <div
          className="md:pl-mainLeft pl-5 bg-darkPopupSecondary dark:bg-darkBackground"
          style={{ height: "96vh" }}
        >
          <div className="pl-5 flex flex-col w-full h-full pb-5">
            <main className="overflow-auto flex-1 basis-0 min-h-content pr-0.5 scrollbar-custom mr-4">
              <div className="relative mx-auto mr-3">
                <div className="pb-1">
                  {profileUser === undefined && <NotFound />}
                  {profileUser !== undefined && (
                    <LayoutProfile
                      profileUser={profileUser}
                      isLoggedInUser={isLoggedInUser}
                    />
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLoggedIn;
