import { useNavigate } from "react-router-dom";
import AFButton from "../components/AFButton";
import Panel from "../components/Panel";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Panel
      injectedClassesInner="m-8 w-auto"
      injectedClassesOuter="max-w-6xl mx-auto"
    >
      <div className="h-full flex justify-center items-center flex-1 flex-col grow mt-12">
        <h1 className="flex-1 text-xl font-bold mb-10">Not Found</h1>
        <p className="flex-1 text-lg font-semibold mb-8">
          A page either doesn't exist here or is private
        </p>
        <AFButton
          type={"button"}
          variant={"outline"}
          fullWidth={undefined}
          flexFill={undefined}
          disabled={false}
          width={undefined}
          clickHandler={(e) => navigate("/")}
        >
          <div className="mx-4 my-2">Go Home</div>
        </AFButton>
      </div>
    </Panel>
  );
};

export default NotFound;
