import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useContext } from "react";
import {
  useFirestore,
  useFirestoreDocData,
  useSigninCheck,
  useFirestoreCollectionData,
  useFunctions,
} from "reactfire";
import { trimOwnerId } from "./utils";
import WalletEntry, {
  walletEntryFirestoreDataConverter,
} from "./WalletConstants";
import { httpsCallable } from "firebase/functions";
import { ethers } from "ethers";
import { ExternalProvider } from "@ethersproject/providers";
import { base58, randomBytes } from "ethers/lib/utils";
import { PhantomProvider } from "../..";
import slugify from "@sindresorhus/slugify";
import { LendingHistoryItem } from "./NFTContext";
import { stringify } from "querystring";

interface UserContextProps {
  wallets: WalletEntry[] | null;
  userId?: string;
  username?: string | null;
  userSlug?: string;
  userData?: UserEntry;
  updateUser?: ({ userId, options }: UpdateUserProps) => Promise<void>;
  syncWallet?: (walletID: string) => Promise<void>;
  syncAllWallets?: () => Promise<void>;
  removeWallet?: (walletID: string) => Promise<void>;
  addressExists?: (address: string) => Promise<boolean>;
  usernameExists?: (username: string, handle: string) => Promise<boolean>;
  associateWallet?: ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps) => Promise<void>;
  connectToEthereum?: () => Promise<string | void>;
  connectToSolana?: () => Promise<{
    stringAddress: string;
    arrayAddress: Uint8Array;
  } | void>;
  connectToNifty?: () => Promise<void>;
  getChallengeLogin?: ({
    address,
    apiId,
    chainId,
  }: ChallengeProps) => Promise<string | undefined>;
  getChallengeLend?: ({
    address,
    apiId,
    chainId,
    lend,
  }: ChallengeProps) => Promise<string | undefined>;
  rejectLendNFT?: ({
    lendId,
    borrowerAddress,
  }: RejectLendProps) => Promise<void>;
  getChallengeCountersign?: ({
    address,
    apiId,
    chainId,
    lend,
  }: ChallengeProps) => Promise<string | undefined>;
  signChallengeEthereum?: ({
    address,
    message,
  }: {
    address: string;
    message: string;
  }) => Promise<string | void>;
  signChallengeSolana?: ({ message }: { message: Uint8Array }) => Promise<{
    publicKey: PhantomProvider["publicKey"];
    signature: Uint8Array;
  } | void>;
  getUserByHandle?: (handle: string) => Promise<UserEntry | undefined>;
}

export const UserContext = React.createContext<UserContextProps>({
  //   data: { status: "pending", value: { username: "", id: "" } },
  wallets: null,
});

interface Props {
  children: React.ReactNode;
}

export interface UserEntry {
  createdAt: Date;
  username: string;
  displayName: string;
  slug: string;
  photoURL?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  isPublic?: boolean;
  website?: string;
  id?: string;
}

export interface UpdateUserProps {
  userId: string;
  options: Partial<UserEntry>;
}

export interface LendChallengeProps {
  lender: string;
  borrower: string;
  startDate: Date;
  endDate: Date;
  nftId: string;
}

export interface ChallengeProps {
  address: string;
  apiId: number[];
  chainId: number[];
  lend?: LendChallengeProps;
}

export interface CountersignChallengeProps {
  address: string;
  apiId: number[];
  chainId: number[];
  lend?: LendChallengeProps;
  hash?: string;
  nft?: LendingHistoryItem;
}

export interface RejectLendProps {
  lendId: string;
  borrowerAddress: string;
}

interface AssociateWalletProps {
  address: string | Uint8Array;
  signature: string | Uint8Array;
  apiId: number[];
  chainId: number[];
  message?: Uint8Array;
}

export const connectToEthereum = async (): Promise<string | void> => {
  let provider: ethers.providers.Web3Provider;
  const externalProvider = window?.ethereum as ExternalProvider;
  if (externalProvider && externalProvider.request) {
    try {
      provider = new ethers.providers.Web3Provider(externalProvider);
      await externalProvider.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      const accounts = await provider.listAccounts();
      const sanitizedAccount = accounts[0].toLowerCase();
      return sanitizedAccount;
    } catch (error) {
      console.error("Metamask connection error", { error, type: typeof error });
    }
  }
};

export const connectToSolana = async (): Promise<{
  stringAddress: string;
  arrayAddress: Uint8Array;
} | void> => {
  const externalProvider = window.solana;
  if (externalProvider) {
    try {
      const { publicKey } = await externalProvider.connect();
      const stringAddress = publicKey.toString();
      const arrayAddress = publicKey.toBuffer();
      return { stringAddress, arrayAddress };
    } catch (error) {
      console.error("Solana connection error", { error, type: typeof error });
    }
  }
};

export const connectToNifty = async (): Promise<void> => {
  const random = randomBytes(16);
  const randomString = base58.encode(random);
  window.localStorage.setItem("niftyState", randomString);
  const urlOrigin = window.location.origin;
  const redirectUri =
    "https://niftygateway.com/authorize?scope=profile:read+email:read&state=" +
    randomString +
    "&client_id=4zBx0BF4Wiyd6WqohisfHXAOflsy4n2wwiK3kNVu&redirect_uri=" +
    urlOrigin +
    "/nifty-callback&response_type=token";
  window.location.replace(redirectUri);
};

export const signChallengeEthereum = async ({
  address,
  message,
}: {
  address: string;
  message: string;
}): Promise<string | void> => {
  let provider: ethers.providers.Web3Provider;
  const externalProvider = window?.ethereum as ExternalProvider;
  if (externalProvider) {
    provider = new ethers.providers.Web3Provider(externalProvider);
    const signedMessage = await provider
      .getSigner(address)
      .signMessage(message);
    return signedMessage;
  }
};

export const signChallengeSolana = async ({
  message,
}: {
  message: Uint8Array;
}): Promise<{
  publicKey: PhantomProvider["publicKey"];
  signature: Uint8Array;
} | void> => {
  const externalProvider = window.solana as PhantomProvider;
  if (externalProvider) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const signedMessage: {
        publicKey: PhantomProvider["publicKey"];
        signature: Uint8Array;
      } = window.solana && (await window.solana.signMessage(message, "utf8"));
      return signedMessage;
    } catch (error) {
      console.error("Sign Challenge Login Solana error", {
        error,
        type: typeof error,
      });
    }
  }
};

export const UserProvider: React.FC<Props> = ({ children }) => {
  const firestore = useFirestore();
  const functions = useFunctions();
  const { data: signInCheckResult } = useSigninCheck();
  const { user } = signInCheckResult;
  const uid = user && user.uid;
  const ownerId = trimOwnerId(uid) || "";
  const username = user && user.displayName;
  // const userDoc = await getDoc(doc(firestore, "users", ownerId));
  const userRef = doc(useFirestore(), "users", ownerId);
  const { status, data } = useFirestoreDocData(userRef);
  const userData =
    status === "loading" ? ({} as UserEntry) : (data as UserEntry);
  userData.id = ownerId;
  const userSlug = userData.slug;

  const getChallengeLogin = async ({
    address,
    apiId,
    chainId,
  }: ChallengeProps): Promise<string | undefined> => {
    try {
      const { data } = await httpsCallable<ChallengeProps, { message: string }>(
        functions,
        "getChallenge"
      )({ address, apiId, chainId });
      return data.message;
    } catch (e) {
      console.warn("GET CHALLENGE ERROR", e);
    }
  };

  const getChallengeLend = async ({
    address,
    apiId,
    chainId,
    lend,
  }: ChallengeProps): Promise<string | undefined> => {
    try {
      const { data } = await httpsCallable<ChallengeProps, { message: string }>(
        functions,
        "getChallengeLending"
      )({ address, apiId, chainId, lend });
      return data.message;
    } catch (e) {
      console.warn("GET CHALLENGE ERROR", e);
      throw e;
    }
  };

  const getChallengeCountersign = async ({
    address,
    apiId,
    chainId,
    lend,
  }: CountersignChallengeProps): Promise<string | undefined> => {
    try {
      const { data } = await httpsCallable<ChallengeProps, { message: string }>(
        functions,
        "getChallengeCountersign"
      )({ address, apiId, chainId, lend });
      return data.message;
    } catch (e) {
      console.warn("GET CHALLENGE ERROR", e);
      throw e;
    }
  };

  const rejectLendNFT = async ({
    lendId,
    borrowerAddress,
  }: {
    lendId: string;
    borrowerAddress: string;
  }): Promise<void> => {
    try {
      await httpsCallable<{ lendId: string; borrowerAddress: string }>(
        functions,
        "rejectLendNFT"
      )({ lendId, borrowerAddress });
    } catch (e) {
      console.error("Challenge Error", e);
      throw e;
    }
  };

  const walletCollection = collection(firestore, "wallets").withConverter(
    walletEntryFirestoreDataConverter
  );

  const userCollection = collection(firestore, "users");

  const walletQuery = query(walletCollection, where("ownerId", "==", ownerId));

  const syncWallet = async (walletID: string) => {
    return await setDoc(doc(firestore, "refresh_wallets", walletID), {
      update: true,
    });
  };

  const associateWallet = async ({
    address,
    signature,
    apiId,
    chainId,
    message,
  }: AssociateWalletProps): Promise<void> => {
    try {
      await httpsCallable<AssociateWalletProps, void>(
        functions,
        "associateWallet"
      )({ address, signature, apiId, chainId, message });
    } catch (e) {
      console.error("ASSOCIATION ERROR", e);
    }
  };

  const removeWallet = async (walletID: string): Promise<void> => {
    await deleteDoc(doc(firestore, "wallets", walletID));
  };

  const addressExists = async (address: string): Promise<boolean> => {
    const snapshot = await getDoc(doc(firestore, "wallets", address));
    if (snapshot.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const usernameExists = async (
    username: string,
    handle: string
  ): Promise<boolean> => {
    const usernameQuery = query(
      userCollection,
      where("displayName", "==", username)
    );
    const handleQuery = query(userCollection, where("slug", "==", handle));
    if (handle === userSlug) return false;
    const usernameDocs = await getDocs(usernameQuery);
    const handleQueryDocs = await getDocs(handleQuery);
    if (usernameDocs.docs.length === 0 && handleQueryDocs.docs.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const updateUser = async ({ userId, options }: UpdateUserProps) => {
    return updateDoc(doc(firestore, "users", userId), options);
  };

  const { data: wallets } = useFirestoreCollectionData(walletQuery, {
    idField: "docId",
  });

  const syncAllWallets = async () => {
    for (let i = 0; i < wallets.length; i++) {
      const currWallet = wallets[i];
      const walletID = currWallet.address;
      await syncWallet(walletID);
    }
  };

  const getUserByHandle = async (
    handle: string
  ): Promise<UserEntry | undefined> => {
    const userQuery = query(userCollection, where("slug", "==", handle));
    const querySnapshot = await getDocs(userQuery);
    let returnVal: UserEntry | undefined;
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (docData) {
        docData.createdAt = new Date(docData.createdAt * 1000);
        returnVal = docData as UserEntry;
      } else {
        return undefined;
      }
    });
    return returnVal;
  };
  if (status === "loading") {
    return (
      <div className="flex items-center justify-center w-full h-full animate-pulse">
        Loading
      </div>
    );
  } else {
    return (
      <UserContext.Provider
        value={{
          associateWallet,
          addressExists,
          connectToEthereum,
          connectToSolana,
          connectToNifty,
          getChallengeLogin,
          getChallengeLend,
          getChallengeCountersign,
          signChallengeEthereum,
          signChallengeSolana,
          rejectLendNFT,
          removeWallet,
          syncWallet,
          syncAllWallets,
          wallets,
          userId: ownerId,
          username,
          userSlug,
          userData,
          updateUser,
          usernameExists,
          getUserByHandle,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
};
