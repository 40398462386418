/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { DisplayNFT } from "../lib/NFTContstants";
import { formatThumbnailURL } from "../lib/utils";
import ChainIcon from "../resources/reactIcons/ChainIcon";

interface Prop {
  nft: DisplayNFT;
  toggleModal: (val: boolean) => void;
  showModal: boolean;
}

export default function ModalPreview({ nft, toggleModal, showModal }: Prop) {
  const thumbnailURL = formatThumbnailURL(nft.thumbnailURL);
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={() => toggleModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <div className="bg-transparent"> */}
              {/* <div className="w-8 h-8">
                  {" "}
                  <XIcon />
                </div> */}
              <Dialog.Panel className="relative text-primary dark:text-darkPrimary bg-surface dark:bg-darkSurface shadow-card dark:shadow-darkCard rounded-lg px-0 pt-0 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:max-w-sm sm:w-full">
                <div
                  className="absolute right-2 top-2 outline-none focus-none hover:text-purpleLighter"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => toggleModal(false)}
                  onClick={() => toggleModal(false)}
                >
                  X
                </div>
                <div>
                  <img className="w-full" src={thumbnailURL} alt={nft.name} />
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-semibold pl-2 pr-6"
                    >
                      {nft.name}
                    </Dialog.Title>
                    <div className="absolute right-2 bottom-5">
                      <ChainIcon apiID={nft.apiID} chainID={nft.chainID} />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
              {/* </div> */}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
