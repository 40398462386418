import { Dispatch, SetStateAction } from "react";
import { Chain } from "../lib/constants";

export default function WalletButton({
  wallet,
  setConnectingChain,
}: {
  wallet: Chain;
  setConnectingChain: Dispatch<SetStateAction<Chain | undefined>>;
}) {
  const { logo, name, website } = wallet;
  return (
    <div>
      <button
        onClick={() => {
          if (name === "Phantom" && window.solana === undefined) {
            const win = window.open(website, "_blank");
            if (win !== null) win.focus();
          } else {
            setConnectingChain(wallet);
          }
        }}
        className="w-full flex inline-flex flex-row justify-center items-center py-2 px-4 border bg-surface dark:bg-darkBackground text-primary dark:text-darkPrimary rounded-md shadow-sm text-sm font-medium hover:shadow-card dark:hover:shadow-darkCard"
      >
        <img src={logo} className="w-8 h-8 items-center mx-auto" alt={name} />
        <span className="sr-only">Sign in with {name}</span>
        <div className="relative flex flex-1 justify-center items-center text-lg">
          <span className="relative right-3">
            <>
              {name === "Ethereum" && `${name}`}
              {name === "Phantom" &&
                window.solana === undefined &&
                `Download ${name}`}
              {name === "Phantom" && window.solana !== undefined && `${name}`}
              {name === "Nifty Gateway" && `${name}`}
            </>
          </span>
        </div>
      </button>
    </div>
  );
}
