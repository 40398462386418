import React, { lazy, useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
const Modal = lazy(() => import("./Modal"));
import NewModal from "./NewModal";
import PhotonImage from "../resources/photon.svg";
import Overflow from "../resources/reactIcons/overflow";
import OverflowMenu from "./OverflowMenu";
import Button from "./Button";
import { DeviceContext } from "../lib/DeviceContext";
import Droppable from "./Droppable";
import { NFTContext } from "../lib/NFTContext";
import { useAnalytics, useAuth } from "reactfire";
import { logEvent } from "firebase/analytics";
import { trimOwnerId } from "../lib/utils";

export interface DeviceProps {
  id: string;
  name: string;
  icon: () => JSX.Element;
  type: string;
  current: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

const DeviceEntry = ({ device }: { device: DeviceProps }) => {
  const [openFloatingMenu, setOpenFloatingMenu] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [draggedOver, setDraggedOver] = useState(false);
  // const [isActiveButton, setIsActiveButton] = useState(false);
  const [clientCoords, setClientCoords] = useState([0, 0]);
  const [newName, updateNewName] = useState("");
  const { renameDevice, unpairDevice } = useContext(DeviceContext);
  const { associateNFT } = useContext(NFTContext);
  const analytics = useAnalytics();
  const auth = useAuth();

  let isActiveButton = false;
  const dropHandler = (event: React.DragEvent<HTMLAnchorElement>) => {
    setDraggedOver(false);
    event.stopPropagation();
    event.preventDefault();
    // TODO: update to only work from collection page
    const nftId = event.dataTransfer.getData("text/plain");
    associateNFT &&
      associateNFT({ nftId, deviceId: device.id })
        .then(() => {
          if (auth.currentUser) {
            const currentUser = auth.currentUser;
            const uid = currentUser.uid;
            const userId = trimOwnerId(uid);
            logEvent(analytics, "associate_nft", {
              device_id: device.id,
              nft_id: nftId,
              user_id: userId,
            });
          }
        })
        .catch((e) => console.error("ERROR", e));
  };

  const dragOverHandler = (event: React.DragEvent) => {
    event.preventDefault();
    setDraggedOver(true);
  };
  const dragLeaveHandler = (event: React.DragEvent) => {
    event.preventDefault();
    setDraggedOver(false);
  };

  return (
    <NavLink
      onDrop={(e) => dropHandler(e)}
      onDragOver={(e) => dragOverHandler(e)}
      onDragLeave={(e) => dragLeaveHandler(e)}
      key={device.id}
      title={device.name}
      to={`/device/${device.id}`}
      className={({ isActive }) => {
        isActiveButton = isActive;
        return classNames(
          isActive || draggedOver
            ? "bg-darkBackground text-darkPrimary shadow-card dark:shadow-darkCard fill-darkPrimary"
            : "text-primary hover:bg-darkPopupSecondary dark:hover:bg-darkBackground dark:text-darkPrimary fill-primary dark:fill-darkPrimary",
          "group flex items-center h-[72px] w-full px-2 py-1 text-base rounded-md z-40"
        );
      }}
    >
      <device.icon
        passedClasses={classNames(
          isActiveButton
            ? "text-darkPrimary fill-darkPrimary"
            : "text-primary dark:fill-darkPrimary dark: text-darkPrimary",
          "h-[60px] w-[60px]"
        )}
      />
      <div className="mx-2 flex flex-col items-start justify-center flex-1">
        <span className="m-0 max-w-[100px] text-start whitespace-nowrap overflow-hidden text-ellipsis w-full font-semibold">
          {device.name}
        </span>
        <span className="text-sm m-0 text-start whitespace-nowrap overflow-hidden text-ellipsis w-full">
          {device.type && capitalize(device.type)}
        </span>
      </div>
      <button
        className={classNames(
          isActiveButton ? "fill-darkPrimary dark:fill-primary " : "",
          "hidden group-hover:block absolute right-5 group:hover:transition-all group:hover:bg-surface group:hover:dark:bg-darkSurface"
        )}
        onClick={(e) => {
          e.preventDefault();
          setOpenFloatingMenu(true);
          setClientCoords([e.clientX, e.clientY]);
        }}
      >
        <Overflow
          passedClasses="
            hover:bg-surface
            hover:dark:bg-darkSurface
            fill-primary
            dark:fill-darkPrimary
            h-10
            w-10
            p-2
            rounded"
        />
      </button>
      {/* TODO: hide scrollbar on open https://github.com/reslear/tailwind-scrollbar-hide */}
      {openFloatingMenu && (
        <OverflowMenu
          coords={clientCoords}
          setOpen={() => setOpenFloatingMenu(false)}
        >
          <div className="flex flex-col w-48 justify-center p-3 font-light z-40 relative">
            <Button
              name="Rename"
              clickHandler={() => {
                toggleModal(true);
              }}
            />
            <Button
              name="Unpair"
              clickHandler={() => {
                if (unpairDevice !== undefined) {
                  unpairDevice({ deviceId: device.id }).catch((err) =>
                    alert(`Error unpairing device ${device.id} - ${err}`)
                  );
                }
              }}
            />
          </div>
        </OverflowMenu>
      )}
      {showModal && (
        <NewModal
          title={`Rename ${device.name}`}
          showModal={showModal}
          toggleModal={toggleModal}
        >
          <div className="mt-5 sm:mt-6">
            <form
              className="w-full flex flex-col mb-6 md:ml-0"
              onSubmit={(e) => {
                e.preventDefault();
                toggleModal(false);
                if (renameDevice !== undefined) {
                  renameDevice({
                    deviceId: device.id,
                    newLabel: newName,
                  }).catch((err) => alert("Error renaming device", err));
                }
              }}
            >
              <label htmlFor="mobile-rename-field" className="sr-only">
                Rename {device.name}
              </label>
              <label htmlFor="desktop-rename-field" className="sr-only">
                Rename {device.name}
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600 rounded">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"></div>
                <input
                  name="mobile-rename-field"
                  id="mobile-rename-field"
                  className="h-full w-full rounded-xl border-2 border-primary dark:border-darkPrimary py-2 pl-8 pr-3 bg-transparent text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden"
                  placeholder="Rename"
                  value={newName}
                  onChange={(e) => updateNewName(e.target.value)}
                  type="rename"
                />
                <input
                  name="desktop-rename-field"
                  id="desktop-rename-field"
                  className="hidden h-full w-full rounded-xl border-2 border-primary dark:border-darkPrimary py-2 pl-8 pr-3 bg-transparent text-sm dark:text-darkPrimary text-primary placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple focus:border-transparent focus:placeholder-gray-400 sm:block"
                  placeholder="Rename Device"
                  value={newName}
                  onChange={(e) => updateNewName(e.target.value)}
                  type="rename"
                />
              </div>
              <button
                type="submit"
                className="inline-flex justify-center w-full mt-6 rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple hover:bg-purpleLighter text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Submit
              </button>
            </form>
          </div>
        </NewModal>
      )}
    </NavLink>
  );
};

export default DeviceEntry;
