import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Chain } from "../lib/constants";
import { UserContext } from "../lib/UserContext";
import ConnectWallet from "./ConnectWallet";
import WalletEntry from "../lib/WalletConstants";
import AFButton from "./AFButton";
import StepList from "./StepList";
import { useAnalytics } from "reactfire";
import { logEvent } from "firebase/analytics";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";

interface WrapperProps {
  setModalOpen?: (arg0: boolean) => void;
}

interface Step {
  index: number;
  name: string;
  status: "complete" | "current" | "upcoming";
}

const steps: Step[] = [
  { index: 0, name: "Connect Wallet", status: "current" },
  { index: 0, name: "Verify Ownership", status: "upcoming" },
  { index: 0, name: "Authenticate With Atomic Form", status: "upcoming" },
];

export default function ConnectWalletWrapper({ setModalOpen }: WrapperProps) {
  const {
    addressExists,
    associateWallet,
    connectToSolana,
    connectToNifty,
    getChallengeLogin,
    signChallengeSolana,
    wallets,
  } = useContext(UserContext);
  const { signMessageAsync } = useSignMessage();
  const [connectingChain, setConnectingChain]: [
    Chain | undefined,
    Dispatch<SetStateAction<Chain | undefined>>
  ] = useState();
  const [step, setStep] = useState<number>(0);
  const [connectingAddress, setNewConnectingAddress] = useState("");
  const [solanaAddressArray, setSolanaAddressArray] = useState<
    undefined | Uint8Array
  >();
  const [hasError, setError] = useState<undefined | string>();
  const [isPending, setIsPending] = useState(false);
  const [implicitAddress, setImplicitAddress] = useState(false);
  const [requestingChallenge, setRequestingChallenge] = useState(false);
  const [addressAlreadyExists, setAddressAlreadyExists] = useState(false);
  const [solanaMesssage, setSolanaMessage] = useState<undefined | Uint8Array>();
  const [challenge, setChallenge] = useState("");
  const [signature, setSignature] = useState<string | Uint8Array>("");
  const analytics = useAnalytics();
  const { address, connector, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { openConnectModal } = useConnectModal();

  const connectToWallet = useCallback(async () => {
    const checkAddressExists = async (
      address: string,
      wallets: WalletEntry[],
      setError: Dispatch<SetStateAction<string | undefined>>
    ): Promise<boolean> => {
      // Check if Address belongs to this user
      const walletsArray = wallets.map((wallet) => wallet.address);
      const walletsArrayContainsAddress = walletsArray.includes(
        address.toLowerCase()
      );
      if (walletsArrayContainsAddress) {
        setError(
          `You're connected with ${address} but it's already associated with your account. Disconnect from any previously connected wallet address before trying again.`
        );
        return true;
      }
      // Check if address belongs to another user
      const exists = addressExists && (await addressExists(address));
      if (exists) {
        setError(
          `You're connected with ${address} but it's already associated with an AF account. Disconnect from any previously connected wallet address before trying again.`
        );
        setAddressAlreadyExists(true);
        return true;
      }
      return false;
    };

    if (connectingChain) {
      const { name } = connectingChain;
      if (name === "Ethereum") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (address && wallets) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const exists = await checkAddressExists(
            address.toLowerCase(),
            wallets,
            setError
          );
          // This is a new address
          if (!exists) {
            setNewConnectingAddress(address);
            setImplicitAddress(true);
            setAddressAlreadyExists(false);
            //increment step
            const nextStep = step + 1;
            setStep(nextStep);
            if (steps[0].status === "current") {
              steps[0].status = "complete";
              steps[1].status = "current";
            }
            return;
          } else {
            setAddressAlreadyExists(true);
          }
        }
        if (implicitAddress) setImplicitAddress(false);
        openConnectModal && openConnectModal();
      } else if (name === "Phantom") {
        connectToSolana &&
          (await connectToSolana()
            .then(async (account) => {
              if (account && wallets) {
                const exists = await checkAddressExists(
                  account.stringAddress.toLowerCase(),
                  wallets,
                  setError
                );
                if (exists) {
                  setAddressAlreadyExists(true);
                  return;
                } else {
                  setAddressAlreadyExists(false);
                  setNewConnectingAddress(account.stringAddress);
                  setSolanaAddressArray(account.arrayAddress);
                  //increment step
                  const nextStep = step + 1;
                  setStep(nextStep);
                  setError(undefined);
                  if (steps[0].status === "current") {
                    steps[0].status = "complete";
                    steps[1].status = "current";
                  }
                }
              }
            })
            .catch((e) => {
              console.error("ERROR", e);
            }));
      } else if (name === "Nifty Gateway") {
        connectToNifty && connectToNifty();
      }
    }
  }, [
    address,
    addressExists,
    connectToNifty,
    connectToSolana,
    connectingChain,
    implicitAddress,
    openConnectModal,
    step,
    wallets,
  ]);

  const getChallengeCallback = useCallback(async () => {
    setRequestingChallenge(true);
    setIsPending(true);
    const getChallengeAsync = async (
      address: string,
      apiID: number[],
      chainID: number[]
    ) => {
      let message: string | undefined;
      try {
        message =
          getChallengeLogin &&
          (await getChallengeLogin({
            address,
            apiId: apiID,
            chainId: chainID,
          }));
        message && setChallenge(message);
        setStep(step + 1);
        return message;
      } catch (e) {
        console.error("Challenge ERR", e);
      } finally {
        setIsPending(false);
        setRequestingChallenge(false);
      }
    };
    if (connectingChain !== undefined) {
      const { apiIDArray: apiID, chainIDArray: chainID } = connectingChain;
      await getChallengeAsync(connectingAddress, apiID, chainID).catch((e) =>
        console.error(e)
      );
    }
  }, [connectingAddress, connectingChain, getChallengeLogin, step]);

  const signChallengeCallback = useCallback(async () => {
    setIsPending(true);
    if (challenge !== "" && connectingChain !== undefined) {
      try {
        const { name } = connectingChain;
        if (name && name === "Ethereum") {
          await signMessageAsync({ message: challenge })
            .then((signature) => {
              if (signature) {
                setSignature(signature);
                setStep(step + 1);
                if (steps[1].status === "current") {
                  steps[1].status = "complete";
                  steps[2].status = "current";
                }
              }
            })
            .catch((e) => {
              console.error("ERROR", e);
            });
        } else if (name && name === "Phantom") {
          const encodedMessage = new TextEncoder().encode(challenge);
          signChallengeSolana &&
            (await signChallengeSolana({ message: encodedMessage })
              .then((signature) => {
                if (signature) {
                  setSignature(signature.signature);
                  setSolanaMessage(encodedMessage);
                  setStep(step + 1);
                  if (steps[1].status === "current") {
                    steps[1].status = "complete";
                    steps[2].status = "current";
                  }
                }
              })
              .catch((e) => {
                console.error("ERROR", e);
              }));
        }
      } catch (e) {
        console.error("Error", e);
      }
    }
    setIsPending(false);
  }, [challenge, connectingChain, signChallengeSolana, signMessageAsync, step]);

  const associateWalletCallback = useCallback(async () => {
    setIsPending(true);
    const associateWalletEthereum = async (
      address: string,
      signature: string,
      apiID: number[],
      chainID: number[]
    ) => {
      associateWallet &&
        (await associateWallet({
          address,
          signature,
          apiId: apiID,
          chainId: chainID,
        }));
      const nextStep = step + 1;
      setStep(nextStep);
    };

    const associateWalletSolana = async (
      address: Uint8Array,
      signature: Uint8Array,
      apiID: number[],
      chainID: number[],
      message: Uint8Array
    ) => {
      associateWallet &&
        (await associateWallet({
          address,
          signature,
          apiId: apiID,
          chainId: chainID,
          message,
        }));
      const nextStep = step + 1;
      setStep(nextStep);
    };

    if (connectingChain) {
      const {
        apiIDArray: apiID,
        chainIDArray: chainID,
        name,
      } = connectingChain;
      if (name === "Ethereum" && typeof signature === "string") {
        try {
          await associateWalletEthereum(
            connectingAddress,
            signature,
            apiID,
            chainID
          ).catch((e) => {
            console.error("ERROR", e);
          });
          setStep(step + 1);
          setTimeout(() => {
            setModalOpen && setModalOpen(false);
          }, 2000);
        } catch (e) {
          console.error("Error", e);
        }
      } else if (
        name === "Phantom" &&
        solanaAddressArray &&
        typeof signature !== "string" &&
        solanaMesssage
      ) {
        try {
          await associateWalletSolana(
            solanaAddressArray,
            signature,
            apiID,
            chainID,
            solanaMesssage
          ).catch((e) => console.error("ERROR", e));
        } catch (e) {
          console.error("EROR", e);
        }
      }
    }
    setIsPending(false);
  }, [
    connectingChain,
    associateWallet,
    step,
    signature,
    connectingAddress,
    setModalOpen,
    solanaAddressArray,
    solanaMesssage,
  ]);

  // Hooks for invoking the next step

  // Update state values on disconnect
  useEffect(() => {
    if (isDisconnected) {
      if (address === undefined && connectingAddress === "") {
        setAddressAlreadyExists(false);
        setError(undefined);
      }
    }
  }, [address, connectingAddress, connector, isDisconnected]);

  // Invoke Connect to wallet
  useEffect(() => {
    if (connectingChain && !connectingAddress) {
      connectToWallet().catch((e) => console.error("error", e));
    }
  }, [connectingChain, connectingAddress, connectToWallet]);

  // Invoke Get Challenge For Wallet
  useEffect(() => {
    if (!hasError && connectingAddress && !challenge && !implicitAddress) {
      getChallengeCallback().catch((e) =>
        console.error("CHALLENGE CALLBACK", e)
      );
    }
  }, [
    hasError,
    challenge,
    connectingAddress,
    getChallengeCallback,
    implicitAddress,
  ]);

  // Handle SignChallenge Callback && Associate Wallet Callback
  useEffect(() => {
    if (step === 2) {
      if (steps[0].status === "current") {
        steps[0].status = "complete";
        steps[1].status = "current";
      }
      signChallengeCallback().catch((e) => console.error("ERROR", e));
    } else if (step === 3) {
      associateWalletCallback()
        .then(() => {
          const chainName = connectingChain?.name || "";
          logEvent(analytics, "wallet_association", {
            chain: chainName,
            address: connectingAddress,
          });
        })
        .catch((e) => console.error("Error", e));
    }
  }, [
    step,
    signChallengeCallback,
    associateWalletCallback,
    connectingChain?.name,
    analytics,
    connectingAddress,
  ]);

  return (
    <div className="mt-4 sm:mt-4 flex flex-col">
      <h1 className="mb-4 flex-0">
        <span className="text-2xl">Adding New Wallet</span>
      </h1>
      <div className="my-4 flex flex-col h-80 flex-1">
        {!connectingChain && step === 0 && (
          <>
            <span>
              Associating Additional Wallets with your AF Account and will bring
              that wallet's NFTs into your AF Collection.
            </span>
            <ConnectWallet
              connectingChain={connectingChain}
              setConnectingChain={setConnectingChain}
            />
          </>
        )}
        {connectingChain && connectingChain.name !== "Nifty Gateway" && (
          <>
            <div className="w-full h-auto flex justify-center items-start flex-0">
              <img
                className="h-14 w-14"
                src={connectingChain.logo}
                alt={connectingChain.name}
              />
            </div>
            {connectingChain.name === "Phantom" ||
              (connectingChain.name === "Ethereum" &&
                connectingAddress !== undefined && (
                  <StepList steps={steps}>
                    <>
                      {step === 0 && (
                        <>
                          <span>
                            Open your {connectingChain.name} wallet and select
                            only the address you want to add.{" "}
                          </span>
                          {connectingChain.name === "Ethereum" && (
                            <span className="my-2">
                              If you are using an injected Wallet Connector,
                              i.e. a browser extension wallet like Metamask, it
                              is not currently possible to disconnect you
                              programmatically. Please open the browser
                              extension wallet and either disconnect all
                              currently connected wallets from the site or only
                              have the wallet connected you want to add before
                              continuing to Connect.
                            </span>
                          )}
                          {hasError && (
                            <span className="text-error">{hasError}</span>
                          )}
                        </>
                      )}
                      {step === 1 && (
                        <>
                          {requestingChallenge && (
                            <span>
                              Retrieving Challenge for {connectingChain.name}{" "}
                              Address: {connectingAddress}.
                            </span>
                          )}
                          {!requestingChallenge && hasError === undefined && (
                            <>
                              <span>
                                You're currently connected with{" "}
                                {connectingChain.name} Address:{" "}
                                {connectingAddress}.
                              </span>
                              <span className="mt-2">
                                Click the button below to request a message to
                                sign with this address.
                              </span>
                            </>
                          )}
                          {hasError && (
                            <span className="text-left text-sm text-error">
                              {hasError}
                            </span>
                          )}
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <span>
                            Sign the message in your Wallet to verify ownership
                            of {connectingAddress} and finish adding your
                            wallet.
                          </span>
                          {hasError && (
                            <span className="text-left text-sm text-error">
                              {hasError}
                            </span>
                          )}
                        </>
                      )}
                      {step === 3 && (
                        <>
                          <span className="text-left text-sm">
                            Verifying signature...
                          </span>
                          {hasError && (
                            <span className="text-left text-sm text-error">
                              {hasError}
                            </span>
                          )}
                        </>
                      )}
                      {step === 4 && (
                        <>
                          <span className="text-left text-sm">
                            Verified! You have successfully added this wallet.
                          </span>
                        </>
                      )}
                    </>
                  </StepList>
                ))}
          </>
        )}
      </div>
      <div className="h-[64px] flex-0">
        {connectingChain && (
          <form
            className="w-full flex mt-4 mb-2"
            onSubmit={(e) => {
              e.preventDefault();
              setError(undefined);
              if (step === 0) {
                if (connectingAddress !== "") {
                  getChallengeCallback().catch((error) => {
                    console.error("error", error);
                  });
                }
                if (addressAlreadyExists) {
                  disconnect();
                } else {
                  connectToWallet().catch((error) => {
                    console.error("error", error);
                  });
                }
              } else if (step === 1) {
                if (connectingAddress) {
                  getChallengeCallback().catch((error) => {
                    console.error("error", error);
                  });
                } else {
                  if (address && connectingChain.name === "Ethereum") {
                    disconnect();
                  } else {
                    connectToWallet().catch((error) => {
                      console.error("error", error);
                    });
                  }
                }
              } else if (step === 2) {
                signChallengeCallback().catch((error) => {
                  console.error("error", error);
                });
              } else if (step === 4) {
                setModalOpen && setModalOpen(false);
              }
            }}
            onReset={(e) => {
              e.preventDefault();
              setConnectingChain(undefined);
              setStep(0);
              setError(undefined);
              steps[0].status = "current";
              steps[1].status = "upcoming";
              steps[2].status = "upcoming";
            }}
          >
            <div className="mx-auto flex gap-2 flex-1 flex-row">
              <AFButton
                disabled={false}
                width={undefined}
                fullWidth={false}
                flexFill={true}
                variant={"outline"}
                type="reset"
              >
                <p>Restart</p>
              </AFButton>
              {step <= 4 && (
                <AFButton
                  disabled={false}
                  width={undefined}
                  fullWidth={undefined}
                  flexFill={true}
                  variant={step === 4 ? "accent" : "fill"}
                  type="submit"
                >
                  <>
                    {step === 0 && addressAlreadyExists && "Disconnect"}
                    {step === 0 &&
                      !addressAlreadyExists &&
                      "Re-request Connect"}
                    {step === 1 && hasError !== undefined && "Reconnect"}
                    {step === 1 && hasError === undefined && "Request Message"}
                    {step === 2 && "Sign Message"}
                    {step === 3 && "Retry Step"}
                    {step === 4 && "Close Window"}
                  </>
                </AFButton>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
