interface Props {
  passedClasses: string;
}

const IconSetupPower = ({ passedClasses }: Props) => (
  <svg
    width="253"
    height="149"
    viewBox="0 0 253 149"
    className={passedClasses}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M133.5 64.7402L132.5 66.4702C134.597 67.681 136.236 69.5498 137.163 71.787C138.089 74.0241 138.252 76.5046 137.625 78.8435C136.998 81.1825 135.617 83.2493 133.696 84.7234C131.775 86.1975 129.421 86.9965 127 86.9965C124.579 86.9965 122.225 86.1975 120.304 84.7234C118.383 83.2493 117.002 81.1825 116.375 78.8435C115.748 76.5046 115.911 74.0241 116.837 71.787C117.764 69.5498 119.403 67.681 121.5 66.4702L120.5 64.7402C118.022 66.1711 116.085 68.3798 114.99 71.0237C113.894 73.6676 113.702 76.599 114.443 79.3632C115.184 82.1274 116.816 84.57 119.086 86.3122C121.356 88.0543 124.138 88.9986 127 88.9986C129.862 88.9986 132.644 88.0543 134.914 86.3122C137.184 84.57 138.816 82.1274 139.557 79.3632C140.298 76.599 140.106 73.6676 139.01 71.0237C137.915 68.3798 135.978 66.1711 133.5 64.7402Z" />
    <path d="M126 61H128V75H126V61Z" />
    <rect
      x="247.5"
      y="5.5"
      width="138"
      height="242"
      transform="rotate(90 247.5 5.5)"
      stroke-width="11"
    />
  </svg>
);

export default IconSetupPower;
