/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Panel from "./Panel";
import { DisplayNFT } from "../lib/NFTContstants";
import ChainIcon from "../resources/reactIcons/ChainIcon";
import ModalPreview from "./ModalPreview";
import { DragEvent, useCallback, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NFTContext } from "../lib/NFTContext";
import LendingIcon from "../resources/reactIcons/LendingIcon";
import BorrowedIcon from "../resources/reactIcons/BorrowedIcon";
import { useAnalytics } from "reactfire";
import { logEvent } from "firebase/analytics";

export default function ArtCard({ nft }: { nft: DisplayNFT }) {
  const [showModal, toggleModal] = useState(false);
  const { disassociateNFT } = useContext(NFTContext);
  const location = useLocation();
  const pathStrings = location.pathname.split("/");
  const path = pathStrings[1];
  const deviceId = pathStrings[2];
  const analytics = useAnalytics();

  const isBorrowed = nft.borrowed;
  const isLended = nft.lended;
  const isLendedOrBorrowed = isBorrowed || isLended;
  let userId: string;

  const disassociationCallback = useCallback(() => {
    if (deviceId && nft.id && disassociateNFT) {
      disassociateNFT({ deviceId: deviceId, nftId: nft.id })
        .then(() => {
          logEvent(analytics, "disassociate_nft", {
            device_id: deviceId,
            nft_id: nft.id,
            user_id: nft.ownerID,
            user_wallet: nft.walletAddress,
          });
        })
        .catch((e) => {
          console.error("ERROR", e);
        });
    }
  }, [
    analytics,
    deviceId,
    disassociateNFT,
    nft.id,
    nft.walletAddress,
    nft.ownerID,
  ]);

  const dragStartHandler = (event: DragEvent) => {
    event.dataTransfer.setData("text/plain", nft.id);
  };

  return (
    <Panel injectedClassesOuter="min-w-60 relative mb-4 group">
      <article
        draggable={true}
        onDragStart={(e) => dragStartHandler(e)}
        className="relative mb-2 rounded min-w-card"
      >
        <div className="w-full z-10">
          <div className="w-full z-10">
            <div
              role="button"
              className="w-full z-10"
              onClick={() => {
                toggleModal(true);
                logEvent(analytics, "preview_nft", {
                  nft_id: nft.id,
                  user_id: nft.ownerID,
                  user_wallet: nft.walletAddress,
                });
              }}
            >
              <img
                src={nft.thumbnailURL}
                alt={nft.name}
                className="aspect-auto w-full transition group-hover:opacity-50"
              />
            </div>
          </div>
        </div>
        <footer>
          <span className="flex justify-between pl-2 pr-3 pt-2">
            <h3 className="font-semibold">{nft.name}</h3>
            <ChainIcon apiID={nft.apiID} chainID={nft.chainID} />
          </span>
        </footer>
        <div className="absolute top-1.5 left-1.5 flex gap-2 transition z-40">
          <div className="flex">
            {isLendedOrBorrowed && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div className="w-9 h-9 rounded flex flex-1 items-center justify-center bg-primary cursor-pointer">
                {isLended && LendingIcon("fill-darkPrimary")}
                {isBorrowed && BorrowedIcon("fill-darkPrimary")}
              </div>
            )}
            {path === "device" && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className="w-9 h-9 ml-2 rounded flex flex-1 items-center justify-center bg-primary cursor-pointer opacity-0 group-hover:opacity-100"
                title="Remove NFT from device"
                onClick={() => disassociationCallback()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="#FFF"
                >
                  <path d="M19 13H5v-2h14v2z" />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-1.5 right-1.5 flex gap-2 transition opacity-0 group-hover:opacity-100 z-40">
          <div className="w-9 h-9 rounded flex items-center justify-center bg-primary">
            <Link to={`/provenance/${nft.id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 32 32"
                fill="#FFF"
              >
                <path d="M30.94,15.66A16.69,16.69,0,0,0,16,5,16.69,16.69,0,0,0,1.06,15.66a1,1,0,0,0,0,.68A16.69,16.69,0,0,0,16,27,16.69,16.69,0,0,0,30.94,16.34,1,1,0,0,0,30.94,15.66ZM16,25c-5.3,0-10.9-3.93-12.93-9C5.1,10.93,10.7,7,16,7s10.9,3.93,12.93,9C26.9,21.07,21.3,25,16,25Z" />
                <path d="M16,10a6,6,0,1,0,6,6A6,6,0,0,0,16,10Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z" />
              </svg>
            </Link>
          </div>
        </div>
      </article>
      {showModal && (
        <ModalPreview
          nft={nft}
          toggleModal={toggleModal}
          showModal={showModal}
        />
      )}
    </Panel>
  );
}
