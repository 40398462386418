import { C as ChainMismatchError, g as getClient, a as ConnectorAlreadyConnectedError, b as ContractResultDecodeError, c as ConnectorNotFoundError, d as ContractMethodDoesNotExistError, e as getProvider, P as ProviderChainsNotFound, f as ChainDoesNotSupportMulticallError, h as ContractMethodRevertedError, i as ContractMethodNoResultError, U as UserRejectedRequestError, n as normalizeChainId, S as SwitchChainNotSupportedError } from './getProvider-a56d1b85.esm.js';
export { A as AddChainError, f as ChainDoesNotSupportMulticallError, C as ChainMismatchError, m as ChainNotConfiguredError, k as Client, l as Connector, a as ConnectorAlreadyConnectedError, c as ConnectorNotFoundError, d as ContractMethodDoesNotExistError, i as ContractMethodNoResultError, h as ContractMethodRevertedError, b as ContractResultDecodeError, I as InjectedConnector, P as ProviderChainsNotFound, o as ProviderRpcError, R as ResourceUnavailableError, p as RpcError, q as SwitchChainError, S as SwitchChainNotSupportedError, U as UserRejectedRequestError, j as createClient, r as createStorage, e as getProvider, s as noopStorage, n as normalizeChainId } from './getProvider-a56d1b85.esm.js';
import { Contract, logger } from 'ethers/lib/ethers';
import { FunctionFragment, isAddress, FormatTypes, parseBytes32String, formatUnits, getAddress, Logger } from 'ethers/lib/utils';
import { providers, BigNumber, Contract as Contract$1 } from 'ethers';
import { m as mainnet } from './chains-8fce19d8.esm.js';
export { a as allChains, c as chain, b as chainId, d as defaultChains, e as defaultL2Chains, f as etherscanBlockExplorers } from './chains-8fce19d8.esm.js';
import shallow from 'zustand/shallow';
import { d as debounce } from './debounce-0862bf88.esm.js';
export { a as alchemyRpcUrls, i as infuraRpcUrls, p as publicRpcUrls } from './rpcs-d533516e.esm.js';
import 'zustand/middleware';
import 'zustand/vanilla';
import 'eventemitter3';

function configureChains(defaultChains, providers) {
  let {
    minQuorum = 1,
    pollingInterval = 4_000,
    targetQuorum = 1,
    stallTimeout
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!defaultChains.length) throw new Error('must have at least one chain');
  if (targetQuorum < minQuorum) throw new Error('quorum cannot be lower than minQuorum');
  let chains = [];
  const providers_ = {};
  const webSocketProviders_ = {};

  for (const chain of defaultChains) {
    let configExists = false;

    for (const provider of providers) {
      const apiConfig = provider(chain); // If no API configuration was found (ie. no RPC URL) for
      // this provider, then we skip and check the next one.

      if (!apiConfig) continue;
      configExists = true;

      if (!chains.some(_ref => {
        let {
          id
        } = _ref;
        return id === chain.id;
      })) {
        chains = [...chains, apiConfig.chain];
      }

      providers_[chain.id] = [...(providers_[chain.id] || []), apiConfig.provider];

      if (apiConfig.webSocketProvider) {
        webSocketProviders_[chain.id] = [...(webSocketProviders_[chain.id] || []), apiConfig.webSocketProvider];
      }
    } // If no API configuration was found across the providers
    // then we throw an error to the consumer.


    if (!configExists) {
      throw new Error([`Could not find valid provider configuration for chain "${chain.name}".\n`, "You may need to add `jsonRpcProvider` to `configureChains` with the chain's RPC URLs.", 'Read more: https://wagmi.sh/docs/providers/jsonRpc'].join('\n'));
    }
  }

  return {
    chains,
    provider: _ref2 => {
      let {
        chainId
      } = _ref2;
      const activeChain = chains.find(x => x.id === chainId) ?? defaultChains[0];
      const chainProviders = providers_[activeChain.id];
      if (!chainProviders || !chainProviders[0]) throw new Error(`No providers configured for chain "${activeChain.id}"`);
      let provider;

      if (chainProviders.length === 1) {
        provider = chainProviders[0]();
      } else {
        provider = fallbackProvider(targetQuorum, minQuorum, chainProviders, {
          stallTimeout
        });
      } // Formatter workaround as Celo does not provide `gasLimit` or `difficulty` on eth_getBlockByNumber.


      if (activeChain.id === 42220) {
        provider.formatter.formats.block = { ...provider.formatter.formats.block,
          difficulty: () => 0,
          gasLimit: () => 0
        };
      }

      return Object.assign(provider, {
        chains,
        pollingInterval
      });
    },
    webSocketProvider: _ref3 => {
      var _chainWebSocketProvid;

      let {
        chainId
      } = _ref3;
      const activeChain = chains.find(x => x.id === chainId) ?? defaultChains[0];
      const chainWebSocketProviders = webSocketProviders_[activeChain.id];
      if (!chainWebSocketProviders) return undefined;
      const provider = (_chainWebSocketProvid = chainWebSocketProviders[0]) === null || _chainWebSocketProvid === void 0 ? void 0 : _chainWebSocketProvid.call(chainWebSocketProviders); // Formatter workaround as Celo does not provide `gasLimit` or `difficulty` on eth_getBlockByNumber.

      if (provider && activeChain.id === 42220) {
        provider.formatter.formats.block = { ...provider.formatter.formats.block,
          difficulty: () => 0,
          gasLimit: () => 0
        };
      } // WebSockets do not work with `fallbackProvider`
      // Default to first available


      return Object.assign(provider || {}, {
        chains
      });
    }
  };
}

function fallbackProvider(targetQuorum, minQuorum, providers_, _ref4) {
  let {
    stallTimeout
  } = _ref4;

  try {
    return new providers.FallbackProvider(providers_.map((chainProvider, index) => {
      const provider = chainProvider();
      return {
        provider,
        priority: provider.priority ?? index,
        stallTimeout: provider.stallTimeout ?? stallTimeout,
        weight: provider.weight
      };
    }), targetQuorum);
  } catch (error) {
    var _error$message;

    if (error !== null && error !== void 0 && (_error$message = error.message) !== null && _error$message !== void 0 && _error$message.includes('quorum will always fail; larger than total weight')) {
      if (targetQuorum === minQuorum) throw error;
      return fallbackProvider(targetQuorum - 1, minQuorum, providers_, {
        stallTimeout
      });
    }

    throw error;
  }
}

function assertActiveChain(_ref) {
  let {
    chainId
  } = _ref;
  const {
    chain: activeChain,
    chains
  } = getNetwork();
  const activeChainId = activeChain === null || activeChain === void 0 ? void 0 : activeChain.id;

  if (chainId !== activeChainId) {
    var _chains$find, _chains$find2;

    throw new ChainMismatchError({
      activeChain: ((_chains$find = chains.find(x => x.id === activeChainId)) === null || _chains$find === void 0 ? void 0 : _chains$find.name) ?? `Chain ${activeChainId}`,
      targetChain: ((_chains$find2 = chains.find(x => x.id === chainId)) === null || _chains$find2 === void 0 ? void 0 : _chains$find2.name) ?? `Chain ${chainId}`
    });
  }
}

/** Forked from https://github.com/epoberezkin/fast-deep-equal */
function deepEqual(a, b) {
  if (a === b) return true;

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false;
    let length;
    let i;

    if (Array.isArray(a) && Array.isArray(b)) {
      length = a.length;
      if (length != b.length) return false;

      for (i = length; i-- !== 0;) if (!deepEqual(a[i], b[i])) return false;

      return true;
    }

    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();
    const keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0;) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (i = length; i-- !== 0;) {
      const key = keys[i];
      if (key && !deepEqual(a[key], b[key])) return false;
    }

    return true;
  } // true if both NaN, false otherwise


  return a !== a && b !== b;
}

/**
 * Get normalized function name from contract
 *
 * @param contract — Contract
 * @param functionName — Function name
 * @param args — Function arguments (used to disambiguate overloaded functions)
 *
 * @returns Normalized function name
 */

function normalizeFunctionName(_ref) {
  let {
    contract,
    functionName,
    args = []
  } = _ref;
  // If `functionName` exists in contract, return it.
  if (functionName in contract.functions) return functionName; // Otherwise, check if `functionName` is an overloaded function based on `args` shape.

  const argsLength = (args === null || args === void 0 ? void 0 : args.length) ?? 0;
  const overloadFunctions = Object.keys(contract.functions).filter(x => x.startsWith(`${functionName}(`)).map(x => ({
    name: x,
    fragment: FunctionFragment.fromString(x)
  })).filter(x => argsLength === x.fragment.inputs.length);

  for (const overloadFunction of overloadFunctions) {
    const matched = args.every((arg, index) => {
      const abiParameter = overloadFunction.fragment.inputs[index];
      return isArgOfType(arg, abiParameter);
    });
    if (matched) return overloadFunction.name;
  } // Wasn't able to find overload, just return function name.


  return functionName;
}
function isArgOfType(arg, abiParameter) {
  const argType = typeof arg;
  const abiParameterType = abiParameter.type;

  switch (abiParameterType) {
    case 'address':
      return isAddress(arg);

    case 'bool':
      return argType === 'boolean';

    case 'function':
      return argType === 'string';

    case 'string':
      return argType === 'string';

    default:
      {
        if (abiParameterType === 'tuple' && 'components' in abiParameter) return Object.values(abiParameter.components).every((component, index) => {
          return isArgOfType(Object.values(arg)[index], component);
        }); // `(u)int<M>`: (un)signed integer type of `M` bits, `0 < M <= 256`, `M % 8 == 0`
        // https://regexr.com/6v8hp

        if (/^u?int(8|16|24|32|40|48|56|64|72|80|88|96|104|112|120|128|136|144|152|160|168|176|184|192|200|208|216|224|232|240|248|256)?$/.test(abiParameterType)) return argType === 'number' || argType === 'bigint' || BigNumber.isBigNumber(arg); // `bytes<M>`: binary type of `M` bytes, `0 < M <= 32`
        // https://regexr.com/6va55

        if (/^bytes([1-9]|1[0-9]|2[0-9]|3[0-2])?$/.test(abiParameterType)) return argType === 'string' || arg instanceof Uint8Array; // fixed-length (`<type>[M]`) and dynamic (`<type>[]`) arrays
        // https://regexr.com/6va6i

        if (/[a-z]+[1-9]{0,3}(\[[0-9]{0,}\])+$/.test(abiParameterType)) {
          return Array.isArray(arg) && arg.every(x => isArgOfType(x, { ...abiParameter,
            // Pop off `[]` or `[M]` from end of type
            type: abiParameterType.replace(/(\[[0-9]{0,}\])$/, '')
          }));
        }

        return false;
      }
  }
}

function logWarn(message) {
  var _getClient, _getClient$config$log, _getClient$config$log2;

  (_getClient = getClient()) === null || _getClient === void 0 ? void 0 : (_getClient$config$log = _getClient.config.logger) === null || _getClient$config$log === void 0 ? void 0 : (_getClient$config$log2 = _getClient$config$log.warn) === null || _getClient$config$log2 === void 0 ? void 0 : _getClient$config$log2.call(_getClient$config$log, message);
}

function minimizeContractInterface(config) {
  try {
    const minimizedAbi = config.abi.filter(x => x.type === 'function' && x.name === config.functionName);
    if (minimizedAbi.length === 0) throw new Error('Invalid ABI');
    return minimizedAbi;
  } catch (error) {
    const abi = Contract.getInterface(config.abi).format(FormatTypes.full);
    const minimizedInterface = Array.isArray(abi) ? abi : [abi];
    return minimizedInterface.filter(i => i.includes(config.functionName));
  }
}

function isPlainArray(value) {
  return Array.isArray(value) && Object.keys(value).length === value.length;
}

function parseContractResult(_ref) {
  let {
    abi,
    data,
    functionName
  } = _ref;

  if (data && isPlainArray(data)) {
    var _fragment$outputs;

    const iface = Contract.getInterface(abi);
    const fragment = iface.getFunction(functionName);
    const isTuple = (((_fragment$outputs = fragment.outputs) === null || _fragment$outputs === void 0 ? void 0 : _fragment$outputs.length) || 0) > 1;
    const data_ = isTuple ? data : [data];
    const encodedResult = iface.encodeFunctionResult(functionName, data_);
    const decodedResult = iface.decodeFunctionResult(functionName, encodedResult);
    return isTuple ? decodedResult : decodedResult[0];
  }

  return data;
}

/**
 * [ERC-20 Token Standard](https://ethereum.org/en/developers/docs/standards/tokens/erc-20)
 */
const erc20ABI = [{
  type: 'event',
  name: 'Approval',
  inputs: [{
    indexed: true,
    name: 'owner',
    type: 'address'
  }, {
    indexed: true,
    name: 'spender',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }]
}, {
  type: 'event',
  name: 'Transfer',
  inputs: [{
    indexed: true,
    name: 'from',
    type: 'address'
  }, {
    indexed: true,
    name: 'to',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'allowance',
  stateMutability: 'view',
  inputs: [{
    name: 'owner',
    type: 'address'
  }, {
    name: 'spender',
    type: 'address'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'approve',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'spender',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}, {
  type: 'function',
  name: 'balanceOf',
  stateMutability: 'view',
  inputs: [{
    name: 'account',
    type: 'address'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'decimals',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint8'
  }]
}, {
  type: 'function',
  name: 'name',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'string'
  }]
}, {
  type: 'function',
  name: 'symbol',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'string'
  }]
}, {
  type: 'function',
  name: 'totalSupply',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'transfer',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'recipient',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}, {
  type: 'function',
  name: 'transferFrom',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'sender',
    type: 'address'
  }, {
    name: 'recipient',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}];
/**
 * [bytes32-flavored ERC-20](https://docs.makerdao.com/smart-contract-modules/mkr-module#4.-gotchas-potential-source-of-user-error)
 * for tokens (ie. Maker) that use bytes32 instead of string.
 */

const erc20ABI_bytes32 = [{
  type: 'event',
  name: 'Approval',
  inputs: [{
    indexed: true,
    name: 'owner',
    type: 'address'
  }, {
    indexed: true,
    name: 'spender',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }]
}, {
  type: 'event',
  name: 'Transfer',
  inputs: [{
    indexed: true,
    name: 'from',
    type: 'address'
  }, {
    indexed: true,
    name: 'to',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'allowance',
  stateMutability: 'view',
  inputs: [{
    name: 'owner',
    type: 'address'
  }, {
    name: 'spender',
    type: 'address'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'approve',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'spender',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}, {
  type: 'function',
  name: 'balanceOf',
  stateMutability: 'view',
  inputs: [{
    name: 'account',
    type: 'address'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'decimals',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint8'
  }]
}, {
  type: 'function',
  name: 'name',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'bytes32'
  }]
}, {
  type: 'function',
  name: 'symbol',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'bytes32'
  }]
}, {
  type: 'function',
  name: 'totalSupply',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'transfer',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'recipient',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}, {
  type: 'function',
  name: 'transferFrom',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'sender',
    type: 'address'
  }, {
    name: 'recipient',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}];
/**
 * [ERC-721 Non-Fungible Token Standard](https://ethereum.org/en/developers/docs/standards/tokens/erc-721)
 */

const erc721ABI = [{
  type: 'event',
  name: 'Approval',
  inputs: [{
    indexed: true,
    name: 'owner',
    type: 'address'
  }, {
    indexed: true,
    name: 'spender',
    type: 'address'
  }, {
    indexed: true,
    name: 'tokenId',
    type: 'uint256'
  }]
}, {
  type: 'event',
  name: 'ApprovalForAll',
  inputs: [{
    indexed: true,
    name: 'owner',
    type: 'address'
  }, {
    indexed: true,
    name: 'operator',
    type: 'address'
  }, {
    indexed: false,
    name: 'approved',
    type: 'bool'
  }]
}, {
  type: 'event',
  name: 'Transfer',
  inputs: [{
    indexed: true,
    name: 'from',
    type: 'address'
  }, {
    indexed: true,
    name: 'to',
    type: 'address'
  }, {
    indexed: true,
    name: 'tokenId',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'approve',
  stateMutability: 'payable',
  inputs: [{
    name: 'spender',
    type: 'address'
  }, {
    name: 'tokenId',
    type: 'uint256'
  }],
  outputs: []
}, {
  type: 'function',
  name: 'balanceOf',
  stateMutability: 'view',
  inputs: [{
    name: 'account',
    type: 'address'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'getApproved',
  stateMutability: 'view',
  inputs: [{
    name: 'tokenId',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'address'
  }]
}, {
  type: 'function',
  name: 'isApprovedForAll',
  stateMutability: 'view',
  inputs: [{
    name: 'owner',
    type: 'address'
  }, {
    name: 'operator',
    type: 'address'
  }],
  outputs: [{
    name: '',
    type: 'bool'
  }]
}, {
  type: 'function',
  name: 'name',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'string'
  }]
}, {
  type: 'function',
  name: 'ownerOf',
  stateMutability: 'view',
  inputs: [{
    name: 'tokenId',
    type: 'uint256'
  }],
  outputs: [{
    name: 'owner',
    type: 'address'
  }]
}, {
  type: 'function',
  name: 'safeTransferFrom',
  stateMutability: 'payable',
  inputs: [{
    name: 'from',
    type: 'address'
  }, {
    name: 'to',
    type: 'address'
  }, {
    name: 'tokenId',
    type: 'uint256'
  }],
  outputs: []
}, {
  type: 'function',
  name: 'safeTransferFrom',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'from',
    type: 'address'
  }, {
    name: 'to',
    type: 'address'
  }, {
    name: 'id',
    type: 'uint256'
  }, {
    name: 'data',
    type: 'bytes'
  }],
  outputs: []
}, {
  type: 'function',
  name: 'setApprovalForAll',
  stateMutability: 'nonpayable',
  inputs: [{
    name: 'operator',
    type: 'address'
  }, {
    name: 'approved',
    type: 'bool'
  }],
  outputs: []
}, {
  type: 'function',
  name: 'symbol',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'string'
  }]
}, {
  type: 'function',
  name: 'tokenByIndex',
  stateMutability: 'view',
  inputs: [{
    name: 'index',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'tokenByIndex',
  stateMutability: 'view',
  inputs: [{
    name: 'owner',
    type: 'address'
  }, {
    name: 'index',
    type: 'uint256'
  }],
  outputs: [{
    name: 'tokenId',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'tokenURI',
  stateMutability: 'view',
  inputs: [{
    name: 'tokenId',
    type: 'uint256'
  }],
  outputs: [{
    name: '',
    type: 'string'
  }]
}, {
  type: 'function',
  name: 'totalSupply',
  stateMutability: 'view',
  inputs: [],
  outputs: [{
    name: '',
    type: 'uint256'
  }]
}, {
  type: 'function',
  name: 'transferFrom',
  stateMutability: 'payable',
  inputs: [{
    name: 'sender',
    type: 'address'
  }, {
    name: 'recipient',
    type: 'address'
  }, {
    name: 'tokeId',
    type: 'uint256'
  }],
  outputs: []
}];
/**
 * [Multicall3](https://github.com/mds1/multicall)
 */

const multicallABI = [{
  inputs: [{
    components: [{
      name: 'target',
      type: 'address'
    }, {
      name: 'allowFailure',
      type: 'bool'
    }, {
      name: 'callData',
      type: 'bytes'
    }],
    name: 'calls',
    type: 'tuple[]'
  }],
  name: 'aggregate3',
  outputs: [{
    components: [{
      name: 'success',
      type: 'bool'
    }, {
      name: 'returnData',
      type: 'bytes'
    }],
    name: 'returnData',
    type: 'tuple[]'
  }],
  stateMutability: 'view',
  type: 'function'
}];
/**
 * [ERC-4626 Tokenized Vaults Standard](https://ethereum.org/en/developers/docs/standards/tokens/erc-4626)
 */

const erc4626ABI = [{
  anonymous: false,
  inputs: [{
    indexed: true,
    name: 'owner',
    type: 'address'
  }, {
    indexed: true,
    name: 'spender',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }],
  name: 'Approval',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    name: 'sender',
    type: 'address'
  }, {
    indexed: true,
    name: 'receiver',
    type: 'address'
  }, {
    indexed: false,
    name: 'assets',
    type: 'uint256'
  }, {
    indexed: false,
    name: 'shares',
    type: 'uint256'
  }],
  name: 'Deposit',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    name: 'from',
    type: 'address'
  }, {
    indexed: true,
    name: 'to',
    type: 'address'
  }, {
    indexed: false,
    name: 'value',
    type: 'uint256'
  }],
  name: 'Transfer',
  type: 'event'
}, {
  anonymous: false,
  inputs: [{
    indexed: true,
    name: 'sender',
    type: 'address'
  }, {
    indexed: true,
    name: 'receiver',
    type: 'address'
  }, {
    indexed: true,
    name: 'owner',
    type: 'address'
  }, {
    indexed: false,
    name: 'assets',
    type: 'uint256'
  }, {
    indexed: false,
    name: 'shares',
    type: 'uint256'
  }],
  name: 'Withdraw',
  type: 'event'
}, {
  inputs: [{
    name: 'owner',
    type: 'address'
  }, {
    name: 'spender',
    type: 'address'
  }],
  name: 'allowance',
  outputs: [{
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'spender',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  name: 'approve',
  outputs: [{
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'asset',
  outputs: [{
    name: 'assetTokenAddress',
    type: 'address'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'account',
    type: 'address'
  }],
  name: 'balanceOf',
  outputs: [{
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  name: 'convertToAssets',
  outputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  name: 'convertToShares',
  outputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'assets',
    type: 'uint256'
  }, {
    name: 'receiver',
    type: 'address'
  }],
  name: 'deposit',
  outputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    name: 'caller',
    type: 'address'
  }],
  name: 'maxDeposit',
  outputs: [{
    name: 'maxAssets',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'caller',
    type: 'address'
  }],
  name: 'maxMint',
  outputs: [{
    name: 'maxShares',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'owner',
    type: 'address'
  }],
  name: 'maxRedeem',
  outputs: [{
    name: 'maxShares',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'owner',
    type: 'address'
  }],
  name: 'maxWithdraw',
  outputs: [{
    name: 'maxAssets',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'shares',
    type: 'uint256'
  }, {
    name: 'receiver',
    type: 'address'
  }],
  name: 'mint',
  outputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  name: 'previewDeposit',
  outputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  name: 'previewMint',
  outputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  name: 'previewRedeem',
  outputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  name: 'previewWithdraw',
  outputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'shares',
    type: 'uint256'
  }, {
    name: 'receiver',
    type: 'address'
  }, {
    name: 'owner',
    type: 'address'
  }],
  name: 'redeem',
  outputs: [{
    name: 'assets',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [],
  name: 'totalAssets',
  outputs: [{
    name: 'totalManagedAssets',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [],
  name: 'totalSupply',
  outputs: [{
    name: '',
    type: 'uint256'
  }],
  stateMutability: 'view',
  type: 'function'
}, {
  inputs: [{
    name: 'to',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  name: 'transfer',
  outputs: [{
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    name: 'from',
    type: 'address'
  }, {
    name: 'to',
    type: 'address'
  }, {
    name: 'amount',
    type: 'uint256'
  }],
  name: 'transferFrom',
  outputs: [{
    name: '',
    type: 'bool'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}, {
  inputs: [{
    name: 'assets',
    type: 'uint256'
  }, {
    name: 'receiver',
    type: 'address'
  }, {
    name: 'owner',
    type: 'address'
  }],
  name: 'withdraw',
  outputs: [{
    name: 'shares',
    type: 'uint256'
  }],
  stateMutability: 'nonpayable',
  type: 'function'
}];

// https://github.com/ethers-io/ethers.js/blob/master/packages/units/src.ts/index.ts#L10-L18
const units = ['wei', 'kwei', 'mwei', 'gwei', 'szabo', 'finney', 'ether'];

async function connect(_ref) {
  let {
    chainId,
    connector
  } = _ref;
  const client = getClient();
  const activeConnector = client.connector;
  if (connector.id === (activeConnector === null || activeConnector === void 0 ? void 0 : activeConnector.id)) throw new ConnectorAlreadyConnectedError();

  try {
    client.setState(x => ({ ...x,
      status: 'connecting'
    }));
    const data = await connector.connect({
      chainId
    });
    client.setLastUsedConnector(connector.id);
    client.setState(x => ({ ...x,
      connector,
      chains: connector === null || connector === void 0 ? void 0 : connector.chains,
      data,
      status: 'connected'
    }));
    client.storage.setItem('connected', true);
    return { ...data,
      connector
    };
  } catch (err) {
    client.setState(x => {
      return { ...x,
        // Keep existing connector connected in case of error
        status: x.connector ? 'connected' : 'disconnected'
      };
    });
    throw err;
  }
}

async function disconnect() {
  const client = getClient();
  if (client.connector) await client.connector.disconnect();
  client.clearState();
  client.storage.removeItem('connected');
}

async function fetchToken(_ref) {
  let {
    address,
    chainId,
    formatUnits: units = 'ether'
  } = _ref;

  async function fetchToken_(_ref2) {
    let {
      abi
    } = _ref2;
    const erc20Config = {
      address,
      abi,
      chainId
    };
    const [decimals, name, symbol, totalSupply] = await readContracts({
      allowFailure: false,
      contracts: [{ ...erc20Config,
        functionName: 'decimals'
      }, { ...erc20Config,
        functionName: 'name'
      }, { ...erc20Config,
        functionName: 'symbol'
      }, { ...erc20Config,
        functionName: 'totalSupply'
      }]
    });
    return {
      address,
      decimals,
      name: name,
      // protect against `ResolvedConfig['BytesType']`
      symbol: symbol,
      // protect against `ResolvedConfig['BytesType']`
      totalSupply: {
        formatted: formatUnits(totalSupply, units),
        value: totalSupply
      }
    };
  }

  try {
    return await fetchToken_({
      abi: erc20ABI
    });
  } catch (err) {
    // In the chance that there is an error upon decoding the contract result,
    // it could be likely that the contract data is represented as bytes32 instead
    // of a string.
    if (err instanceof ContractResultDecodeError) {
      const {
        name,
        symbol,
        ...rest
      } = await fetchToken_({
        abi: erc20ABI_bytes32
      });
      return {
        name: parseBytes32String(name),
        symbol: parseBytes32String(symbol),
        ...rest
      };
    }

    throw err;
  }
}

function getContract(_ref) {
  let {
    address,
    abi,
    signerOrProvider
  } = _ref;
  return new Contract$1(address, abi, signerOrProvider);
} ////////////////////////////////////////////////////////////////////////////////////////////////////
// Contract
// TODO: Add remaining properties

/**
 * @description Prepares the parameters required for a contract write transaction.
 *
 * Returns config to be passed through to `writeContract`.
 *
 * @example
 * import { prepareWriteContract, writeContract } from '@wagmi/core'
 *
 * const config = await prepareWriteContract({
 *  address: '0x...',
 *  abi: wagmiAbi,
 *  functionName: 'mint',
 * })
 * const result = await writeContract(config)
 */
async function prepareWriteContract(_ref) {
  let {
    abi,
    address,
    args,
    chainId,
    functionName,
    overrides,
    signer: signer_
  } = _ref;
  const signer = signer_ ?? (await fetchSigner({
    chainId
  }));
  if (!signer) throw new ConnectorNotFoundError();
  if (chainId) assertActiveChain({
    chainId
  });
  const contract = getContract({
    address,
    abi: abi,
    // TODO: Remove cast and still support `Narrow<TAbi>`
    signerOrProvider: signer
  });
  const normalizedFunctionName = normalizeFunctionName({
    contract,
    functionName,
    args
  });
  const populateTransactionFn = contract.populateTransaction[normalizedFunctionName];
  if (!populateTransactionFn) throw new ContractMethodDoesNotExistError({
    address,
    functionName: normalizedFunctionName
  });
  const params = [...(args ?? []), ...(overrides ? [overrides] : [])];
  const unsignedTransaction = await populateTransactionFn(...params);
  const gasLimit = unsignedTransaction.gasLimit || (await signer.estimateGas(unsignedTransaction));
  const minimizedAbi = minimizeContractInterface({
    abi: abi,
    // TODO: Remove cast and still support `Narrow<TAbi>`
    functionName
  });
  return {
    abi: minimizedAbi,
    address,
    chainId,
    // TODO: Remove cast
    functionName: functionName,
    mode: 'prepared',
    request: { ...unsignedTransaction,
      gasLimit
    }
  };
}

function getWebSocketProvider() {
  let {
    chainId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = getClient();
  if (chainId) return client.getWebSocketProvider({
    chainId
  }) || client.webSocketProvider;
  return client.webSocketProvider;
}

function watchProvider(args, callback) {
  const client = getClient();

  const handleChange = async () => callback(getProvider(args));

  const unsubscribe = client.subscribe(_ref => {
    let {
      provider
    } = _ref;
    return provider;
  }, handleChange);
  return unsubscribe;
}

function watchWebSocketProvider(args, callback) {
  const client = getClient();

  const handleChange = async () => callback(getWebSocketProvider(args));

  const unsubscribe = client.subscribe(_ref => {
    let {
      webSocketProvider
    } = _ref;
    return webSocketProvider;
  }, handleChange);
  return unsubscribe;
}

async function multicall(_ref) {
  let {
    allowFailure = true,
    chainId,
    contracts,
    overrides
  } = _ref;
  const provider = getProvider({
    chainId
  });
  if (!provider.chains) throw new ProviderChainsNotFound();
  const chain = provider.chains.find(chain => chain.id === chainId) || provider.chains[0];
  if (!chain) throw new ProviderChainsNotFound();
  if (!(chain !== null && chain !== void 0 && chain.multicall)) throw new ChainDoesNotSupportMulticallError({
    chain
  });
  if (typeof (overrides === null || overrides === void 0 ? void 0 : overrides.blockTag) === 'number' && (overrides === null || overrides === void 0 ? void 0 : overrides.blockTag) < chain.multicall.blockCreated) throw new ChainDoesNotSupportMulticallError({
    blockNumber: overrides === null || overrides === void 0 ? void 0 : overrides.blockTag,
    chain
  });
  const multicallContract = getContract({
    address: chain.multicall.address,
    abi: multicallABI,
    signerOrProvider: provider
  });
  const calls = contracts.map(_ref2 => {
    let {
      address,
      abi,
      functionName,
      ...config
    } = _ref2;
    const {
      args
    } = config || {};
    const contract = getContract({
      address,
      abi
    });
    const params = args ?? [];
    const normalizedFunctionName = normalizeFunctionName({
      contract,
      functionName,
      args
    });

    try {
      const contractFunction = contract[normalizedFunctionName];
      if (!contractFunction) logWarn(`"${normalizedFunctionName}" is not in the interface for contract "${address}"`);
      const callData = contract.interface.encodeFunctionData(normalizedFunctionName, params);
      return {
        target: address,
        allowFailure,
        callData
      };
    } catch (err) {
      if (!allowFailure) throw err;
      return {
        target: address,
        allowFailure,
        callData: '0x'
      };
    }
  });
  const params = [...[calls], ...(overrides ? [overrides] : [])];
  const results = await multicallContract.aggregate3( // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...params);
  return results.map((_ref3, i) => {
    let {
      returnData,
      success
    } = _ref3;
    const {
      address,
      abi,
      args,
      functionName
    } = contracts[i];
    const contract = getContract({
      address,
      abi: abi // TODO: Remove cast and still support `Narrow<TAbi>`

    });
    const normalizedFunctionName = normalizeFunctionName({
      contract,
      functionName,
      args
    });

    if (!success) {
      let error;

      try {
        contract.interface.decodeFunctionResult(normalizedFunctionName, returnData);
      } catch (err) {
        error = new ContractMethodRevertedError({
          address,
          args,
          chainId: chain.id,
          functionName: normalizedFunctionName,
          errorMessage: err.message
        });
        if (!allowFailure) throw error;
        logWarn(error.message);
      }

      return null;
    }

    if (returnData === '0x') {
      const error = new ContractMethodNoResultError({
        address,
        args,
        chainId: chain.id,
        functionName: normalizedFunctionName
      });
      if (!allowFailure) throw error;
      logWarn(error.message);
      return null;
    }

    try {
      const result = contract.interface.decodeFunctionResult(normalizedFunctionName, returnData);
      return Array.isArray(result) && result.length === 1 ? result[0] : result;
    } catch (err) {
      const error = new ContractResultDecodeError({
        address,
        args,
        chainId: chain.id,
        functionName: normalizedFunctionName,
        errorMessage: err.message
      });
      if (!allowFailure) throw error;
      logWarn(error.message);
      return null;
    }
  });
}

async function readContract(_ref) {
  let {
    address,
    args,
    chainId,
    abi,
    functionName,
    overrides
  } = _ref;
  const provider = getProvider({
    chainId
  });
  const contract = getContract({
    address,
    abi: abi,
    // TODO: Remove cast and still support `Narrow<TAbi>`
    signerOrProvider: provider
  });
  const normalizedFunctionName = normalizeFunctionName({
    contract,
    functionName,
    args
  });
  const contractFunction = contract[normalizedFunctionName];
  if (!contractFunction) throw new ContractMethodDoesNotExistError({
    address,
    functionName: normalizedFunctionName
  });
  const params = [...(args ?? []), ...(overrides ? [overrides] : [])];
  return contractFunction === null || contractFunction === void 0 ? void 0 : contractFunction(...params);
}

async function readContracts(_ref) {
  let {
    allowFailure = true,
    contracts,
    overrides
  } = _ref;

  try {
    const provider = getProvider();
    const contractsByChainId = contracts.reduce((contracts, contract, index) => {
      const chainId = contract.chainId ?? provider.network.chainId;
      return { ...contracts,
        [chainId]: [...(contracts[chainId] || []), {
          contract,
          index
        }]
      };
    }, {});

    const promises = () => Object.entries(contractsByChainId).map(_ref2 => {
      let [chainId, contracts] = _ref2;
      return multicall({
        allowFailure,
        chainId: parseInt(chainId),
        contracts: contracts.map(_ref3 => {
          let {
            contract
          } = _ref3;
          return contract;
        }),
        overrides
      });
    });

    let results;

    if (allowFailure) {
      results = (await Promise.allSettled(promises())).map(result => {
        if (result.status === 'fulfilled') return result.value;

        if (result.reason instanceof ChainDoesNotSupportMulticallError) {
          logWarn(result.reason.message);
          throw result.reason;
        }

        return null;
      }).flat();
    } else {
      results = (await Promise.all(promises())).flat();
    } // Reorder the contract results back to the order they were
    // provided in.


    const resultIndexes = Object.values(contractsByChainId).map(contracts => contracts.map(_ref4 => {
      let {
        index
      } = _ref4;
      return index;
    })).flat();
    return results.reduce((results, result, index) => {
      results[resultIndexes[index]] = result;
      return results;
    }, []);
  } catch (err) {
    if (err instanceof ContractResultDecodeError) throw err;
    if (err instanceof ContractMethodNoResultError) throw err;
    if (err instanceof ContractMethodRevertedError) throw err;

    const promises = () => contracts.map(contract => readContract({ ...contract,
      overrides
    }));

    if (allowFailure) return (await Promise.allSettled(promises())).map((result, i) => {
      if (result.status === 'fulfilled') return result.value;
      const {
        address,
        args,
        chainId,
        functionName
      } = contracts[i];
      const error = new ContractMethodRevertedError({
        address,
        functionName,
        chainId: chainId ?? mainnet.id,
        args,
        errorMessage: result.reason
      });
      logWarn(error.message);
      return null;
    });
    return await Promise.all(promises());
  }
}

function watchContractEvent(_ref, callback) {
  let {
    address,
    abi,
    chainId,
    eventName,
    once
  } = _ref;

  const handler = function () {
    return callback(...arguments);
  };

  let contract;

  const watchEvent = async () => {
    var _contract;

    if (contract) (_contract = contract) === null || _contract === void 0 ? void 0 : _contract.off(eventName, handler);
    const signerOrProvider = getWebSocketProvider({
      chainId
    }) || getProvider({
      chainId
    });
    contract = getContract({
      address,
      abi: abi,
      // TODO: Remove cast and still support `Narrow<TAbi>`
      signerOrProvider
    });
    if (once) contract.once(eventName, handler);else contract.on(eventName, handler);
  };

  watchEvent();
  const client = getClient();
  const unsubscribe = client.subscribe(_ref2 => {
    let {
      provider,
      webSocketProvider
    } = _ref2;
    return {
      provider,
      webSocketProvider
    };
  }, watchEvent, {
    equalityFn: shallow
  });
  return () => {
    var _contract2;

    (_contract2 = contract) === null || _contract2 === void 0 ? void 0 : _contract2.off(eventName, handler);
    unsubscribe();
  };
}

async function fetchBlockNumber() {
  let {
    chainId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const provider = getProvider({
    chainId
  });
  const blockNumber = await provider.getBlockNumber();
  return blockNumber;
}

function watchBlockNumber(args, callback) {
  // We need to debounce the listener as we want to opt-out
  // of the behavior where ethers emits a "block" event for
  // every block that was missed in between the `pollingInterval`.
  // We are setting a wait time of 1 as emitting an event in
  // ethers takes ~0.1ms.
  const debouncedCallback = debounce(callback, 1);
  let previousProvider;

  const createListener = provider => {
    if (previousProvider) {
      var _previousProvider;

      (_previousProvider = previousProvider) === null || _previousProvider === void 0 ? void 0 : _previousProvider.off('block', debouncedCallback);
    }

    provider.on('block', debouncedCallback);
    previousProvider = provider;
  };

  const provider_ = getWebSocketProvider({
    chainId: args.chainId
  }) ?? getProvider({
    chainId: args.chainId
  });
  if (args.listen) createListener(provider_);
  let active = true;
  const client = getClient();
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider,
      webSocketProvider
    } = _ref;
    return {
      provider,
      webSocketProvider
    };
  }, async _ref2 => {
    let {
      provider,
      webSocketProvider
    } = _ref2;
    const provider_ = webSocketProvider ?? provider;

    if (args.listen && !args.chainId && provider_) {
      createListener(provider_);
    }

    const blockNumber = await fetchBlockNumber({
      chainId: args.chainId
    });
    if (!active) return;
    callback(blockNumber);
  }, {
    equalityFn: shallow
  });
  return () => {
    var _previousProvider2;

    active = false;
    unsubscribe();
    provider_ === null || provider_ === void 0 ? void 0 : provider_.off('block', debouncedCallback);
    (_previousProvider2 = previousProvider) === null || _previousProvider2 === void 0 ? void 0 : _previousProvider2.off('block', debouncedCallback);
  };
}

function watchMulticall(config, callback) {
  const client = getClient();

  const handleChange = async () => callback(await multicall(config));

  const unwatch = config.listenToBlock ? watchBlockNumber({
    listen: true
  }, handleChange) : undefined;
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider
    } = _ref;
    return provider;
  }, handleChange);
  return () => {
    unsubscribe();
    unwatch === null || unwatch === void 0 ? void 0 : unwatch();
  };
}

function watchReadContract(config, callback) {
  const client = getClient();

  const handleChange = async () => callback(await readContract(config));

  const unwatch = config.listenToBlock ? watchBlockNumber({
    listen: true
  }, handleChange) : undefined;
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider
    } = _ref;
    return provider;
  }, handleChange);
  return () => {
    unsubscribe();
    unwatch === null || unwatch === void 0 ? void 0 : unwatch();
  };
}

function watchReadContracts(config, callback) {
  const client = getClient();

  const handleChange = async () => callback(await readContracts(config));

  const unwatch = config.listenToBlock ? watchBlockNumber({
    listen: true
  }, handleChange) : undefined;
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider
    } = _ref;
    return provider;
  }, handleChange);
  return () => {
    unsubscribe();
    unwatch === null || unwatch === void 0 ? void 0 : unwatch();
  };
}

/**
 * @description Fetches transaction for hash
 *
 * @example
 * import { fetchTransaction } from '@wagmi/core'
 *
 * const transaction = await fetchTransaction({
 *  chainId: 1,
 *  hash: '0x...',
 * })
 */
async function fetchTransaction(_ref) {
  let {
    chainId,
    hash
  } = _ref;
  const provider = getProvider({
    chainId
  });
  return provider.getTransaction(hash);
}

async function fetchEnsAddress(_ref) {
  let {
    chainId,
    name
  } = _ref;
  const provider = getProvider({
    chainId
  });
  const address = await provider.resolveName(name);

  try {
    return address ? getAddress(address) : null;
  } catch (_error) {
    return null;
  }
}

async function fetchEnsAvatar(_ref) {
  let {
    addressOrName,
    chainId
  } = _ref;
  const provider = getProvider({
    chainId
  }); // TODO: Update with more advanced logic
  // https://github.com/ensdomains/ens-avatar

  const avatar = await provider.getAvatar(addressOrName);
  return avatar;
}

async function fetchEnsName(_ref) {
  let {
    address,
    chainId
  } = _ref;
  const provider = getProvider({
    chainId
  });
  return provider.lookupAddress(getAddress(address));
}

async function fetchEnsResolver(_ref) {
  let {
    chainId,
    name
  } = _ref;
  const provider = getProvider({
    chainId
  });
  const resolver = await provider.getResolver(name);
  return resolver;
}

/**
 * @description Prepares the parameters required for sending a transaction.
 *
 * Returns config to be passed through to `sendTransaction`.
 *
 * @example
 * import { prepareSendTransaction, sendTransaction } from '@wagmi/core'
 *
 * const config = await prepareSendTransaction({
 *  request: {
 *    to: 'moxey.eth',
 *    value: parseEther('1'),
 *  }
 * })
 * const result = await sendTransaction(config)
 */
async function prepareSendTransaction(_ref) {
  let {
    chainId,
    request,
    signer: signer_
  } = _ref;
  const signer = signer_ ?? (await fetchSigner({
    chainId
  }));
  if (!signer) throw new ConnectorNotFoundError();
  if (chainId) assertActiveChain({
    chainId
  });
  const [to, gasLimit] = await Promise.all([isAddress(request.to) ? Promise.resolve(request.to) : fetchEnsAddress({
    name: request.to
  }), request.gasLimit ? Promise.resolve(request.gasLimit) : signer.estimateGas(request)]);
  if (!to) throw new Error('Could not resolve ENS name');
  return { ...(chainId ? {
      chainId
    } : {}),
    request: { ...request,
      gasLimit,
      to
    },
    mode: 'prepared'
  };
}

/**
 * @description Function to send a transaction.
 *
 * It is recommended to pair this with the `prepareSendTransaction` function to avoid
 * [UX pitfalls](https://wagmi.sh/docs/prepare-hooks/intro#ux-pitfalls-without-prepare-hooks).
 *
 * @example
 * import { prepareSendTransaction, sendTransaction } from '@wagmi/core'
 *
 * const config = await prepareSendTransaction({
 *  to: 'moxey.eth',
 *  value: parseEther('1'),
 * })
 * const result = await sendTransaction(config)
 */
async function sendTransaction(_ref) {
  let {
    chainId,
    mode,
    request
  } = _ref;

  /********************************************************************/

  /** START: iOS App Link cautious code.                              */

  /** Do not perform any async operations in this block.              */

  /** Ref: wagmi.sh/docs/prepare-hooks/intro#ios-app-link-constraints */

  /********************************************************************/
  // `fetchSigner` isn't really "asynchronous" as we have already
  // initialized the provider upon user connection, so it will return
  // immediately.
  const signer = await fetchSigner();
  if (!signer) throw new ConnectorNotFoundError();

  if (mode === 'prepared') {
    if (!request.gasLimit) throw new Error('`gasLimit` is required');
    if (!request.to) throw new Error('`to` is required');
  }

  if (chainId) assertActiveChain({
    chainId
  });

  try {
    var _connectUnchecked, _ref2;

    // Why don't we just use `signer.sendTransaction`?
    // The `signer.sendTransaction` method performs async
    // heavy operations (such as fetching block number)
    // which is not really needed for our case.
    // Having async heavy operations has side effects
    // when using it in a click handler (iOS deep linking issues,
    // delay to open wallet, etc).
    const uncheckedSigner = (_connectUnchecked = (_ref2 = signer).connectUnchecked) === null || _connectUnchecked === void 0 ? void 0 : _connectUnchecked.call(_ref2);
    const {
      hash,
      wait
    } = await (uncheckedSigner ?? signer).sendTransaction(request);
    /********************************************************************/

    /** END: iOS App Link cautious code.                                */

    /** Go nuts!                                                        */

    /********************************************************************/

    return {
      hash: hash,
      wait
    };
  } catch (error) {
    if (error.code === 4001) throw new UserRejectedRequestError(error);
    throw error;
  }
}

async function waitForTransaction(_ref) {
  let {
    chainId,
    confirmations,
    hash,
    timeout,
    wait: wait_
  } = _ref;
  let promise;

  if (hash) {
    const provider = getProvider({
      chainId
    });
    promise = provider.waitForTransaction(hash, confirmations, timeout);
  } else if (wait_) promise = wait_(confirmations);else throw new Error('hash or wait is required');

  return promise;
}

/**
 * @description Function to call a contract write method.
 *
 * It is recommended to pair this with the {@link prepareWriteContract} function
 * to avoid [UX pitfalls](https://wagmi.sh/docs/prepare-hooks/intro#ux-pitfalls-without-prepare-hooks).
 *
 * @example
 * import { prepareWriteContract, writeContract } from '@wagmi/core'
 *
 * const config = await prepareWriteContract({
 *   address: '0x...',
 *   abi: wagmiAbi,
 *   functionName: 'mint',
 * })
 * const result = await writeContract(config)
 */
async function writeContract(_ref) {
  let {
    address,
    args,
    chainId,
    abi,
    functionName,
    mode,
    overrides,
    request: request_
  } = _ref;

  /****************************************************************************/

  /** START: iOS App Link cautious code.                                      */

  /** Do not perform any async operations in this block.                      */

  /** Ref: https://wagmi.sh/docs/prepare-hooks/intro#ios-app-link-constraints */

  /****************************************************************************/
  const signer = await fetchSigner();
  if (!signer) throw new ConnectorNotFoundError();
  if (chainId) assertActiveChain({
    chainId
  });
  if (mode === 'prepared') if (!request_) throw new Error('`request` is required');
  const request = mode === 'recklesslyUnprepared' ? (await prepareWriteContract({
    address,
    args,
    chainId,
    abi: abi,
    // TODO: Remove cast and still support `Narrow<TAbi>`
    functionName,
    overrides
  })).request : request_;
  const transaction = await sendTransaction({
    request,
    mode: 'prepared'
  });
  /********************************************************************/

  /** END: iOS App Link cautious code.                                */

  /** Go nuts!                                                        */

  /********************************************************************/

  return transaction;
}

async function fetchBalance(_ref) {
  var _chain$nativeCurrency, _chain$nativeCurrency2;

  let {
    addressOrName,
    chainId,
    formatUnits: unit,
    token
  } = _ref;
  const client = getClient();
  const provider = getProvider({
    chainId
  });

  if (token) {
    // Convert ENS name to address if required
    let resolvedAddress;
    if (isAddress(addressOrName)) resolvedAddress = addressOrName;else {
      const address = await provider.resolveName(addressOrName); // Same error `provider.getBalance` throws for invalid ENS name

      if (!address) logger.throwError('ENS name not configured', Logger.errors.UNSUPPORTED_OPERATION, {
        operation: `resolveName(${JSON.stringify(addressOrName)})`
      });
      resolvedAddress = address;
    }

    const fetchContractBalance = async _ref2 => {
      let {
        abi
      } = _ref2;
      const erc20Config = {
        abi,
        address: token,
        chainId
      };
      const [value, decimals, symbol] = await readContracts({
        allowFailure: false,
        contracts: [{ ...erc20Config,
          functionName: 'balanceOf',
          args: [resolvedAddress]
        }, { ...erc20Config,
          functionName: 'decimals'
        }, { ...erc20Config,
          functionName: 'symbol'
        }]
      });
      return {
        decimals,
        formatted: formatUnits(value ?? '0', unit ?? decimals),
        symbol: symbol,
        // protect against `ResolvedConfig['BytesType']`
        value
      };
    };

    try {
      return await fetchContractBalance({
        abi: erc20ABI
      });
    } catch (err) {
      // In the chance that there is an error upon decoding the contract result,
      // it could be likely that the contract data is represented as bytes32 instead
      // of a string.
      if (err instanceof ContractResultDecodeError) {
        const {
          symbol,
          ...rest
        } = await fetchContractBalance({
          abi: erc20ABI_bytes32
        });
        return {
          symbol: parseBytes32String(symbol),
          ...rest
        };
      }

      throw err;
    }
  }

  const chains = [...(client.provider.chains || []), ...(client.chains ?? [])];
  const value = await provider.getBalance(addressOrName);
  const chain = chains.find(x => x.id === provider.network.chainId);
  return {
    decimals: (chain === null || chain === void 0 ? void 0 : (_chain$nativeCurrency = chain.nativeCurrency) === null || _chain$nativeCurrency === void 0 ? void 0 : _chain$nativeCurrency.decimals) ?? 18,
    formatted: formatUnits(value ?? '0', unit ?? 'ether'),
    symbol: (chain === null || chain === void 0 ? void 0 : (_chain$nativeCurrency2 = chain.nativeCurrency) === null || _chain$nativeCurrency2 === void 0 ? void 0 : _chain$nativeCurrency2.symbol) ?? 'ETH',
    value
  };
}

async function fetchSigner() {
  var _client$connector, _client$connector$get;

  let {
    chainId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = getClient();
  const signer = (await ((_client$connector = client.connector) === null || _client$connector === void 0 ? void 0 : (_client$connector$get = _client$connector.getSigner) === null || _client$connector$get === void 0 ? void 0 : _client$connector$get.call(_client$connector, {
    chainId
  }))) || null;
  return signer;
}

function getAccount() {
  const {
    data,
    connector,
    status
  } = getClient();

  switch (status) {
    case 'connected':
      return {
        address: data === null || data === void 0 ? void 0 : data.account,
        connector: connector,
        isConnected: true,
        isConnecting: false,
        isDisconnected: false,
        isReconnecting: false,
        status
      };

    case 'reconnecting':
      return {
        address: data === null || data === void 0 ? void 0 : data.account,
        connector,
        isConnected: !!(data !== null && data !== void 0 && data.account),
        isConnecting: false,
        isDisconnected: false,
        isReconnecting: true,
        status
      };

    case 'connecting':
      return {
        address: undefined,
        connector: undefined,
        isConnected: false,
        isConnecting: true,
        isDisconnected: false,
        isReconnecting: false,
        status
      };

    case 'disconnected':
      return {
        address: undefined,
        connector: undefined,
        isConnected: false,
        isConnecting: false,
        isDisconnected: true,
        isReconnecting: false,
        status
      };
  }
}

function getNetwork() {
  var _client$data, _client$data$chain, _client$data2;

  const client = getClient();
  const chainId = (_client$data = client.data) === null || _client$data === void 0 ? void 0 : (_client$data$chain = _client$data.chain) === null || _client$data$chain === void 0 ? void 0 : _client$data$chain.id;
  const activeChains = client.chains ?? [];
  const activeChain = [...(client.provider.chains || []), ...activeChains].find(x => x.id === chainId) ?? {
    id: chainId,
    name: `Chain ${chainId}`,
    network: `${chainId}`,
    rpcUrls: {
      default: ''
    }
  };
  return {
    chain: chainId ? { ...activeChain,
      ...((_client$data2 = client.data) === null || _client$data2 === void 0 ? void 0 : _client$data2.chain),
      id: chainId
    } : undefined,
    chains: activeChains
  };
}

async function signMessage(args) {
  try {
    const signer = await fetchSigner();
    if (!signer) throw new ConnectorNotFoundError();
    return await signer.signMessage(args.message);
  } catch (error) {
    if (error.code === 4001) throw new UserRejectedRequestError(error);
    throw error;
  }
}

async function signTypedData(_ref) {
  let {
    domain,
    types,
    value
  } = _ref;
  const signer = await fetchSigner();
  if (!signer) throw new ConnectorNotFoundError();
  const {
    chainId: chainId_
  } = domain;
  const chainId = chainId_ ? normalizeChainId(chainId_) : undefined;
  if (chainId) assertActiveChain({
    chainId
  }); // Method name may be changed in the future, see https://docs.ethers.io/v5/api/signer/#Signer-signTypedData

  return signer._signTypedData(domain, types, value);
}

async function switchNetwork(_ref) {
  let {
    chainId
  } = _ref;
  const {
    connector
  } = getClient();
  if (!connector) throw new ConnectorNotFoundError();
  if (!connector.switchChain) throw new SwitchChainNotSupportedError({
    connector
  });
  return connector.switchChain(chainId);
}

function watchAccount(callback) {
  let {
    selector = x => x
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const client = getClient();

  const handleChange = () => callback(getAccount());

  const unsubscribe = client.subscribe(_ref => {
    let {
      data,
      connector,
      status
    } = _ref;
    return selector({
      address: data === null || data === void 0 ? void 0 : data.account,
      connector,
      status
    });
  }, handleChange, {
    equalityFn: shallow
  });
  return unsubscribe;
}

function watchNetwork(callback) {
  let {
    selector = x => x
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const client = getClient();

  const handleChange = () => callback(getNetwork());

  const unsubscribe = client.subscribe(_ref => {
    var _data$chain;

    let {
      data,
      chains
    } = _ref;
    return selector({
      chainId: data === null || data === void 0 ? void 0 : (_data$chain = data.chain) === null || _data$chain === void 0 ? void 0 : _data$chain.id,
      chains
    });
  }, handleChange, {
    equalityFn: shallow
  });
  return unsubscribe;
}

function watchSigner(_ref, callback) {
  let {
    chainId
  } = _ref;
  const client = getClient();

  const handleChange = async () => callback(await fetchSigner({
    chainId
  }));

  const unsubscribe = client.subscribe(_ref2 => {
    var _data$chain;

    let {
      data,
      connector
    } = _ref2;
    return {
      account: data === null || data === void 0 ? void 0 : data.account,
      chainId: data === null || data === void 0 ? void 0 : (_data$chain = data.chain) === null || _data$chain === void 0 ? void 0 : _data$chain.id,
      connector
    };
  }, handleChange, {
    equalityFn: shallow
  });
  return unsubscribe;
}

async function fetchFeeData() {
  let {
    chainId,
    formatUnits: units = 'wei'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const provider = getProvider({
    chainId
  });
  const feeData = await provider.getFeeData();
  const formatted = {
    gasPrice: feeData.gasPrice ? formatUnits(feeData.gasPrice, units) : null,
    maxFeePerGas: feeData.maxFeePerGas ? formatUnits(feeData.maxFeePerGas, units) : null,
    maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ? formatUnits(feeData.maxPriorityFeePerGas, units) : null
  };
  return { ...feeData,
    formatted
  };
}

export { configureChains, connect, deepEqual, disconnect, erc20ABI, erc4626ABI, erc721ABI, fetchBalance, fetchBlockNumber, fetchEnsAddress, fetchEnsAvatar, fetchEnsName, fetchEnsResolver, fetchFeeData, fetchSigner, fetchToken, fetchTransaction, getAccount, getContract, getNetwork, getWebSocketProvider, minimizeContractInterface, multicall, parseContractResult, prepareSendTransaction, prepareWriteContract, readContract, readContracts, sendTransaction, signMessage, signTypedData, switchNetwork, units, waitForTransaction, watchAccount, watchBlockNumber, watchContractEvent, watchMulticall, watchNetwork, watchProvider, watchReadContract, watchReadContracts, watchSigner, watchWebSocketProvider, writeContract };
