import { LendingHistoryItem } from "../lib/NFTContext";
import ArtCardMini from "./ArtCardMini";

interface HistoryProps {
  nfts: LendingHistoryItem[];
}

const LendingHistory = ({ nfts }: HistoryProps) => {
  return (
    <div className="flex flex-col items-start text-left">
      {nfts &&
        nfts.map((nft: LendingHistoryItem) => {
          return (
            <div
              key={nft.lendId}
              className="flex w-full flex-col lg:flex-row h-auto justify-between my-8 items-start pb-4 border-b-2 last:border-b-0 border-scrollbar dark:border-darkScrollbar"
            >
              <div className="flex lg:flex-[0.4] flex-1 max-w-[100%] lg:max-w-[40%] lg:pt-7 mb-6 justify-center items-center">
                <div className="w-auto max-w-[100%] h-auto mb-4 lg:mb-0 mr-0 lg:mr-2">
                  <ArtCardMini nft={nft} />
                </div>
              </div>
              <div className="w-full flex-1 ml-2">
                <p className="text-lg">Details</p>
                <div className="rounded-lg p-3 bg-surface dark:bg-popupBackgroundSecondary">
                  <table className="min-w-full divide-y dark:divide-surface divide-popupBackground">
                    <tbody className="divide-y dark:divide-popupSecondary divide-symbolFaint bg-surface dark:bg-popupBackgroundSecondary rounded-full">
                      <tr>
                        <td>Lender</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.lender}
                        </td>
                      </tr>
                      <tr>
                        <td>Borrower</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.borrower}
                        </td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.startDate.toDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td>End Date</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.endDate.toDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.status}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LendingHistory;
