import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { FunctionComponent, Suspense, lazy, useEffect } from "react";
const Home = lazy(() => import("../pages/Home"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const Authentication = lazy(() => import("../pages/Authentication"));
const Collection = lazy(() => import("./Collection"));
const Provenance = lazy(() => import("../pages/Provenance"));
import Device from "../pages/Device";
import Lending from "../pages/Lending";
import Profile from "../pages/Profile";
import OpenRoute from "./OpenRoute";
import { AnalyticsProvider, useAnalytics, useFirebaseApp } from "reactfire";
// import Admin from "../pages/Admin";
import NotFound from "../pages/NotFound";
import { getAnalytics, logEvent } from "firebase/analytics";
import { generatePageLocationString } from "../lib/utils";

function PageViewLogger() {
  const location = useLocation();
  const analytics = useAnalytics();
  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `location` changes

  useEffect(() => {
    const { pathname } = location;
    const pageLocation = generatePageLocationString(pathname);
    logEvent(analytics, "page_view", {
      page_title: pageLocation,
      page_location: pathname,
    });
  }, [analytics, location]);

  return null;
}

const Router: FunctionComponent = () => {
  const app = useFirebaseApp();
  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <BrowserRouter>
        <Suspense>
          <Routes>
            {/* Wrap home element in Private Route */}
            <Route
              path=""
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            >
              <Route index element={<Collection />}></Route>
              {/* <Route path="/:username" element={<Profile />}></Route> */}
              <Route path="/device/:id" element={<Device />}></Route>
              <Route path="/lending" element={<Lending />}></Route>
            </Route>
            <Route
              path="/provenance/:id"
              element={
                <OpenRoute>
                  <Provenance />
                </OpenRoute>
              }
            ></Route>
            <Route
              path="/:username"
              element={
                <OpenRoute>
                  <Profile />
                </OpenRoute>
              }
            />
            <Route path="/login" element={<Authentication />} />
            <Route path="/nifty-callback" element={<Authentication />} />
            <Route
              path="*"
              element={
                <OpenRoute>
                  <NotFound />
                </OpenRoute>
              }
            />
          </Routes>
        </Suspense>
        <PageViewLogger location={location} />
      </BrowserRouter>
    </AnalyticsProvider>
  );
};

export default Router;
