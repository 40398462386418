export default function ActionIconBrightness() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      fill="current"
    >
      <path d="M16.5714 30.7143C16.5714 31.3429 17.0857 31.8571 17.7143 31.8571H22.2857C22.9143 31.8571 23.4286 31.3429 23.4286 30.7143V29.5714H16.5714V30.7143ZM20 9C15.5886 9 12 12.5886 12 17C12 19.72 13.36 22.1086 15.4286 23.56V26.1429C15.4286 26.7714 15.9429 27.2857 16.5714 27.2857H23.4286C24.0571 27.2857 24.5714 26.7714 24.5714 26.1429V23.56C26.64 22.1086 28 19.72 28 17C28 12.5886 24.4114 9 20 9ZM23.2571 21.6857L22.2857 22.3714V25H17.7143V22.3714L16.7429 21.6857C15.2 20.6114 14.2857 18.8629 14.2857 17C14.2857 13.8457 16.8457 11.2857 20 11.2857C23.1543 11.2857 25.7143 13.8457 25.7143 17C25.7143 18.8629 24.8 20.6114 23.2571 21.6857Z" />
    </svg>
  );
}
