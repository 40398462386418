import React, { useContext, useEffect, useState } from "react";
import LendingIcon from "../resources/reactIcons/LendingIcon";
import UserIcon from "../resources/reactIcons/UserIcon";
import CollectionIcon from "../resources/reactIcons/CollectionIcon";
import { UserContext } from "./UserContext";

interface NavigationItem {
  name: string;
  href: string;
  icon: (passedClasses: string) => JSX.Element;
  current: boolean;
}

export interface NavigationProps {
  navigation: NavigationItem[];
  setCurrentPath: ((name: string | null) => void) | null;
  currentlyActive: string;
  search?: string;
  setSearch?: (val: string) => void;
}

// https://v5.reactrouter.com/web/guides/primary-components

export const NavigationContext = React.createContext<NavigationProps>({
  navigation: [
    { name: "Collection", href: "", icon: CollectionIcon, current: true },
    {
      name: "Profile",
      href: `/:username`,
      icon: UserIcon,
      current: false,
    },
    { name: "Lending", href: "/lending", icon: LendingIcon, current: false },
  ],
  setCurrentPath: null,
  currentlyActive: "Collection",
});

interface Props {
  children: React.ReactNode;
}

export const NavigationProvider: React.FC<Props> = ({ children }) => {
  const { userSlug } = useContext(UserContext);
  const userPath = userSlug ? `/${userSlug || ""}` : "/";
  const [currentlyActive, setCurrentlyActive] = useState("Collection");
  const [navigation, setNavigation] = useState([
    { name: "Collection", href: "/", icon: CollectionIcon, current: true },
    {
      name: "Profile",
      href: userPath,
      icon: UserIcon,
      current: false,
    },
    { name: "Lending", href: "/lending", icon: LendingIcon, current: false },
  ]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (userSlug && navigation[1].href === "/") {
      const newNav = navigation;
      for (let i = 0; i < newNav.length; i++) {
        if (newNav[i].name === "Navigation") {
          newNav[i].href = `/${userSlug}`;
        }
      }
      setNavigation(newNav);
    }
  }, [navigation, userSlug]);

  const setCurrentPath = (name: string | null) => {
    const newNav = navigation;
    for (let i = 0; i < newNav.length; i++) {
      if (newNav[i].name === name) {
        newNav[i].current = true;
        setCurrentlyActive(name);
      } else {
        newNav[i].current = false;
      }
    }
    setNavigation(newNav);
  };

  return (
    <NavigationContext.Provider
      value={{ currentlyActive, navigation, setCurrentPath, search, setSearch }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
