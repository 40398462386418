import {
  collection,
  doc,
  query,
  where,
  updateDoc,
  deleteField,
  getDoc,
  // documentId,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
  useSigninCheck,
} from "reactfire";
import {
  DisplayNFT,
  displayNFTFirestoreDataConverter,
  LendNFTProps,
  // LentNFT,
  lentNFTFirestoreDataConverter,
} from "./NFTContstants";
import { UserContext } from "./UserContext";
import { trimOwnerId } from "./utils";

export interface AssociateNFTProps {
  nftId: string;
  deviceId: string;
}

interface NFTContextProps {
  data: DisplayNFT[] | null;
  borrowedData: DisplayNFT[] | null;
  combinedData: DisplayNFT[] | null;
  status: string;
  associateNFT?: ({ nftId, deviceId }: AssociateNFTProps) => Promise<void>;
  disassociateNFT?: ({ nftId, deviceId }: AssociateNFTProps) => Promise<void>;
  // unpairDevice?: ({ deviceId }: UnpairProps) => Promise<void>;
  lendNFT?: ({
    lender,
    borrower,
    startDate,
    endDate,
    nftId,
    signature,
  }: LendNFTProps) => Promise<void>;
  addOffChainMedia?: ({
    nftName,
    mediaURL,
    userId,
    uid,
  }: addOffchainMediaProps) => Promise<void>;
  countersignLendNFT?: ({
    lendId,
    signingAddress,
    signature,
    transactionHash,
  }: CountersignProps) => Promise<void>;
  lendingHistoryData?: LendNFTProps[];
  enrichedHistory?: LendingHistoryItem[];
}

export interface CountersignProps {
  lendId: string;
  signingAddress: string | Uint8Array;
  signature: string | Uint8Array;
  transactionHash?: string;
}

export interface LendingHistoryItem {
  lendId: string;
  lender: string | Uint8Array;
  borrower: string | Uint8Array;
  startDate: Date;
  endDate: Date;
  nftId: string;
  signature: string | Uint8Array;
  status: "Active" | "Future" | "Past";
  lendStatus: "pending" | "accepted" | "rejected";
  loanDescription?: string;
  fee?: {
    value: number;
    chainId: number;
    hash?: string;
  };
  type?: "Lend" | "Borrow";
  artworkURL?: string;
  thumbnailURL?: string;
  name?: string;
  apiID?: number;
  chainID?: number;
  contractAddress?: string;
  tokenID?: string;
}

export interface addOffchainMediaProps {
  nftName: string;
  mediaURL: string;
  thumbnailURL: string;
  userId: string;
  uid: string;
}

export const NFTContext = React.createContext<NFTContextProps>({
  data: null,
  combinedData: null,
  status: "",
  associateNFT: undefined,
  disassociateNFT: undefined,
  borrowedData: null,
});

interface Props {
  children: React.ReactNode;
}

export const NFTProvider: React.FC<Props> = ({ children }) => {
  const [isLendingEnriched, setIsLendingEnriched] = useState(false);
  const [enrichedHistory, setEnrichedHistory] = useState<LendingHistoryItem[]>(
    []
  );
  const firestore = useFirestore();
  const functions = useFunctions();
  const { data: signInCheckResult } = useSigninCheck();
  const { wallets } = useContext(UserContext);

  const uid =
    signInCheckResult && signInCheckResult.user && signInCheckResult.user.uid;
  const ownerId = trimOwnerId(uid);
  const nftCollection = collection(firestore, "nfts").withConverter(
    displayNFTFirestoreDataConverter
  );
  const lendingCollection = collection(firestore, "lending").withConverter(
    lentNFTFirestoreDataConverter
  );

  const nftQuery = query(nftCollection, where("ownerId", "==", ownerId));
  const { status, data } = useFirestoreCollectionData(nftQuery, {
    idField: "docId",
  });

  const walletArray = wallets?.map((wallet) => wallet.address);

  const lendingHistoryQuery = query(
    lendingCollection,
    where("lender", "in", walletArray)
  );
  const borrowingHistoryQuery = query(
    lendingCollection,
    where("borrower", "in", walletArray)
  );
  const { data: lentHistoryData } = useFirestoreCollectionData(
    lendingHistoryQuery,
    {
      idField: "docId",
    }
  );

  const { data: borrowingHistoryData } = useFirestoreCollectionData(
    borrowingHistoryQuery,
    {
      idField: "docId",
    }
  );

  // Retrieve lendingHistory (all borrows and lends for a user)
  const lendingHistoryData = lentHistoryData.concat(borrowingHistoryData);

  // const enrichedHistory: LendingHistoryItem[] = [];

  useEffect(() => {
    const generateEnrichedData = async () => {
      return await Promise.all(
        lendingHistoryData.map(async (lend) => {
          const nftRef = doc(firestore, "nfts", lend.nftId).withConverter(
            displayNFTFirestoreDataConverter
          );
          const docSnap = await getDoc(nftRef);

          let status: LendingHistoryItem["status"];
          const current = new Date();
          if (current < lend.startDate) {
            status = "Future";
          } else if (current > lend.endDate) {
            status = "Past";
          } else {
            status = "Active";
          }

          if (docSnap.exists()) {
            const nft = docSnap.data();

            let type: LendingHistoryItem["type"];
            if (walletArray?.includes(lend.lender)) {
              type = "Lend";
            } else {
              type = "Borrow";
            }
            return {
              lendId: lend.id,
              lender: lend.lender,
              borrower: lend.borrower,
              startDate: lend.startDate,
              endDate: lend.endDate,
              nftId: lend.nftId,
              lendStatus: lend.status,
              loanDescription: lend.loanDescription,
              fee: lend.fee,
              status,
              type,
              signature: lend.signature,
              artworkURL: nft.artworkURL,
              thumbnailURL: nft.thumbnailURL,
              name: nft.name,
              apiID: nft.apiID,
              chainID: nft.chainID,
              contractAddress: nft.contractAddress,
              tokenID: nft.tokenID,
            };
          } else {
            return {
              lendId: lend.id,
              lender: lend.lender,
              borrower: lend.borrower,
              startDate: lend.startDate,
              endDate: lend.endDate,
              nftId: lend.nftId,
              signature: lend.signature,
              status,
              lendStatus: lend.status,
              loanDescription: lend.loanDescription,
              fee: lend.fee,
            };
          }
        })
      );
    };

    if (!isLendingEnriched) {
      generateEnrichedData()
        .then((data) => {
          if (data !== undefined) {
            setEnrichedHistory(
              // @ts-ignore
              data
                .filter((d) => d.type !== undefined)
                .sort((a, b) =>
                  a.endDate.getTime() > b.endDate.getTime() ? -1 : 1
                )
            );
            setIsLendingEnriched(true);
          } else {
            setIsLendingEnriched(true);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [lendingHistoryData, isLendingEnriched, firestore, walletArray]);

  const borrowerQuery = query(
    nftCollection,
    where("lending.borrowerId", "==", ownerId)
  );
  const { data: borrowedData } = useFirestoreCollectionData(borrowerQuery, {
    idField: "docId",
  });

  const filteredBorrowedData = borrowedData
    ? borrowedData
        .filter((nft) => {
          const borrowItem = borrowingHistoryData.filter(
            (borrow) => borrow.nftId === nft.id
          );
          if (
            borrowItem &&
            borrowItem[0] &&
            borrowItem[0].status === "accepted"
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((nft) => {
          nft.borrowed = true;
          return nft;
        })
    : [];

  // TODO: generate array of nftIds that are actively on borrow
  // replace borrowedData query w/ array of nftId's actively being borrowed
  // Get active and confirmed borrowedLends for adding to nftData
  // const activeAcceptedBorrowIds = borrowingHistoryData.filter((borrow) => {
  //   if (borrow.status === "accepted" && current <= borrow.endDate && current >= borrow.startDate) {
  //     return borrow.nftId;
  //   }
  // })
  const combinedData =
    filteredBorrowedData.length > 0 ? data?.concat(filteredBorrowedData) : data;

  const associateNFT = async ({ deviceId, nftId }: AssociateNFTProps) => {
    await updateDoc(doc(firestore, "nfts", nftId), {
      [`devices.${deviceId}.order`]: Date.now(),
    });
  };

  const disassociateNFT = async ({ deviceId, nftId }: AssociateNFTProps) => {
    await updateDoc(doc(firestore, "nfts", nftId), {
      [`devices.${deviceId}`]: deleteField(),
    });
  };

  const lendNFTFunction = httpsCallable<LendNFTProps>(functions, "lendNFT");
  const lendNFT = async ({
    lender,
    borrower,
    startDate,
    endDate,
    nftId,
    signature,
  }: LendNFTProps): Promise<void> => {
    await lendNFTFunction({
      lender,
      borrower,
      startDate,
      endDate,
      nftId,
      signature,
    });
  };

  const addOffchainMediaFunction = httpsCallable<addOffchainMediaProps>(
    functions,
    "addOffchainMedia"
  );
  const addOffChainMedia = async ({
    nftName,
    mediaURL,
    thumbnailURL,
    userId,
    uid,
  }: addOffchainMediaProps): Promise<void> => {
    await addOffchainMediaFunction({
      nftName,
      mediaURL,
      thumbnailURL,
      userId,
      uid,
    });
  };

  const counterSignNFTFunction = httpsCallable<CountersignProps>(
    functions,
    "countersignLendNFT"
  );
  const countersignLendNFT = async ({
    lendId,
    signingAddress,
    signature,
    transactionHash,
  }: CountersignProps): Promise<void> => {
    await counterSignNFTFunction({
      lendId,
      signingAddress,
      signature,
      transactionHash,
    });
  };
  return (
    <NFTContext.Provider
      value={{
        data,
        borrowedData,
        combinedData,
        status,
        associateNFT,
        disassociateNFT,
        lendNFT,
        addOffChainMedia,
        countersignLendNFT,
        lendingHistoryData,
        enrichedHistory,
      }}
    >
      {children}
    </NFTContext.Provider>
  );
};
