import {
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { replaceStringAt } from "../lib/utils";
import { InputCodeError } from "./DeviceSetup";

const regexAllDigits = /^\d+$/;
export const blankChar = " ";
const originValue = "      ";

interface Props {
  disabled: boolean;
  setPairingCode: (arg0: string) => void;
  errorStatus: InputCodeError;
}

let value = "      ";

const NumericPINInput = ({
  disabled = false,
  setPairingCode,
  errorStatus = InputCodeError.OK,
}: Props) => {
  const [digits, setDigits] = useState<string[]>(["", "", "", "", "", ""]);
  const [inputs, setInputs] = useState<Element[]>();

  useEffect(() => {
    const ref: HTMLFormElement | null = document.getElementById(
      "pairing-form"
    ) as HTMLFormElement;

    value = originValue;

    if (ref !== null && ref.tagName === "FORM") {
      const formElements = ref.elements;
      const formElementsArray: Element[] = Object.values(formElements);
      setInputs(formElementsArray);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      formElements[0].focus();
    }
  }, []);

  function updateIndex(event: FormEvent, index: number) {
    //Get the current value (stripping out non-digits)
    let inputValue = (event.target as HTMLInputElement).value.replace(
      /\D/g,
      ""
    );

    //Ignore if there is no value
    if (inputValue.length === 0) {
      setDigits(value.split(""));
      return;
    }
    if (inputValue.length === 2) {
      inputValue = inputValue[1];
    }
    //Split the value across the following inputs
    value = replaceStringAt(value, index, inputValue);
    value = value.substring(0, value.length);
    setDigits(value.split(""));
    //Check if we're done
    if (index + 1 === value.length) {
      //Check if our value is entirely numeric
      if (regexAllDigits.test(value)) {
        setPairingCode(value);
      }
    } else if (inputs !== undefined) {
      //Select the next input
      const nextInput: Element | undefined = inputs[index + inputValue.length];
      if (nextInput !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        nextInput.focus();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        nextInput.select();
      }
    }
  }

  function handleKey(event: KeyboardEvent, index: number) {
    if (event.key === "Backspace") {
      event.preventDefault();
      if (value.charAt(index) === " ") {
        if (index - 1 >= 0) {
          //Removing the previous character
          value = replaceStringAt(value, index - 1, blankChar);
          setDigits(value.split(""));

          //Focusing the previous input
          const prevInput = inputs[index - 1];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          prevInput.focus();
        }
      } else {
        //Removing the current character
        value = replaceStringAt(value, index, blankChar);
        setDigits(value.split(""));
      }
    } else if (event.key === "ArrowLeft") {
      if (index - 1 >= 0) {
        event.preventDefault();

        //Focusing the previous input
        const prevInput = inputs[index - 1];
        prevInput.focus();
        prevInput.select();
      }
    } else if (event.key === "ArrowRight") {
      if (index + 1 < value.length) {
        event.preventDefault();

        //Focusing the next input
        const nextInput = inputs[index + 1];
        nextInput.focus();
        nextInput.select();
      }
    } else if (event.key === "Enter") {
      //Check if our value is entirely numeric
      if (regexAllDigits.test(value)) {
        setPairingCode(value);
      }
    }
  }

  function focusTarget(event: FocusEvent) {
    (event.target as HTMLInputElement).select();
  }

  return (
    <div
      className="w-full flex items-center justify-center flex-col"
      id="pairing-form-container"
    >
      <form id="pairing-form" className="flex justify-evenly w-full">
        {digits.map((digit, index) => {
          return (
            <input
              key={index}
              className="w-12 h-12 text-pin text-center font-semibold border-2 border-primary dark:text-primary dark:border-darkPrimary rounded relative ring-0 focus:outline-none focus:ring-purple pin-input"
              type="number"
              value={digit.length <= 1 ? digit : digit[digit.length - 1]}
              onInput={(event: FormEvent) => updateIndex(event, index)}
              onFocus={focusTarget}
              onKeyDown={(event: KeyboardEvent) => handleKey(event, index)}
              disabled={disabled}
            />
          );
        })}
      </form>
      {errorStatus !== InputCodeError.OK && (
        <p className="mt-4 text-error font-bold">
          {errorStatus === InputCodeError.NotFound && "Device Not Found"}
          {errorStatus === InputCodeError.ServerError &&
            "A server error occurred"}
        </p>
      )}
    </div>
  );
};

export default NumericPINInput;
