import { Header } from "../components/Header";
import { UserEntry } from "../lib/UserContext";
import NotFound from "../pages/NotFound";
import ProfileBody from "./ProfileBody";

const ProfileLoggedOut = ({
  profileUser,
}: {
  profileUser: UserEntry | undefined;
}) => {
  return (
    <div className="h-screen">
      <Header />
      {profileUser !== undefined && (
        <>
          {/* <Panel
            injectedClassesOuter="m-4"
            injectedClassesInner="overflow-y-scroll"
          > */}
          <main className="overflow-scroll max-w-4xl h-full min-h-content pr-0.5 scrollbar-custom mx-auto pb-20 z-[500]">
            <div className="relative mx-auto mr-3">
              <div className="pb-1">
                <div className="h-40 w-40 md:mt-2 mb-2 mx-2">
                  <div className="relative overflow-hidden rounded-full block bg-gradient-to-br from-purpleLighter to-darkPrimary">
                    {profileUser.photoURL ? (
                      <img
                        className="relative h-40 w-40 rounded-full"
                        src={profileUser.photoURL}
                        alt="User"
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-full w-full"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <ProfileBody user={profileUser} isLoggedInUser={false} />
          </main>
          {/* </Panel> */}
        </>
      )}
      {profileUser === undefined && <NotFound />}
    </div>
  );
};

export default ProfileLoggedOut;
