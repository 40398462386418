import AFButton from "./AFButton";
import { uuidv4 } from "@firebase/util";
import { UserEntry } from "../lib/UserContext";
import { FormEvent, useContext, useState } from "react";
import mime from "mime-types";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { NFTContext } from "../lib/NFTContext";
import Notification from "./Notification";

const MediaUpload = ({
  user,
  toggleModal,
}: {
  user: UserEntry;
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const storage = getStorage();
  const { addOffChainMedia } = useContext(NFTContext);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<
    undefined | [string, string?]
  >();
  const [storedDownloadURL, setStoredDownloadURL] = useState("");
  const [storedThumbnailDownloadURL, setStoredThumbnailDownloadURL] =
    useState("");
  const [storedUUID, setStoredUUID] = useState("");
  const [nftNameInput, setNftNameInput] = useState("");
  const [extensionType, setExtensionType] = useState<string | boolean>(false);

  const addOffchainMediaToStorage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.currentTarget.blur();
    if (
      !event.currentTarget ||
      !event.currentTarget.files ||
      !event.currentTarget.files[0]
    ) {
      alert("Please try re-uploading file");
      return;
    }
    setNotificationStatus("Pending");
    setShowNotification(true);

    const file = event.currentTarget.files[0];
    const extension = mime.extension(file.type);
    setExtensionType(extension);
    const uuid = uuidv4();
    if (user.id && extension) {
      const urlString = `offchainMedia/${user.id}/${uuid}.${extension}`;
      const storageRef = ref(storage, urlString);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setNotificationMessage(["Uploading NFT", `${progress}%`]);
        },
        (error) => {
          setNotificationStatus("Error");
          setNotificationMessage(["Error Uploading NFT", error.message]);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              // TODO: clean up previous downloadURL before updating in the save method
              setStoredDownloadURL(downloadURL);
              setStoredUUID(uuid);
              setNotificationStatus("Success");
              setNotificationMessage(["Success Uploading your NFT"]);
              setTimeout(() => {
                setShowNotification(false);
              }, 2000);
            })
            .catch((error) => {
              setNotificationStatus("Error");
              setNotificationMessage(["Error Uploading NFT", error]);
            });
        }
      );
    } else {
      alert("An Error Occurred, please try reloading the page.");
    }
  };

  const addOffchainThumbnailMediaToStorage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.currentTarget.blur();
    if (
      !event.currentTarget ||
      !event.currentTarget.files ||
      !event.currentTarget.files[0]
    ) {
      alert("Please try re-uploading file");
      return;
    }
    setNotificationStatus("Pending");
    setShowNotification(true);

    const file = event.currentTarget.files[0];
    const extension = mime.extension(file.type);
    if (user.id && extension) {
      const urlString = `offchainMedia/${user.id}/${storedUUID}-thumbnail.${extension}`;
      const storageRef = ref(storage, urlString);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setNotificationMessage(["Uploading NFT Thumbnail", `${progress}%`]);
        },
        (error) => {
          setNotificationStatus("Error");
          setNotificationMessage([
            "Error Uploading NFT Thumbnail",
            error.message,
          ]);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              // TODO: clean up previous downloadURL before updating in the save method
              setStoredThumbnailDownloadURL(downloadURL);
              setNotificationStatus("Success");
              setNotificationMessage(["Success Uploading your Thumbnail"]);
              setTimeout(() => {
                setShowNotification(false);
              }, 2000);
            })
            .catch((error) => {
              setNotificationStatus("Error");
              setNotificationMessage(["Error Uploading NFT", error]);
            });
        }
      );
    } else {
      alert("An Error Occurred, please try reloading the page.");
    }
  };

  const addOffChainNFT = async (event: FormEvent) => {
    event.preventDefault();
    setNotificationStatus("Saving");
    setNotificationMessage(["Saving your OffChain Media", ""]);
    user.id &&
      storedDownloadURL &&
      storedUUID &&
      addOffChainMedia &&
      (await addOffChainMedia({
        nftName: nftNameInput,
        mediaURL: storedDownloadURL,
        thumbnailURL: storedThumbnailDownloadURL || storedDownloadURL,
        userId: user.id,
        uid: storedUUID,
      }));
    setNotificationStatus("Success");
    setNotificationMessage([
      "Success Uploading your NFT",
      "It will now appear in your NFTs",
    ]);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000);
    setStoredDownloadURL("");
    setStoredUUID("");
  };

  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setNftNameInput(newVal);
  };

  return (
    <>
      <Notification
        passedShow={showNotification}
        passedSetShow={setShowNotification}
        status={notificationStatus}
        message={notificationMessage}
      />
      <div className="flex flex-col">
        <form
          className="flex flex-col items-center mt-2"
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (
              storedDownloadURL === "" ||
              ((extensionType === "mp4" ||
                extensionType === "mov" ||
                extensionType === "avi") &&
                storedThumbnailDownloadURL === "")
            ) {
              alert("Please ensure all fields are filled out");
              return;
            }
            addOffChainNFT(event)
              .then(() => {
                toggleModal(false);
              })
              .catch((error) => {
                console.error(error);
              });
          }}
        >
          {/* Name selection */}
          <label htmlFor="nftName" className="my-2 w-[320px] text-left">
            Name
            <input
              className="h-full w-full rounded-xl border-2 border-primary dark:border-darkPrimary dark:hover:border-purpleLighter  py-2 pl-8 pr-3 bg-transparent text-sm dark:text-darkPrimary text-primary placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple focus:border-transparent focus:placeholder-gray-400 sm:block z-40"
              type="text"
              onChange={handleTextInput}
              id="offchain-name"
            />
          </label>
          {/* Media Preview */}
          {storedDownloadURL && (
            <div className="h-40 w-full flex justify-center items-center">
              {storedDownloadURL &&
                (extensionType === "mp4" ||
                  extensionType === "mov" ||
                  extensionType === "avi") && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    src={storedDownloadURL}
                    className="h-40 w-auto"
                    controls={true}
                  />
                )}
              {storedDownloadURL &&
                (extensionType === "png" ||
                  extensionType === "jpg" ||
                  extensionType === "jpeg" ||
                  extensionType === "svg" ||
                  extensionType === "gif") && (
                  <img
                    src={storedDownloadURL}
                    alt="upload preview"
                    className="h-40 w-auto"
                  />
                )}
            </div>
          )}
          {/* Media selection */}
          {/* <div className="flex flex-col my-2 mx-2 border 1px solid rounded"> */}
          {storedDownloadURL === "" && (
            <label
              htmlFor="offchain-media"
              className="my-2 hover:text-purpleLighter dark:hover:text-purpleLighter dark:hover:border-purpleLighter text-left w-[320px] h-full rounded-xl border-2 border-primary dark:border-darkPrimary hover:border-purpleLighter hover:ring-purpleLighter py-2 pl-8 pr-3 bg-transparent text-sm dark:text-darkPrimary text-primary placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple focus:border-transparent focus:placeholder-gray-400 sm:block z-40 cursor-pointer"
            >
              Click Here To Upload Media
              <input
                type="file"
                id="offchain-media"
                name="offchain-media"
                className="hidden"
                onChange={addOffchainMediaToStorage}
                accept="image/png, image/jpeg, image/gif, video/mp4, video/avi, video/mov"
              />
            </label>
          )}
          {/* Thumbnail selection */}
          {storedDownloadURL &&
            (extensionType === "mp4" ||
              extensionType === "mov" ||
              extensionType === "avi") && (
              <>
                {storedThumbnailDownloadURL && (
                  <div className="h-40 w-full flex justify-center items-center">
                    <img
                      src={storedThumbnailDownloadURL}
                      alt="upload preview"
                      className="h-40 w-auto"
                    />
                  </div>
                )}
                <p className="text-error">
                  {storedThumbnailDownloadURL === "" &&
                    "Upload a thumbnail to be associated with this video file"}
                </p>
                {storedThumbnailDownloadURL === "" && (
                  <label
                    htmlFor="offchain-media-thumbnail"
                    className="my-2 hover:text-purpleLighter dark:hover:text-purpleLighter dark:hover:border-purpleLighter text-left w-[320px] h-full rounded-xl border-2 border-primary dark:border-darkPrimary hover:border-purpleLighter hover:ring-purpleLighter py-2 pl-8 pr-3 bg-transparent text-sm dark:text-darkPrimary text-primary placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple focus:border-transparent focus:placeholder-gray-400 sm:block z-40 cursor-pointer"
                  >
                    Click Here To Upload Thumbnail for Video
                    <input
                      type="file"
                      id="offchain-media-thumbnail"
                      name="offchain-media-thumbnail"
                      className="hidden"
                      onChange={addOffchainThumbnailMediaToStorage}
                      accept="image/png, image/jpeg, image/gif"
                    />
                  </label>
                )}
              </>
            )}
          {/* </div> */}
          <div className="flex mt-2 h-full w-[320px]">
            <AFButton
              disabled={false}
              width={320}
              fullWidth={undefined}
              flexFill={true}
              variant={"fill"}
              type={"submit"}
            >
              <p>Add OffChain Media</p>
            </AFButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default MediaUpload;
