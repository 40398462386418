import {
  collection,
  doc,
  query,
  where,
  updateDoc,
  deleteDoc,
  FieldValue,
} from "firebase/firestore";
import React from "react";
import {
  useAnalytics,
  useFirestore,
  useFirestoreCollectionData,
  useSigninCheck,
} from "reactfire";
import { logEvent } from "firebase/analytics";
import {
  DeviceDataBluetooth,
  DeviceDataSleepSchedule,
  DeviceEntry,
  deviceEntryFirestoreDataConverter,
  DeviceState,
} from "./DeviceConstants";
import { trimOwnerId } from "./utils";

interface DeviceContextProps {
  data: DeviceEntry[];
  status: string;
  renameDevice?: ({ deviceId, newLabel }: RenameProps) => Promise<void>;
  unpairDevice?: ({ deviceId }: UnpairProps) => Promise<void>;
  toggleEnabled?: ({ deviceId, value }: ToggleEnabledProps) => Promise<void>;
  updateDeviceSleepSchedule?: ({
    deviceID,
    options,
  }: UpdateDeviceSleepScheduleProps) => Promise<void>;
  updateDeviceSettings?: ({
    deviceID,
    options,
  }: UpdateDeviceSettingsProps) => Promise<void>;
  updateDeviceBrightness?: ({
    deviceID,
    newBrightness,
  }: UpdateDeviceBrightnessProps) => Promise<void>;
  updateDeviceBluetooth?: ({
    deviceID,
    options,
  }: UpdateDeviceBluetoothProps) => Promise<void>;
  resetTargetDeviceBluetooth?: ({
    deviceID,
  }: {
    deviceID: string;
  }) => Promise<void>;
}

export const DeviceContext = React.createContext<DeviceContextProps>({
  data: [{}],
  status: "",
  renameDevice: undefined,
  unpairDevice: undefined,
});

interface Props {
  children: React.ReactNode;
}

interface RenameProps {
  deviceId: string;
  newLabel: string;
}

interface UnpairProps {
  deviceId: string;
}

interface ToggleEnabledProps {
  deviceId: string;
  value: boolean;
}

export interface UpdateDeviceSleepScheduleProps {
  deviceID: string;
  options: Partial<DeviceDataSleepSchedule>;
}

export interface UpdateDeviceSettingsProps {
  deviceID: string;
  options: Partial<DeviceState>;
}

export interface UpdateDeviceBrightnessProps {
  deviceID: string;
  newBrightness: number;
}

export interface UpdateDeviceBluetoothProps {
  deviceID: string;
  options: Partial<DeviceDataBluetooth>;
}

export const DeviceProvider: React.FC<Props> = ({ children }) => {
  const firestore = useFirestore();
  const analytics = useAnalytics();
  const { data: signInCheckResult } = useSigninCheck();
  const uid =
    signInCheckResult && signInCheckResult.user && signInCheckResult.user.uid;
  const ownerId = trimOwnerId(uid);
  const deviceCollection = collection(firestore, "devices").withConverter(
    deviceEntryFirestoreDataConverter
  );
  const deviceQuery = query(deviceCollection, where("ownerId", "==", ownerId));
  const { status, data } = useFirestoreCollectionData(deviceQuery, {
    idField: "docId",
  });

  // Device Entry Functions
  const renameDevice = async ({ deviceId, newLabel }: RenameProps) => {
    await updateDoc(doc(firestore, "devices", deviceId), { label: newLabel });
  };

  const unpairDevice = async ({ deviceId }: UnpairProps) => {
    logEvent(analytics, "unpair_device", {
      device_id: deviceId,
      user_id: ownerId,
    });
    await deleteDoc(doc(firestore, "devices", deviceId));
  };

  // Settings Functions
  const toggleEnabled = async ({ deviceId, value }: ToggleEnabledProps) => {
    await updateDeviceSleepSchedule({
      deviceID: deviceId,
      options: { enabled: value },
    });
  };

  // Device Settings Functions
  const updateDeviceSleepSchedule = async ({
    deviceID,
    options,
  }: UpdateDeviceSleepScheduleProps) => {
    const data: { [x: string]: FieldValue | Partial<unknown> | undefined } = {};
    if ("enabled" in options) data["sleepSchedule.enabled"] = options.enabled;
    if ("timeZone" in options)
      data["sleepSchedule.timeZone"] = options.timeZone;
    if ("hourEnter" in options)
      data["sleepSchedule.hourEnter"] = options.hourEnter;
    if ("minuteEnter" in options)
      data["sleepSchedule.minuteEnter"] = options.minuteEnter;
    if ("hourExit" in options)
      data["sleepSchedule.hourExit"] = options.hourExit;
    if ("minuteExit" in options)
      data["sleepSchedule.minuteExit"] = options.minuteExit;
    logEvent(analytics, "update_setting", {
      device_id: deviceID,
      user_id: ownerId,
      setting: "sleep schedule",
    });
    return updateDoc(doc(firestore, "devices", deviceID), data);
  };

  const updateDeviceSettings = async ({
    deviceID,
    options,
  }: UpdateDeviceSettingsProps) => {
    const data: { [x: string]: FieldValue | Partial<unknown> | undefined } = {};
    // Slideshow
    if ("slideshow" in options) data["slideshow"] = options.slideshow;
    if ("slideshowDuration" in options)
      data["duration"] = options.slideshowDuration;
    if ("slideshow" in options) {
      logEvent(analytics, "update_setting", {
        device_id: deviceID,
        user_id: ownerId,
        setting: "slideshow",
      });
    }
    if ("slideshowDuration" in options) {
      logEvent(analytics, "update_setting", {
        device_id: deviceID,
        user_id: ownerId,
        setting: "slideshow duration",
      });
    }
    // Display Mode
    if ("frame" in options) data["frame"] = options.frame;
    if ("frame" in options) {
      logEvent(analytics, "update_setting", {
        device_id: deviceID,
        user_id: ownerId,
        setting: "frame",
      });
    }
    // Theme
    if ("theme" in options) data["theme"] = options.theme;
    if ("theme" in options) {
      if ("theme" in options) {
        logEvent(analytics, "update_setting", {
          device_id: deviceID,
          user_id: ownerId,
          setting: "theme",
        });
      }
    }
    if ("themeCustomColor" in options)
      data["customTheme"] = options.themeCustomColor;
    if ("themeCustomColor" in options) {
      logEvent(analytics, "update_setting", {
        device_id: deviceID,
        user_id: ownerId,
        setting: "theme custom color",
      });
    }

    if ("fallbackOrientation" in options)
      data["fallbackOrientation"] = options.fallbackOrientation;

    return updateDoc(doc(firestore, "devices", deviceID), data);
  };

  const updateDeviceBrightness = async ({
    deviceID,
    newBrightness,
  }: UpdateDeviceBrightnessProps) => {
    logEvent(analytics, "update_setting", {
      device_id: deviceID,
      user_id: ownerId,
      setting: "brightness",
    });
    return updateDoc(doc(firestore, "devices", deviceID), {
      brightness: newBrightness,
    });
  };

  const updateDeviceBluetooth = async ({
    deviceID,
    options,
  }: UpdateDeviceBluetoothProps) => {
    const data: { [x: string]: FieldValue | Partial<unknown> | undefined } = {};

    if ("availableDevices" in options)
      data["bluetooth.availableDevices"] = options.availableDevices;
    if ("isScanning" in options)
      data["bluetooth.isScanning"] = options.isScanning;
    if ("targetDevice" in options && options.targetDevice)
      data["bluetooth.targetDevice"] = options.targetDevice;
    if ("connectionState" in options)
      data["bluetooth.connectionState"] = options.connectionState;
    if ("isScanning" in options) {
      logEvent(analytics, "update_setting", {
        device_id: deviceID,
        user_id: ownerId,
        setting: "bluetooth scanning",
      });
    }
    if ("targetDevice" in options) {
      logEvent(analytics, "update_setting", {
        device_id: deviceID,
        user_id: ownerId,
        setting: "bluetooth target device",
      });
    }
    return updateDoc(doc(firestore, "devices", deviceID), data);
  };

  const resetTargetDeviceBluetooth = async ({
    deviceID,
  }: {
    deviceID: string;
  }) => {
    return updateDoc(doc(firestore, "devices", deviceID), {
      ["bluetooth.connectionState"]: "disconnected",
      ["bluetooth.targetDevice"]: null,
    });
  };

  return (
    <DeviceContext.Provider
      value={{
        data,
        status,
        renameDevice,
        resetTargetDeviceBluetooth,
        unpairDevice,
        updateDeviceSleepSchedule,
        updateDeviceBrightness,
        updateDeviceBluetooth,
        updateDeviceSettings,
        toggleEnabled,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
