import React from "react";
import { classNames } from "../lib/uiUtils";

type Props = {
  children?: JSX.Element;
  withClass?: string;
};

function Divider({ children, withClass = "" }: Props) {
  return (
    <div
      className={classNames(
        "flex border-t border-darkPopupSecondary dark:border-darkPopupBackground px-4 pt-3 pb-3 scrollbar-custom-surface ",
        withClass
      )}
    >
      {children}
    </div>
  );
}

export default Divider;
