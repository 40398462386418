import Select, { SingleValue, StylesConfig } from "react-select";
// import { classNames } from "../lib/uiUtils";

export default function AFSelect({
  defaultValue,
  options,
  handleChange,
}: {
  defaultValue: { value: string | number; label: string };
  options: { value: string | number; label: string }[];
  handleChange: (
    newValue: SingleValue<{ value: string | number; label: string }>
  ) => void;
}) {
  const changeHandler = (
    newValue: SingleValue<{ value: string | number; label: string }>
  ) => {
    handleChange(newValue);
  };

  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ":focus": {
        ...styles[":focus"],
        color: "#5E00FF",
      },
      ...styles,
      cursor: "pointer",
      //   backgroundColor: "#000",
      //   color: "#FFF",
    }),
    option: (styles) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: "#FFF",
        ":active": {
          ...styles[":active"],
          backgroundColor: "#7B66FF",
          color: "#FFF",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      ":focus": {
        ...styles[":focus"],
        color: "#5E00FF",
      },
    }),
    //   placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles) => ({ ...styles }),
  };

  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      isSearchable={true}
      onChange={(newValue) => changeHandler(newValue)}
      styles={colourStyles}
    />
  );
}
