import { MouseEvent } from "react";
import Panel from "./Panel";

interface Props {
  children: JSX.Element;
  coords: number[];
  setOpen: (val: boolean) => void;
}

export default function OverflowMenu({ children, coords, setOpen }: Props) {
  const onScrimDown = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    e.preventDefault();
    setOpen(false);
  };
  return (
    <div
      role="presentation"
      className="fixed z-10 right-0 left-0 bottom-0 top-0 w-10 h-10"
      onClick={(e) => {
        onScrimDown(e);
      }}
    >
      <div
        aria-hidden="true"
        className="fixed z-30 right-0 top-0 left-0 bottom-0"
      ></div>
      <div
        className="absolute"
        style={{ left: `${coords[0]}px`, top: `${coords[1]}px` }}
      >
        <Panel injectedClassesOuter="border border-primary dark:border-darkPrimary">
          <div className="flex flex-col w-auto h-auto">{children}</div>
        </Panel>
      </div>
    </div>
  );
}
