import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, UserEntry } from "../lib/UserContext";
import Panel from "./Panel";
import { useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import SidebarMobile from "./SidebarMobile";
import { NavigationContext } from "../lib/NavigationProvider";
import ProfileEdit from "./ProfileEdit";
import ProfileBody from "./ProfileBody";
import Notification from "./Notification";
import slugify from "@sindresorhus/slugify";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import mime from "mime-types";
import { reservedHandles } from "../lib/constants";
import NotFound from "../pages/NotFound";

export default function LayoutProfile({
  profileUser,
  isLoggedInUser,
}: {
  profileUser: UserEntry;
  isLoggedInUser: boolean;
}) {
  const navigate = useNavigate();
  const storage = getStorage();
  const { userId, updateUser, usernameExists } = useContext(UserContext);
  const userData: UserEntry = profileUser;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editModeActive, setEditModeActive] = useState(false);
  const { navigation } = useContext(NavigationContext);
  const [userPhoto, setUserPhoto] = useState(userData && userData?.photoURL);
  const [isPublic, setIsPublic] = useState(userData?.isPublic || false);
  const [userBio, setUserBio] = useState<string | undefined>(userData?.bio);
  const [website, setWebsite] = useState<string | undefined>(userData?.website);
  const [username, setUsername] = useState<string | undefined>(
    userData?.displayName
  );
  const [userHandle, setUserHandle] = useState(userData?.slug);
  const [usernameIsValid, setUsernameIsValid] = useState<boolean | undefined>();
  const [websiteIsValid, setWebsiteIsValid] = useState<boolean | undefined>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [notificationMessage, setNotificationMessage] = useState<
    undefined | [string, string?]
  >();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (typeof username === "string") {
      setUserHandle(slugify(username));
    }
  }, [username]);

  // Automatically close
  useEffect(() => {
    if (
      showNotification === true &&
      ["Error", "Success"].includes(notificationStatus)
    ) {
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  }, [showNotification, notificationStatus]);

  // useEffect(())

  const handleImageSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
    if (
      !event.currentTarget ||
      !event.currentTarget.files ||
      !event.currentTarget.files[0]
    ) {
      alert("Please try re-uploading file");
      return;
    }
    setNotificationStatus("Pending");
    setShowNotification(true);

    const file = event.currentTarget.files[0];
    const extension = mime.extension(file.type);

    if (userData?.id && extension) {
      const urlString = `userImages/${userData?.id}/profileAvatar.${extension}`;
      const storageRef = ref(storage, urlString);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setNotificationMessage(["Uploading Image", `${progress}%`]);
        },
        (error) => {
          setNotificationStatus("Error");
          setNotificationMessage(["Error Uploading Image", error.message]);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              // TODO: clean up previous downloadURL before updating in the save method
              setUserPhoto(downloadURL);
              setNotificationStatus("Success");
              setNotificationMessage([
                "Success Uploading your Image",
                "Save your profile to assign it to your profile",
              ]);
            })
            .catch((error) => {
              setNotificationStatus("Error");
              setNotificationMessage(["Error Uploading Image", error]);
            });
        }
      );
    } else {
      alert("An Error Occurred, please try reloading the page.");
    }
  };

  const checkUsernameIsValid = async () => {
    if (!isUpdating) {
      if ((username && userHandle) || (username === "" && userHandle === "")) {
        if (userHandle.length < 3) {
          setUsernameIsValid(false);
          return;
        }
        if (reservedHandles.indexOf(userHandle) >= 0) {
          setUsernameIsValid(false);
          return;
        }

        const isUsernameTaken =
          usernameExists && (await usernameExists(username, userHandle));
        if (isUsernameTaken) {
          if (
            username === userData?.displayName &&
            userHandle === userData?.slug
          ) {
            setUsernameIsValid(true);
          } else {
            setUsernameIsValid(false);
          }
        } else {
          setUsernameIsValid(true);
        }
      }
    }
  };

  const cancelEdit = () => {
    setEditModeActive(false);
    setUsername(userData?.displayName);
    setUserHandle(userData?.slug);
    setUserPhoto(userData?.photoURL);
    setUserBio(userData?.bio);
    setWebsite(userData?.website);
    setIsPublic(userData?.isPublic || false);
    setUsernameIsValid(undefined);
  };

  interface updateOptions {
    [key: string]: string | boolean;
  }

  const handleUserUpdate = async () => {
    if (usernameIsValid === false) {
      setNotificationStatus("Error");
      setShowNotification(true);
      setNotificationMessage([
        "Error",
        "Please Enter a valid Username before saving",
      ]);
      return;
    }
    if (updateUser && userId && userData) {
      const options: updateOptions = {};
      if (username) {
        options.displayName = username;
        options.username = username;
      }
      if (userHandle) options.slug = userHandle;
      if (userPhoto) options.photoURL = userPhoto;
      if (userBio) options.bio = userBio;
      if (website) options.website = website;
      options.isPublic = isPublic;
      setNotificationStatus("Pending");
      setShowNotification(true);
      setNotificationMessage(["Saving Account", ""]);
      setIsUpdating(true);
      await updateUser({
        userId,
        options,
      })
        .then(() => {
          setEditModeActive(false);
          setIsUpdating(false);
          navigate(`/${userHandle}`);
          setNotificationStatus("Success");
          setNotificationMessage([
            "Saved Account",
            "The information has been updated",
          ]);
        })
        .catch((err) => {
          console.error(err);
          setIsUpdating(false);
          setNotificationStatus("Error");
          setNotificationMessage([
            "Error Saving Account",
            "Please refresh the page and try again",
          ]);
        });
    }
  };

  return (
    <>
      <Notification
        passedShow={showNotification}
        passedSetShow={setShowNotification}
        status={notificationStatus}
        message={notificationMessage}
      />
      <SidebarMobile
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={navigation}
      />
      <div>
        <Panel
          injectedClassesOuter="mb-4 mr-0"
          injectedClassesInner="md:pt-4 pt-4 pb-4 px-4 mx-auto"
        >
          <div className="md:h-0 h-16">
            <button
              type="button"
              className="p-2 ml-4 text-primary dark:text-darkPrimary focus:outline-none focus:ring-2 focus:ring-inset focus:rounded-full focus:ring-purple md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {isLoggedInUser && editModeActive === false && (
              <div className="absolute right-4 top-4 w-20">
                <button
                  className="text-primary bg-transparent border-2 border-primary box-border dark:text-darkPrimary hover:dark:text-primary dark:border-darkPrimary hover:secondary hover:bg-primary hover:text-darkPrimary hover:dark:bg-darkPopupPrimary h-10 rounded transition-all flex-shrink-0 flex items-center justify-center cursor-pointer w-20 text-sm font-medium"
                  onClick={() => setEditModeActive(true)}
                  onKeyDown={() => setEditModeActive(true)}
                >
                  Edit
                </button>
              </div>
            )}
            {isLoggedInUser && editModeActive === true && (
              <>
                <div className="absolute right-28 top-4 w-20">
                  <button
                    className="text-primary bg-transparent border-2 border-primary box-border dark:text-darkPrimary hover:dark:text-primary dark:border-darkPrimary hover:secondary hover:bg-primary hover:text-darkPrimary hover:dark:bg-darkPopupPrimary h-10 text-sm font-medium rounded transition-all flex-shrink-0 flex items-center justify-center cursor-pointer w-20"
                    onClick={cancelEdit}
                    onKeyDown={cancelEdit}
                  >
                    Cancel
                  </button>
                </div>
                <div className="absolute right-4 top-4 w-20">
                  <button
                    className="border-none bg-purple text-darkPrimary hover:bg-purpleLighter h-10 text-sm font-medium rounded transition-all flex-shrink-0 flex items-center justify-center cursor-pointer w-20"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => await handleUserUpdate()}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="h-40 w-40 md:mt-2 mb-2 mx-2">
            <div className="relative overflow-hidden rounded-full block bg-gradient-to-br from-purpleLighter to-darkPrimary">
              {userPhoto ? (
                <img
                  className="relative h-40 w-40 rounded-full"
                  src={userPhoto}
                  alt="User"
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-full w-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
                  />
                </svg>
              )}
              {editModeActive && (
                <label
                  htmlFor="desktop-user-photo"
                  className="absolute inset-0 flex h-full items-center justify-center bg-primary dark:bg-darkPrimary bg-opacity-75 text-sm font-medium text-darkPrimary dark:text-primary opacity-0 focus-within:opacity-100 hover:opacity-90 hover:dark:opacity-75"
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                  <input
                    type="file"
                    id="desktop-user-photo"
                    name="user-photo"
                    onChange={handleImageSubmit}
                    accept="image/png, image/jpeg, image/gif"
                    className="absolute inset-0 h-40 w-40 cursor-pointer rounded-md border-gray-300 opacity-0 text-primary dark:text-darkPrimary"
                  />
                </label>
              )}
            </div>
          </div>
          {/* Profile Edit */}
          {editModeActive && userData && (
            <ProfileEdit
              user={userData}
              setOpen={setEditModeActive}
              isPublic={isPublic}
              setIsPublic={setIsPublic}
              username={username}
              setUsername={setUsername}
              userHandle={userHandle}
              checkUsername={checkUsernameIsValid}
              usernameIsValid={usernameIsValid}
              userBio={userBio}
              setUserBio={setUserBio}
              website={website}
              setWebsite={setWebsite}
              websiteIsValid={websiteIsValid}
              setWebsiteIsValid={setWebsiteIsValid}
            />
          )}
          {/* Profile Body */}
          {!editModeActive && userData && (
            <ProfileBody user={userData} isLoggedInUser={isLoggedInUser} />
          )}
          {profileUser === undefined && <NotFound />}
        </Panel>
      </div>
    </>
  );
}
