import { MoonIcon, SunIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { ThemeContext } from "../lib/ThemeContext";

const ThemeSwitcher = ({ className = "" }) => {
  const [theme, toggleTheme] = useContext(ThemeContext);

  return (
    <button
      onClick={toggleTheme}
      className={`${className} rounded-full text-primary dark:text-darkPrimary`}
    >
      {theme === "dark" ? (
        <MoonIcon className="w-4 h-4" />
      ) : (
        <SunIcon className="w-4 h-4" />
      )}
    </button>
  );
};

export default ThemeSwitcher;
