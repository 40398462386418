import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { ThemeProvider } from "./lib/ThemeContext";
import FirebaseProvider from "./components/FirebaseProvider";
import Web3Provider from "./components/Web3Provider";
import Router from "./components/Router";

const App = () => (
  <FirebaseProvider>
    <ThemeProvider>
      <Web3Provider>
        <Router />
      </Web3Provider>
    </ThemeProvider>
  </FirebaseProvider>
);

const container: HTMLElement | null = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
