const CollectionIcon = (passedClasses: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="current"
      className={passedClasses}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5385 16.1538H17.3077C16.6704 16.1538 16.1538 16.6704 16.1538 17.3077V26.5385C16.1538 27.1757 16.6704 27.6923 17.3077 27.6923H26.5385C27.1757 27.6923 27.6923 27.1757 27.6923 26.5385V17.3077C27.6923 16.6704 27.1757 16.1538 26.5385 16.1538ZM26.5385 32.3077H17.3077C16.6704 32.3077 16.1538 32.8243 16.1538 33.4615V42.6923C16.1538 43.3296 16.6704 43.8462 17.3077 43.8462H26.5385C27.1757 43.8462 27.6923 43.3296 27.6923 42.6923V33.4615C27.6923 32.8243 27.1757 32.3077 26.5385 32.3077ZM42.6923 16.1538H33.4615C32.8243 16.1538 32.3077 16.6704 32.3077 17.3077V26.5385C32.3077 27.1757 32.8243 27.6923 33.4615 27.6923H42.6923C43.3296 27.6923 43.8462 27.1757 43.8462 26.5385V17.3077C43.8462 16.6704 43.3296 16.1538 42.6923 16.1538ZM42.6923 32.3077H33.4615C32.8243 32.3077 32.3077 32.8243 32.3077 33.4615V42.6923C32.3077 43.3296 32.8243 43.8462 33.4615 43.8462H42.6923C43.3296 43.8462 43.8462 43.3296 43.8462 42.6923V33.4615C43.8462 32.8243 43.3296 32.3077 42.6923 32.3077ZM17.3077 15C16.0332 15 15 16.0332 15 17.3077V26.5385C15 27.813 16.0332 28.8462 17.3077 28.8462H26.5385C27.813 28.8462 28.8462 27.813 28.8462 26.5385V17.3077C28.8462 16.0332 27.813 15 26.5385 15H17.3077ZM17.3077 31.1538C16.0332 31.1538 15 32.187 15 33.4615V42.6923C15 43.9668 16.0332 45 17.3077 45H26.5385C27.813 45 28.8462 43.9668 28.8462 42.6923V33.4615C28.8462 32.187 27.813 31.1538 26.5385 31.1538H17.3077ZM31.1538 17.3077C31.1538 16.0332 32.187 15 33.4615 15H42.6923C43.9668 15 45 16.0332 45 17.3077V26.5385C45 27.813 43.9668 28.8462 42.6923 28.8462H33.4615C32.187 28.8462 31.1538 27.813 31.1538 26.5385V17.3077ZM31.1538 33.4615C31.1538 32.187 32.187 31.1538 33.4615 31.1538H42.6923C43.9668 31.1538 45 32.187 45 33.4615V42.6923C45 43.9668 43.9668 45 42.6923 45H33.4615C32.187 45 31.1538 43.9668 31.1538 42.6923V33.4615Z"
      />
    </svg>
  );
};

export default CollectionIcon;
