import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentData,
  WithFieldValue,
  PartialWithFieldValue,
} from "firebase/firestore";
// Device Icons
import Wave from "../resources/reactIcons/wave";
import Photon from "../resources/reactIcons/photon";
// Device Setting Icons
import ActionIconPower from "../resources/reactIcons/DeviceIcons/ActionIconPower";
import ActionIconSleep from "../resources/reactIcons/DeviceIcons/ActionIconSleep";
import ActionIconSlideshow from "../resources/reactIcons/DeviceIcons/ActionIconSlideshow";
import { langResolveDisplayFrame, langResolveDisplayOrientation, langResolveDisplayTheme } from "./utils";
import DeviceControlSlideshow from "../components/DeviceControlSlideshow";
import ActionIconBluetooth from "../resources/reactIcons/DeviceIcons/ActionIconBluetooth";
import ActionIconBrightness from "../resources/reactIcons/DeviceIcons/ActionIconBrightness";
import ActionIconOrientation from "../resources/reactIcons/DeviceIcons/ActionIconOrientation";

// Devices

// Device Control
export interface DeviceSetting {
  name: string;
  icon: (status?: string, frame?: DisplayFrame) => JSX.Element;
  ellipsisComponent?: (device: DeviceEntry, option: DeviceSetting) => JSX.Element;
  isDisabled: boolean;
  accessor?: string;
  accessorValues: (accessorStatus: boolean | string | number) => string | number;
}
export const deviceOptions: DeviceSetting[] = [
  {name: "Status", icon: ActionIconPower, ellipsisComponent: undefined, isDisabled: true, accessor: "", accessorValues: () => "On"},
  {name: "Sleep Schedule", icon: ActionIconSleep, ellipsisComponent: DeviceControlSlideshow, isDisabled: false, accessor: "sleepSchedule.enabled", accessorValues: (accessorStatus) => accessorStatus ? "On ": "Off"},
  {name: "Slideshow", icon: ActionIconSlideshow, ellipsisComponent: DeviceControlSlideshow, isDisabled: false, accessor: "slideshow", accessorValues: (accessorStatus) => accessorStatus ? "Playing" : "Paused"},
  {name: "Sound", icon: ActionIconBluetooth, ellipsisComponent: undefined, isDisabled: false, accessor: "bluetooth.connectionState", accessorValues: (accessorStatus) => accessorStatus === "connected" ? "Connected" : "Disconnected"},
  {name: "Display mode", icon: ActionIconPower, ellipsisComponent: undefined, isDisabled: false, accessor: "frame", accessorValues: (accessorStatus) => langResolveDisplayFrame(accessorStatus as DisplayFrame)},
  {name: "Brightness", icon: ActionIconBrightness, ellipsisComponent: undefined, isDisabled: false, accessor: "brightness", accessorValues: (accessorStatus) => typeof(accessorStatus) === "number" ? String(accessorStatus) : "Unset"},
  {name: "Theme", icon: ActionIconPower, ellipsisComponent: undefined, isDisabled: false, accessor: "theme", accessorValues: (accessorStatus) => langResolveDisplayTheme(accessorStatus as DisplayTheme)},
  {name: "Screen Rotation", icon: ActionIconOrientation, ellipsisComponent: undefined, isDisabled: false, accessor: "fallbackOrientation", accessorValues: (accessorStatus) => langResolveDisplayOrientation(accessorStatus as DisplayOrientation)}

];

export enum DisplayFrame {
  Fill = "fill",
  Fit = "fit",
  Banner = "banner",
  Gallery = "gallery",
}

export enum DisplayOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
  LandscapeInverted = "landscapeInverted",
  PortraitInverted = "portraitInverted",
}

export enum DisplayTheme {
  Light = "light",
  Dark = "dark",
  Custom = "custom",
}

export enum DeviceType {
  Photon = "photon",
  Wave = "wave",
}

// Interfaces
export interface DeviceDataBluetooth {
  isScanning: boolean;
  availableDevices: DeviceDataBluetoothDevice[];
  targetDevice: DeviceDataBluetoothDevice | null;
  connectionState: "disconnected" | "connecting" | "connected";
  error: DeviceDataBluetoothError | null;
}

export interface DeviceDataBluetoothDevice {
  alias: string;
  address: string;
}

export interface DeviceDataBluetoothError {
  device: DeviceDataBluetoothDevice;
  code: number;
  message: string | null;
}

export interface DeviceDataSleepSchedule {
  enabled: boolean;
  timeZone: string;
  hourEnter: number;
  minuteEnter: number;
  hourExit: number;
  minuteExit: number;
}

export interface DeviceEntry {
  id: string;
  icon: () => JSX.Element;
  name: string;
  type: DeviceType;
  state: DeviceState;
  current: boolean;
}

export interface DeviceState {
  frame: DisplayFrame;
  theme: DisplayTheme;
  themeCustomColor: string;
  slideshow: boolean;
  slideshowDuration: number;
  sleepSchedule: DeviceDataSleepSchedule;
  bluetooth: DeviceDataBluetooth;
  fallbackOrientation: DisplayOrientation;
  brightness: number | null;
}

export interface DeviceEntryRaw {
  label: string;
  type: DeviceType;
  frame: DisplayFrame;
  theme: DisplayTheme;
  customTheme: string;
  slideshow: boolean;
  duration: number;
  sleepSchedule: {
    enabled: boolean;
    timeZone: string;
    hourEnter: number;
    hourExit: number;
    minuteEnter: number;
    minuteExit: number;
  };
  bluetooth: {
    isScanning: boolean;
    availableDevices: [];
    targetDevice: DeviceDataBluetoothDevice;
    connectionState: DeviceDataBluetooth["connectionState"];
    error: DeviceDataBluetooth["error"];
  };
  fallbackOrientation: DisplayOrientation | null;
  brightness: number | null;
}

// Converter
export const deviceEntryFirestoreDataConverter: FirestoreDataConverter<DeviceEntry> =
  {
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): DeviceEntry {
      const data: DeviceEntryRaw = snapshot.data(options);

      return {
        id: snapshot.id,
        name: data["label"],
        type: data["type"],
        icon: data["type"] === "photon" ? Photon : Wave,
        current: false,
        state: {
          frame: data["frame"],
          theme: data["theme"],
          themeCustomColor: data["customTheme"],
          slideshow: data["slideshow"],
          slideshowDuration: data["duration"],
          sleepSchedule: {
            enabled: data["sleepSchedule"]?.["enabled"] ?? false,
            timeZone:
              data["sleepSchedule"]?.["timeZone"] ??
              Intl.DateTimeFormat().resolvedOptions().timeZone,
            hourEnter: data["sleepSchedule"]?.["hourEnter"] ?? 22,
            hourExit: data["sleepSchedule"]?.["hourExit"] ?? 6,
            minuteEnter: data["sleepSchedule"]?.["minuteEnter"] ?? 0,
            minuteExit: data["sleepSchedule"]?.["minuteExit"] ?? 0,
          },
          bluetooth: {
            isScanning: data["bluetooth"]?.["isScanning"] ?? false,
            availableDevices: data["bluetooth"]?.["availableDevices"] ?? [],
            targetDevice: data["bluetooth"]?.["targetDevice"] ?? null,
            connectionState:
              data["bluetooth"]?.["connectionState"] ?? "disconnected",
            error: data["bluetooth"]?.["error"] ?? null,
          },
          fallbackOrientation:
            data["fallbackOrientation"] ?? DisplayOrientation.Landscape,
          brightness: data["brightness"] ?? null,
        },
      };
    },
    toFirestore(
      modelObject:
        | WithFieldValue<DeviceEntry>
        | PartialWithFieldValue<DeviceEntry>
    ): DocumentData {
      return {
        label: modelObject.name,
        type: modelObject.type,
      };
    },
  };
