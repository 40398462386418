import { wallet } from "@rainbow-me/rainbowkit";
import { logEvent } from "firebase/analytics";
import { FirebaseError } from "firebase/app";
import { useContext, useState } from "react";
import { useAnalytics } from "reactfire";
import { LendingHistoryItem, NFTContext } from "../lib/NFTContext";
import {
  connectToEthereum,
  connectToSolana,
  CountersignChallengeProps,
  signChallengeEthereum,
  signChallengeSolana,
  UserContext,
} from "../lib/UserContext";
import AFButton from "./AFButton";
import ArtCardMini from "./ArtCardMini";

interface HistoryProps {
  nfts: LendingHistoryItem[];
}

const PendingLends = ({ nfts }: HistoryProps) => {
  const analytics = useAnalytics();
  const [borrowerAddress, setBorrowerAddress] = useState<string | undefined>();
  const [solanaAddress, setSolanaAddress] = useState<Uint8Array | undefined>();
  const [pendingNFT, setPendingNFT] = useState<
    LendingHistoryItem | undefined
  >();
  const [hasError, setError] = useState<string | undefined>();
  const [challenge, setChallenge] = useState<string | undefined>();
  const [signature, setSignature] = useState<string | Uint8Array>("");
  const [message, setMessage] = useState<string | undefined>();
  const [isRejectingNFT, setRejectingNFT] = useState(false);
  const [step, setStep] = useState(0);
  const [isCounterSigning, setIsCounterSigning] = useState(false);
  const { getChallengeCountersign, rejectLendNFT, wallets } =
    useContext(UserContext);
  const { countersignLendNFT } = useContext(NFTContext);

  const walletArray = wallets?.map((wallet) => wallet.address);

  const handleReject = (nft: LendingHistoryItem) => {
    setRejectingNFT(true);
    setPendingNFT(nft);
    rejectLendNFT &&
      typeof nft.borrower === "string" &&
      rejectLendNFT({
        lendId: nft.lendId,
        borrowerAddress: nft.borrower,
      })
        .then(() => {
          logEvent(analytics, "reject_lend_nft", {
            nft_id: nft.nftId,
            lender_address: nft.lender,
            borrower_address: nft.borrower,
            start_date: nft.startDate,
            end_date: nft.endDate,
          });
          window.location.reload();
        })
        .catch((e) => {
          console.error("Rejection Error:", e);
          setError("Error Rejection");
        });
  };

  const handleCountersign = (nft: LendingHistoryItem) => {
    // Reimplement/refactor LendingModalContent sign Message
    setPendingNFT(nft);
    connectToWallet(nft).catch((e) => console.error("Countersign Error", e));
  };

  const getChallengeFn = async ({
    address,
    apiId,
    chainId,
    lend,
  }: CountersignChallengeProps): Promise<string | void> => {
    try {
      const message =
        getChallengeCountersign &&
        (await getChallengeCountersign({
          address,
          apiId,
          chainId,
          lend,
        }));
      if (message) {
        return message;
      }
    } catch (error) {
      console.error("getlenderror", error);
    }
  };

  const connectToWallet = async (nft: LendingHistoryItem): Promise<void> => {
    const { chainID, borrower } = nft;
    try {
      if (chainID === 3) {
        //  Solana Connection
        const solanaDetails = await connectToSolana();
        if (solanaDetails && typeof nft.lender === "string") {
          setBorrowerAddress(solanaDetails.stringAddress);
          setSolanaAddress(solanaDetails.arrayAddress);
          await getChallengeMessage({
            address: solanaDetails.stringAddress,
            apiId: [3],
            chainId: [3],
            lend: {
              lender: nft.lender,
              borrower: solanaDetails.stringAddress,
              startDate: nft.startDate,
              endDate: nft.endDate,
              nftId: nft.nftId,
            },
            nft,
          });
        }
      } else {
        const ethDetails = await connectToEthereum().catch((e) => {
          console.error("Lending Error", e);
        });
        if (ethDetails !== undefined) {
          if (ethDetails !== borrower && typeof borrower === "string") {
            alert(
              `To Accept this lend, please ensure you're connecting with the wallet containing this NFT: ${borrower}.`
            );
            setError(
              `Ensure you're connecting with the wallet containing this NFT: ${borrower}.`
            );
            return;
          } else if (typeof nft.lender === "string") {
            if (hasError) setError(undefined);
            setBorrowerAddress(ethDetails);
            await getChallengeMessage({
              address: ethDetails,
              apiId: [1, 2],
              chainId: [1, 2],
              lend: {
                lender: nft.lender,
                borrower: ethDetails,
                startDate: nft.startDate,
                endDate: nft.endDate,
                nftId: nft.nftId,
              },
              nft,
            });
          }
        }
      }
    } catch (e) {
      console.error("Error connecting", e);
      setError("Error retrieving Message from Atomic Form backend.");
    }
  };
  //   : {
  //     lender: lenderAddress,
  //     borrower: borrowerAddress,
  //     startDate: new Date(startDateString),
  //     endDate: new Date(endDateString),
  //     nftId: nft.id,
  //   }

  const getChallengeMessage = async ({
    address,
    apiId,
    chainId,
    lend,
    nft,
  }: CountersignChallengeProps) => {
    try {
      const message = await getChallengeFn({
        address: address,
        apiId: apiId,
        chainId: chainId,
        lend,
      });
      if (message && nft) {
        setChallenge(message);
        await signMessage(nft, message);
      } else {
        setError("Error retrieving Message from Atomic Form backend.");
      }
    } catch (e) {
      console.error("Error connecting", e);
      setError("Error retrieving Message from Atomic Form backend.");
    }
  };

  const signMessage = async (
    nft: LendingHistoryItem,
    passedChallenge: string
  ): Promise<void> => {
    const message = passedChallenge;
    if (nft && message && signChallengeEthereum && signChallengeSolana) {
      if (nft.chainID === 3) {
        const encodedMessage = new TextEncoder().encode(message);
        const solSig = await signChallengeSolana({ message: encodedMessage });
        if (solSig) {
          setSignature(solSig.signature);
          setSolanaAddress(solSig.publicKey?.toBuffer());
          setStep(step + 1);
          await countersignCallback(solSig.signature, nft, nft.borrower);
          //   await lendCallback(solSig.signature);
        }
      } else if (typeof nft.borrower === "string") {
        const ethSig = await signChallengeEthereum({
          address: nft.borrower,
          message,
        });
        if (ethSig) {
          setSignature(ethSig);
          setStep(step + 1);
          await countersignCallback(ethSig, nft, nft.borrower);
        }
      }
    }
  };

  // lendCallback
  const countersignCallback = async (
    signature: string | Uint8Array,
    nft: LendingHistoryItem,
    signingAddress: string | Uint8Array
  ) => {
    // owner Address
    const lender = nft.lender;
    const borrower = nft.borrower;
    const startDate = nft.startDate;
    const endDate = nft.endDate;
    const nftId = nft.nftId;

    try {
      countersignLendNFT &&
        (await countersignLendNFT({
          lendId: nft.lendId,
          signingAddress: signingAddress,
          signature: signature,
        }));
      logEvent(analytics, "countersign_lend_nft", {
        nft_id: nftId,
        lender_address: lender,
        borrower_address: borrower,
        start_date: startDate,
        end_date: endDate,
      });
      window.location.reload();
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.message);
        throw error;
      } else {
        setError("Error Countersigning NFT");
      }
    }
  };

  return (
    <div className="flex flex-col items-start text-left">
      {nfts &&
        nfts.map((nft: LendingHistoryItem) => {
          return (
            <div
              key={nft.lendId}
              className="flex w-full flex-col lg:flex-row h-auto justify-between my-8 pb-6 items-start border-b-2 last:border-b-0 border-scrollbar dark:border-darkScrollbar"
            >
              <div className="flex lg:flex-[0.4] flex-1 w-full max-w-[100%] lg:max-w-[40%] lg:pt-7 mb-6 justify-center items-center">
                <div className="w-auto max-w-[100%] h-auto mb-4 lg:mb-0 mr-0 lg:mr-2">
                  <ArtCardMini nft={nft} />
                </div>
              </div>
              <div className="w-full flex-1 ml-2">
                <p className="text-lg">Details</p>
                <div className="rounded-lg p-3 bg-surface dark:bg-popupBackgroundSecondary">
                  <table className="min-w-full divide-y dark:divide-surface divide-popupBackground">
                    <tbody className="divide-y dark:divide-popupSecondary divide-symbolFaint bg-surface dark:bg-popupBackgroundSecondary rounded-full">
                      <tr>
                        <td>Lender</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.lender}
                        </td>
                      </tr>
                      <tr>
                        <td>Borrower</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.borrower}
                        </td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.startDate.toDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td>End Date</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.endDate.toDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                          {nft.lendStatus[0].toUpperCase() +
                            nft.lendStatus.substring(1)}
                        </td>
                      </tr>
                      {nft.loanDescription && (
                        <tr>
                          <td>Loan Description</td>
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 overflow-hidden text-ellipsis">
                            {nft.loanDescription}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {typeof nft.borrower === "string" &&
                  walletArray?.includes(nft.borrower) && (
                    <div className="flex items-center justify-end w-full mt-4">
                      <AFButton
                        disabled={false}
                        width={undefined}
                        fullWidth={false}
                        flexFill={true}
                        variant={"outline"}
                        type={undefined}
                        clickHandler={() => handleReject(nft)}
                      >
                        <p>
                          {isRejectingNFT &&
                          pendingNFT &&
                          pendingNFT.lendId === nft.lendId
                            ? "Rejecting Lend"
                            : "Reject Lend"}
                        </p>
                      </AFButton>
                      <div className="w-8" />
                      <AFButton
                        disabled={false}
                        width={undefined}
                        fullWidth={false}
                        flexFill={true}
                        variant={"accent"}
                        type={undefined}
                        clickHandler={() => handleCountersign(nft)}
                      >
                        <p>
                          {isCounterSigning &&
                          pendingNFT &&
                          pendingNFT.lendId === nft.lendId
                            ? "Processing Signature"
                            : "Accept Lend"}
                        </p>
                      </AFButton>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PendingLends;
