import { DeviceType, DisplayFrame, DisplayOrientation, DisplayTheme } from "./DeviceConstants";

export const trimOwnerId = (uid: string | null) => {
  if (uid === null) return;
  return uid.split(":", 2)[1];
};

/**
 * Replaces a portion of a string with another string at a specified index
 * @param string The string to overwrite
 * @param index The index to start replacement
 * @param replacement The string to overwrite the original string with
 */
 export function replaceStringAt(string: string, index: number, replacement: string): string {
	return string.substring(0, index) + replacement + string.substring(index + replacement.length);
}

export const formatDate = (date: Date) => {
	let dateString = date.toUTCString();
	if (dateString.includes("00:00:00 GMT")) {
		dateString = dateString.split("00:00:00 GMT")[0];
	}
  return dateString;
}

export function generatePageLocationString(pathname: string) {
	const pathStrings = pathname.split("/");
	let pageLocation: string;
  if (pathStrings[1] === "login") {
    pageLocation = "Login";
   } else if (pathStrings[1] === "") {
      pageLocation = "Collection";
    } else if (pathStrings[1] === "nifty-callback") {
      pageLocation = "Nifty Callback";
    } else if (pathStrings[1] === "profile") {
      pageLocation = "Profile";
    } else if (pathStrings[1] === "device") {
      pageLocation = "Device";
    } else if (pathStrings[1] === "lending") {
      pageLocation = "Lending";
    } else if (pathStrings[1] === "provenance") {
		pageLocation = "Provenance";
	}else {
		pageLocation = pathname;
	}
	return pageLocation;
}

export function generateDateString(date: Date) {
  let month = `${date.getMonth() +1}`
  let day= `${date.getDate()}`;
  const year = `${date.getFullYear()}`;
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join('-');
}

export function normalizeIPFSURL(url: string): string {
  const ipfsProtocol = "ipfs://";
  const ipfsGateway = "https://ipfs.io/";

	if(url.startsWith(ipfsProtocol)) {
		return ipfsGateway + url.substring(ipfsProtocol.length);
	}
	
	return url;
}

export function formatThumbnailURL(url: string): string {
	const newUrl = normalizeIPFSURL(url);
	const trimmedURL = newUrl.replace(/=s(.*?)$/g, "=s0")
    return trimmedURL;
}

/**
 * Builds a human-readable list with proper punctuation out of list components
 */
 export function langBuildList(listComponents: string[]): string {
	if(listComponents.length === 0) {
		return "";
	} else if(listComponents.length === 1) {
		return listComponents[0];
	} else if(listComponents.length === 2) {
		return `${listComponents[0]} and ${listComponents[1]}`;
	} else {
		return listComponents.slice(listComponents.length - 1).join(", ") + ", and " + listComponents[listComponents.length - 1];
	}
}

/**
 * Maps a device type to a human-readable string
 */
 export function langResolveDeviceType(type: DeviceType) {
	switch(type) {
		case DeviceType.Photon:
			return "Photon";
		case DeviceType.Wave:
			return "Wave";
		default:
			return "Unknown device"
	}
}

/**
 * Maps a display frame to a human-readable string
 */
 export function langResolveDisplayFrame(frame: DisplayFrame) {
	switch(frame) {
		case DisplayFrame.Fill:
			return "Fill screen"
		case DisplayFrame.Fit:
			return "Fit screen";
		case DisplayFrame.Banner:
			return "Bar";
		case DisplayFrame.Gallery:
			return "Gallery";
		default:
			return "Unknown display frame";
	}
}


/**
 * Maps a display theme to a human-readable string
 */
 export function langResolveDisplayTheme(theme: DisplayTheme) {
	switch(theme) {
		case DisplayTheme.Light:
			return "Light";
		case DisplayTheme.Dark:
			return "Dark";
		case DisplayTheme.Custom:
			return "Custom";
		default:
			return "Unknown display theme";
	}
}

/**
 * Maps a display orientation to a human-readable string
 */
export function langResolveDisplayOrientation(orientation: DisplayOrientation): string {
	switch(orientation) {
		case DisplayOrientation.Landscape:
			return "0 deg";
		case DisplayOrientation.Portrait:
			return "90 deg";
		case DisplayOrientation.LandscapeInverted:
			return "180 deg";
		case DisplayOrientation.PortraitInverted:
			return "270 deg";
	}
}

/**
 * Formats a number as a human-readable percentage
 * @param percent A number from 0.0 to 1.0, to be read as a percent
 */
export function langFormatPercent(percent: number): string {
	return Math.floor(percent * 100).toString() + "%";
}

/**
 * Formats a duration in milliseconds to a short human-readable string
 */
export function langFormatDurationShort(duration: number) {
	if(duration < 1000) {
		return `${duration} ms`;
	} else if(duration < 60 * 1000) {
		return `${Math.floor(duration / 1000)} sec`;
	} else if(duration < 60 * 60 * 1000) {
		return `${Math.floor(duration / (60 * 1000))} min`;
	} else {
		return `${Math.floor(duration / (60 * 60 * 1000))} hr`;
	}
}


const reactFirePreloadedObservables = (globalThis as Record<string, unknown>)['_reactFirePreloadedObservables'] as
| Map<string, unknown>
| undefined;

export const clearFirestoreCache = () => {
  // const map = globalThis['_reactFirePreloadedObservables'];
  if (reactFirePreloadedObservables) {
    Array.from(reactFirePreloadedObservables.keys())
      .filter((key) => key.includes('firestore'))
      .forEach((key) =>
        reactFirePreloadedObservables.delete(key)
      );
  }
};