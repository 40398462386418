import { classNames } from "../lib/uiUtils";
import { Switch } from "@headlessui/react";
import { UserContext, UserEntry } from "../lib/UserContext";
import { useContext } from "react";

export default function ProfileEdit({
  user,
  setOpen,
  isPublic,
  setIsPublic,
  username,
  setUsername,
  userHandle,
  checkUsername,
  usernameIsValid,
  userBio,
  setUserBio,
  website,
  setWebsite,
  websiteIsValid,
  setWebsiteIsValid,
}: {
  user: UserEntry;
  setOpen: (status: boolean) => void;
  isPublic: boolean;
  setIsPublic: (status: boolean) => void;
  username: string | undefined;
  setUsername: (value: string | undefined) => void;
  userHandle: string | undefined;
  checkUsername: () => Promise<void>;
  usernameIsValid: undefined | boolean;
  userBio: string | undefined;
  setUserBio: (value: string | undefined) => void;
  website: string | undefined;
  setWebsite: (value: string | undefined) => void;
  websiteIsValid: boolean | undefined;
  setWebsiteIsValid: (arg: boolean) => void;
}) {
  const { usernameExists } = useContext(UserContext);

  const saveUser = () => {
    console.log("Saving user");
    setOpen(false);
  };

  const usernameClasses =
    usernameIsValid === false
      ? "border-error focus:border-error focus:ring-error text-error"
      : " border-gray-300 focus:border-purpleLighter focus:ring-purpleLighter";

  const websiteClasses =
    websiteIsValid === false
      ? "border-error focus:border-error focus:ring-error text-error"
      : " border-gray-300 focus:border-purpleLighter focus:ring-purpleLighter";

  const websiteSpanClasses =
    websiteIsValid === false ? "border-error text-error" : "border-gray-300";

  return (
    <>
      {/* User Info */}
      <div className="px-6 mt-8 divide-y divide-gray-200">
        <h2 className="text-lg font-medium leading-6 text-primary dark:text-darkPrimary">
          User Info
        </h2>
        <p className="mt-1 pt-2 text-sm text-primary dark:text-darkPrimary">
          The information related to your account, if your Account is made
          public, this is what people will see when they visit your profile.
        </p>
      </div>
      <form
        className="divide-y divide-gray-200 lg:col-span-9"
        onSubmit={(event) => {
          event.preventDefault();
          saveUser();
        }}
      >
        {/* Profile section */}
        <div className="pt-0 pb-6 pl-6 pr-6 px-4 mt-3">
          <div className="flex flex-col lg:flex-row">
            <div className="flex-grow space-y-6">
              {/* Username */}
              <div className="col-span-12">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-primary dark:text-darkPrimary"
                >
                  Username
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onBlur={async () => {
                      // Here we will validate the input, checking both handle and username
                      await checkUsername();
                    }}
                    onChange={(event) => {
                      // Check if the username is valid for our URLs
                      if (
                        !event.target.value.includes("@") &&
                        !event.target.value.includes("%") &&
                        !event.target.value.includes(":") &&
                        !event.target.value.includes("$") &&
                        !event.target.value.includes(";") &&
                        !event.target.value.includes("=") &&
                        !event.target.value.includes("#") &&
                        !event.target.value.includes("|") &&
                        !event.target.value.includes("?") &&
                        !event.target.value.includes("+") &&
                        !event.target.value.includes("\\") &&
                        !event.target.value.includes("/") &&
                        !event.target.value.includes("<") &&
                        !event.target.value.includes(">") &&
                        !event.target.value.includes("^") &&
                        !event.target.value.includes("{") &&
                        !event.target.value.includes("}") &&
                        !event.target.value.includes("(") &&
                        !event.target.value.includes(")") &&
                        !event.target.value.includes("[") &&
                        !event.target.value.includes("]")
                      ) {
                        setUsername(event.target.value);
                        if (usernameIsValid === false) {
                          void (async () => {
                            await checkUsername()
                              .catch((err) => {
                                console.error(err);
                              })
                              .then(() => console.log("Checked username"));
                          })();
                        }
                      }
                    }}
                    value={username !== undefined ? username : user.displayName}
                    className={classNames(
                      "mt-1 block w-full rounded-md border py-2 px-3 shadow-sm focus:outline-none sm:text-sm text-primary dark:text-darkPrimary bg-background dark:bg-darkBackground",
                      usernameClasses
                    )}
                  />
                  {usernameIsValid === false && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {username.length < 3 || username === ""
                        ? "Usernames must be at least 3 characters"
                        : "This Username is taken, please select another"}
                    </p>
                  )}
                  <p className="mt-2 text-sm text-primary dark:text-darkPrimary">
                    Your accounts username, it will be converted to a url
                    friendly format which you can see below.
                  </p>
                </div>
              </div>
              {/* Userslug */}
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-primary dark:text-darkPrimary"
                >
                  Profile URL
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center rounded-md border border-gray-300 bg-gray-50 px-3 text-primary dark:text-darkPrimary sm:text-sm bg-background dark:bg-darkBackground">
                    app.atomicform.com/{userHandle}
                  </span>
                  {/* <input
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="username"
                    className="block w-full min-w-0 flex-grow rounded-none rounded-r-md border-gray-300 focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                    defaultValue={user.handle}
                  /> */}
                </div>
              </div>
              {/* About */}
              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-primary dark:text-darkPrimary"
                >
                  About
                </label>
                <div className="mt-1">
                  <textarea
                    id="about"
                    name="about"
                    onChange={(event) => setUserBio(event.target.value)}
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple focus:ring-purple sm:text-sm text-primary dark:text-darkPrimary bg-background dark:bg-darkBackground"
                    defaultValue={userBio}
                  />
                </div>
                <p className="mt-2 text-sm text-primary dark:text-darkPrimary">
                  Brief description for your profile. New lines are preserved.
                </p>
              </div>
              {/* Website */}
              <div>
                <label
                  htmlFor="website"
                  className="block text-sm font-medium text-primary dark:text-darkPrimary"
                >
                  Website
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span
                    className={classNames(
                      "inline-flex items-center rounded-l-md border border-r-0 pl-2 pr-[2px] sm:text-sm text-primary dark:text-darkPrimary bg-background dark:bg-darkBackground",
                      websiteSpanClasses
                    )}
                  >
                    https://
                  </span>
                  <input
                    id="website"
                    name="website"
                    onChange={(event) => setWebsite(event.target.value)}
                    className={classNames(
                      "block w-full border-l-0 flex-grow pl-0 pb-[1.5px] py-0 h-10 rounded-none rounded-r-md shadow-sm sm:text-sm text-primary dark:text-darkPrimary bg-background dark:bg-darkBackground",
                      websiteClasses
                    )}
                    value={website}
                    onBlur={() => {
                      try {
                        website && new URL(`https://${website}`);
                      } catch (e) {
                        console.error("WEBSITE ERROR", website);
                        setWebsiteIsValid(false);
                        return;
                      }
                      setWebsiteIsValid(true);
                    }}
                    type="url"
                  />
                </div>
                {websiteIsValid === false && (
                  <p className="mt-2 text-sm text-red-600" id="website-error">
                    Please enter a valid website
                  </p>
                )}
                <p className="mt-2 text-sm text-primary dark:text-darkPrimary">
                  A website that will be linked from your Profile
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Privacy section */}
        <div className="divide-y divide-gray-200 pt-6">
          <div className="px-4 sm:px-6">
            <div>
              <h2 className="text-lg font-medium leading-6 text-primary dark:text-darkPrimary">
                Privacy
              </h2>
              <p className="mt-1 text-sm text-primary dark:text-darkPrimary">
                This controls whether people can access your Atomic Form Profile
                page.
              </p>
            </div>
            <ul className="mt-2 divide-y divide-gray-200">
              <Switch.Group
                as="li"
                className="flex items-center justify-between py-4"
              >
                <div className="flex flex-col">
                  <Switch.Label
                    as="p"
                    className="text-sm font-medium text-primary dark:text-darkPrimary"
                    passive
                  >
                    Make account public
                  </Switch.Label>
                  <Switch.Description className="text-sm text-primary dark:text-darkPrimary">
                    Switching this will make your profile visible to other
                    users, they will be able to see your Atomic Form Profile and
                    the Wallets, NFTs and Lending History attached to it.
                  </Switch.Description>
                </div>
                <Switch
                  checked={isPublic}
                  onChange={setIsPublic}
                  className={classNames(
                    isPublic
                      ? "bg-purple"
                      : "bg-scrollbar dark:bg-darkScrollbar",
                    "relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isPublic ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </ul>
          </div>
        </div>
      </form>
    </>
  );
}
