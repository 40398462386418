import { PlusIcon } from "@heroicons/react/outline";
import LogoSquare from "../resources/reactIcons/LogoSquare";
import Panel from "./Panel";
import IconButton from "./IconButton";
import Divider from "./Divider";
import DeviceEntry from "./DeviceEntry";
import { DeviceContext } from "../lib/DeviceContext";
import { useContext, useState } from "react";
import AFModal from "./AFModal";
import DeviceSetup from "./DeviceSetup";
import { NavigationContext } from "../lib/NavigationProvider";

export default function SidebarDesktop() {
  const { data } = useContext(DeviceContext);
  const [showAddDeviceModal, toggleAddDeviceModal] = useState(false);
  const { navigation } = useContext(NavigationContext);

  return (
    <div className="hidden md:flex md:flex-col md:fixed mx-5 w-64 z-20 h-sidebar">
      <Panel
        injectedClassesOuter="h-sidebar pb-1"
        injectedClassesInner="h-full"
      >
        <div className="flex flex-col flex-grow h-full pb-1">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 h-full pb-1">
            <div className="flex-1 flex flex-col overflow-hidden pb-1">
              <div className="flex items-center flex-shrink-0 px-4 py-5">
                <LogoSquare passedClasses="h-14 w-auto color-primary fill-primary dark:color-popupPrimary dark:fill-popupPrimary" />
              </div>
              <nav className="flex-1 max-h-56 p-3" aria-label="Sidebar">
                <div className="space-y-1">
                  {navigation.map((item) => (
                    <IconButton item={item} key={item.name} />
                  ))}
                </div>
              </nav>
              <Divider withClass="overflow-y-auto">
                <div className="w-full">
                  {data &&
                    data.map((device) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      return <DeviceEntry device={device} key={device.id} />;
                    })}
                </div>
              </Divider>
            </div>
            <Divider withClass="flex-shrink-0">
              <button
                className="w-full rounded"
                onClick={() => toggleAddDeviceModal(true)}
              >
                <div className="flex items-center flex-shrink-0 h-11 p-3 w-full group block bg-purple hover:bg-purpleLighter text-darkPrimary font-bold rounded">
                  <div>
                    <PlusIcon
                      className="h-6 w-6 font-bold"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-8/12">
                    <span className="mx-3 font-bold">Add Device</span>
                  </div>
                </div>
              </button>
            </Divider>
          </div>
        </div>
      </Panel>
      <AFModal
        toggleModal={toggleAddDeviceModal}
        showModal={showAddDeviceModal}
      >
        <DeviceSetup toggleModal={toggleAddDeviceModal} />
      </AFModal>
    </div>
  );
}
