import { useContext } from "react";
import { Link } from "react-router-dom";
// import { useAuth } from "reactfire";
import { UserContext } from "../lib/UserContext";
// import { trimOwnerId } from "../lib/utils";
import ProfileIcon from "../resources/reactIcons/ProfileIcon";
import Panel from "./Panel";

export default function EmptyDeviceState() {
  const { userId } = useContext(UserContext);

  // TODO: add wallet syncing status
  // TODO: add sync wallet button

  return (
    <div className="flex flex-col justify-center items-center w-full h-full mt-28">
      <Panel injectedClassesInner="p-6">
        <div className="flex flex-col justify-center items-center w-full h-full">
          {/* <ProfileIcon passedClasses="fill-primary dark:fill-darkPrimary" /> */}
          <span className="flex-1 mt-2 block text-xl font-medium">
            No NFT's Added yet
          </span>
          <span className="flex-1 text-center mb-4">
            Go back to the{" "}
            <Link to="/" className="hover:text-purpleLighter underline">
              Collection Page
            </Link>{" "}
            and drag NFT's over this device's Entry on the Sidebar to add them
            to this Device and Display them.
          </span>
          <span className="flex-1 text-center mb-4">
            This is not currently supported on mobile.
          </span>
        </div>
      </Panel>
    </div>
  );
}
