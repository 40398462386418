export default function ActionIconPower() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 45 45"
      fill="current"
    >
      <path
        fillRule="evenodd"
        d="M22 32.377C27.5228 32.377 32 27.8998 32 22.377C32 16.8541 27.5228 12.377 22 12.377C16.4772 12.377 12 16.8541 12 22.377C12 27.8998 16.4772 32.377 22 32.377ZM22 34.377C28.6274 34.377 34 29.0044 34 22.377C34 15.7495 28.6274 10.377 22 10.377C15.3726 10.377 10 15.7495 10 22.377C10 29.0044 15.3726 34.377 22 34.377Z"
      />
      <path d="M20.9995 29.376V15.376H22.9995V29.376H20.9995Z" />
    </svg>
  );
}
