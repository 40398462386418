/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import EthereumLogo from "../resources/ethereum.svg";
import SolanaLogo from "../resources/solana.svg";
import NiftyLogo from "../resources/nifty.png";
import MetamaskLogo from "../resources/metamask.svg";
import PhantomLogo from "../resources/phantom.svg";
import {
  CalendarIcon,
  HomeIcon,
  SearchCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

export const APP_NAME = "Atomic Form";

export const navigation = [
  { name: "Support", href: "https://atomicform.com/pages/support" },
  {
    name: "Terms",
    href: "https://atomicform.com/pages/pre-order-terms-and-conditions",
  },
  { name: "Privacy", href: "https://atomicform.com/pages/privacy-policy" },
];

export const sidebarNavigation = [
  { name: "Collection", href: "", icon: HomeIcon, current: true },
  { name: "Profile", href: "/", icon: CalendarIcon, current: false },
  { name: "Lending", href: "/lending", icon: UserGroupIcon, current: false },
  { name: "Devices", href: "/devices", icon: SearchCircleIcon, current: false },
];

// Typescript
export type Chain = {
  name: string;
  logo: string;
  chainName: string;
  chainLogo: string;
  windowObject: string;
  website: string;
  connect: () => void;
  apiID: number;
  apiIDArray: number[];
  chainIDArray: number[];
};

// Connect function added to chain? or as seperate function hook in Wallet Button?
export const Chains: Chain[] = [
  {
    name: "Ethereum",
    logo: EthereumLogo,
    chainLogo: EthereumLogo,
    chainName: "Ethereum",
    website: "https://metamask.io",
    windowObject: "ethereum",
    connect: () => {console.log("ETH")},
    apiID: 1,
    apiIDArray: [1,2],
    chainIDArray: [1,2]
  },
  {
    name: "Phantom",
    logo: PhantomLogo,
    chainLogo: SolanaLogo,
    chainName: "Solana",
    website: "https://phantom.app",
    windowObject: "solana",
    connect: () => {console.log("SOL")},
    apiID: 3,
    apiIDArray: [3],
    chainIDArray: [3]
  },
  {
    name: "Nifty Gateway",
    logo: NiftyLogo,
    chainLogo: EthereumLogo,
    chainName: "Ethereum",
    website: "https://niftygateway.com",
    windowObject: "",
    connect: () => {console.log("NIF")},
    apiID: 4,
    apiIDArray: [4],
    chainIDArray: [1]
  },
];

export const reservedHandles = [
  "add",
  "admin",
  "app",
  "apps",
  "callback",
  "chat",
  "collection",
  "device",
  "devices",
  "discover",
  "gallery",
  "labs",
  "lending",
  "login",
  "wallet",
  "nifty-callback",
  "provenance",
  "playlists",
  "users",
];