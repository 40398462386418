export default function ActionIconOrientation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 45 45"
      fill="current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.26172 34V22C9.26225 21.4697 9.47313 20.9613 9.84809 20.5864C10.223 20.2114 10.7314 20.0005 11.2617 20H23.2617C23.792 20.0005 24.3004 20.2114 24.6753 20.5864C25.0503 20.9613 25.2612 21.4697 25.2617 22V34C25.2612 34.5303 25.0503 35.0387 24.6753 35.4136C24.3004 35.7886 23.792 35.9995 23.2617 36H11.2617C10.7314 35.9995 10.223 35.7886 9.84809 35.4136C9.47313 35.0387 9.26225 34.5303 9.26172 34ZM11.2617 22L11.2607 34H23.2617V22H11.2617ZM24.2617 8L25.6717 9.41L23.0917 12H28.2617C30.1176 12.0021 31.8968 12.7403 33.2091 14.0526C34.5214 15.3649 35.2596 17.1441 35.2617 19V24H33.2617V19C33.2601 17.6744 32.7328 16.4036 31.7955 15.4662C30.8582 14.5289 29.5873 14.0016 28.2617 14H23.0917L25.6717 16.59L24.2617 18L19.2617 13L24.2617 8Z"
      />
    </svg>
  );
}
