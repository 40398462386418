import type {FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, DocumentData, WithFieldValue, PartialWithFieldValue, Timestamp} from "firebase/firestore";

export interface DisplayNFTAssociation {
	deviceID: string;
	order: number;
}

export interface lendingAssociation {
	"lender": string;
	"borrower": string;
	"startDate": Date;
	"endDate": Date;
}

export interface LendNFTProps {
	lender: string | Uint8Array;
	borrower: string | Uint8Array;
	startDate: Date;
	endDate: Date;
	nftId: string;
	signature: string | Uint8Array;
	loanDescription?: string;
	fee?: {
		value: number;
		chainId: number;
		hash?: string;
	};
}
  

export interface LentNFT {
	id: string;
    lender: string;
    borrower: string;
    startDate: Date;
    endDate: Date;
    nftId: string;
    signature: string;
	status: "pending" | "accepted" | "rejected";
	counterSignature?: string;
	loanDescription?: string;
	fee?: {
		value: number;
		chainId: number;
		hash?: string;
	};
}

export interface LentNFTEntryRaw {
    lender: string;
    borrower: string;
    startDate: Timestamp;
    endDate: Timestamp;
    nftId: string;
    signature: string;
	status: "pending" | "accepted" | "rejected";
	counterSignature?: string;
	loanDescription?: string;
	fee?: {
		value: number;
		chainId: number;
		hash?: string;
	}
}

export interface NFTEntryRaw {
    ownerId: string;
    thumbnailUrl: string;
    url: string;
    name: string;
    artist: string | undefined;
    apiId: number;
    chainId: number;
    devices: {[key: string]: {order: number}} | undefined;
    contractAddress: string;
    tokenId: string;
    walletAddress: string;
    lending: lendingAssociation | undefined;
}

export interface DisplayNFT {
	id: string;
	ownerID: string;
	thumbnailURL: string;
	artworkURL: string;
	name: string | undefined;
	artist: string | undefined;
	apiID?: number;
	chainID?: number;
	date: Date;
	associations: DisplayNFTAssociation[];
	contractAddress: string;
	tokenID: string;
	walletAddress: string;
	lending: lendingAssociation | undefined;
	borrowed: boolean | undefined;
	lended: boolean | undefined;
	lendHistory: lendingAssociation | undefined;
}

// Converter
export const lentNFTFirestoreDataConverter: FirestoreDataConverter<LentNFT> = {
	fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): LentNFT {
		const data: LentNFTEntryRaw = snapshot.data(options);
		return {
			id: snapshot.id,
			lender: data["lender"],
			borrower: data["borrower"],
			startDate: new Date(data["startDate"].seconds * 1000),
            endDate: new Date(data["endDate"].seconds * 1000),
			nftId: data["nftId"],
			signature: data["signature"],
			status: data["status"],
			counterSignature: data["counterSignature"],
			loanDescription: data["loanDescription"],
			fee: data["fee"]
		};
	},
    toFirestore(modelObject: WithFieldValue<LentNFT> | PartialWithFieldValue<LentNFT>): DocumentData {
		return {
			lender: modelObject.lender,
			borrower: modelObject.borrower,
			startDate: modelObject.startDate,
			endDate: modelObject.endDate,
			nftId: modelObject.nftId,
			signature: modelObject.signature
		};
	}
};

export const displayNFTFirestoreDataConverter: FirestoreDataConverter<DisplayNFT> = {
	fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): DisplayNFT {
		const data: NFTEntryRaw = snapshot.data(options);
		return {
			id: snapshot.id,
			ownerID: data["ownerId"],
			thumbnailURL: data["thumbnailUrl"],
			artworkURL: data["url"],
			name: data["name"],
			artist: data["artist"],
			date: new Date(0),
			apiID: data["apiId"],
			chainID: data["chainId"],
			associations: Object.entries(data["devices"] as {[key: string]: {order: number}})
				.map(([deviceID, {order}]): DisplayNFTAssociation => ({deviceID, order})),
			contractAddress: data["contractAddress"],
			tokenID: data["tokenId"],
			walletAddress: data["walletAddress"],
			borrowed: undefined,
			lended: undefined,
			lendHistory: undefined,
			lending: data["lending"] && Object.entries(data["lending"]).reduce((acc: lendingAssociation, [key, value]) => {
				if (key === "startDate" || key === "endDate") {
					acc[key] = new Date(value.seconds * 1000);
				} else if (key === "borrower" || key === "lender") {
					acc[key] = value;
				}
				return acc;
 			}, {lender: "", borrower: "", startDate: new Date(), endDate: new Date()}),
		};
	},
	toFirestore(modelObject: WithFieldValue<DisplayNFT> | PartialWithFieldValue<DisplayNFT>): DocumentData {
		return {
			thumbnailUrl: modelObject.thumbnailURL,
			name: modelObject.name,
			artist: modelObject.artist,
			date: modelObject.date,
			contractAddress: modelObject.contractAddress,
			tokenId: modelObject.tokenID,
			ownerId: modelObject.ownerID,
			walletAddress: modelObject.walletAddress
		};
	}
};