export default function ActionIconSlideshow(status?: string) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
      viewBox="0 0 45 45"
      fill="current"
    >
      {status === "Playing" ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9502 10.377L34.0496 22.377L14.9502 34.377V10.377ZM16.9502 30.7584L30.2902 22.377L16.9502 13.9955L16.9502 30.7584Z"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0254 34.377V10.377H20.0254V34.377H18.0254ZM24.9731 34.377V10.377H26.9731V34.377H24.9731Z"
        />
      )}
    </svg>
  );
}
