// https://tailwindui.com/components/application-ui/navigation/steps
import { CheckCircleIcon } from "@heroicons/react/solid";

interface Step {
  index: number;
  name: string;
  status: "complete" | "current" | "upcoming";
}

export default function StepList({
  steps,
  children,
}: {
  steps: Step[];
  children: JSX.Element;
}) {
  return (
    <div className="py-2 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center" aria-label="Progress">
        <ol className="space-y-2 w-full h-full">
          {steps.map((step) => (
            <li key={step.name}>
              {step.status === "complete" ? (
                <div className="group">
                  <span className="flex items-start">
                    <span className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-purpleLighter"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-sm font-medium text-gray-500">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <>
                  <div
                    className="flex flex-row items-start"
                    aria-current="step"
                  >
                    <span
                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                      aria-hidden="true"
                    >
                      <span className="absolute h-4 w-4 rounded-full bg-purple" />
                      <span className="relative block h-2 w-2 rounded-full bg-purpleLighter animate-pulse" />
                    </span>
                    <span className="ml-3 text-sm font-medium text-purpleLighter">
                      {step.name}
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center pl-[32px] text-left text-sm">
                    {children}
                  </div>
                </>
              ) : (
                <div className="group">
                  <div className="flex items-start">
                    <div
                      className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                      aria-hidden="true"
                    >
                      <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                    </div>
                    <p className="ml-3 text-sm font-medium text-gray-500 dark:text-gray-400">
                      {step.name}
                    </p>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}
